import React from 'react';
import { useHistory } from 'react-router';
import { useService } from '../../services';
import PolicyForm from '../components/PolicyForm';
import PolicyServices from '../services/PolicyServices';

const AddPolicy: React.FC<{}> = () => {
  const history = useHistory();
  const policyServices = useService(PolicyServices);

  return (
    <>
      <h1>Ajouter un groupe</h1>
      <PolicyForm onSubmit={policyServices.addPolicy} onSuccess={id => history.push(`/policies/${id}`)} />
    </>
  )
}

export default AddPolicy;