import React from 'react';
import { CardBody as CardBodyStrap, CardBodyProps as CardBodyStrapProps } from 'reactstrap';
import './CardBody.scss';

interface CardProps extends CardBodyStrapProps {
  scrollable?: boolean;
}

const CardBody: React.FC<CardProps> = ({ scrollable, className: originalClassName, ...props}) => {

  const className = scrollable ? (originalClassName ? `${originalClassName} card-body-scrollable` : "card-body-scrollable") : originalClassName;
  return (
    <CardBodyStrap className={className} {...props} />
  )
}

export default CardBody;