import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, UserContext } from './AuthContext';
import Loading from '../../common/components/Loading';

interface AuthInitializerProps {
  validate: () => Promise<UserContext>;
}

const AuthInitializer: React.FC<AuthInitializerProps> = ({ validate, children }) => {
  const [isInitialized, setInitialized] = useState(false);
  var { signin } = useContext(AuthContext);

  useEffect(() => {
    if (!isInitialized) {
      validate()
        .then(user => signin(user))
        .catch()
        .finally(() => setInitialized(true));
    }
  }, [isInitialized]);

  return <>{isInitialized ? children : <Loading />}</>
}

export default AuthInitializer;