import React from 'react';
import DisplayField from '../../common/components/DisplayField';

export interface DiceAttributeProps {
  value: number;
  physicalBonus: number;
  magicBonus: number;
  side: number;
  average: number
}

const DiceAttribute: React.FC<DiceAttributeProps & { name: string }> = ({ name, value, physicalBonus, magicBonus, side, average }) => {
  const pb = physicalBonus < 0 ? `${physicalBonus}` : `+${physicalBonus}`;
  const mb = magicBonus < 0 ? `${magicBonus}` : `+${magicBonus}`;
  return (
    <DisplayField title={name}>
      <span>{`${value}D${side} ${pb} ${mb}`} </span>
      <small>
        <small>(<b>Moyenne: </b>{average})</small>
      </small>
    </DisplayField>
  )
}

export default DiceAttribute;