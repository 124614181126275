import React, { useEffect, useState } from 'react';
import { CardDeck } from 'reactstrap';
import Button from '../../common/components/Button';
import Loading from '../../common/components/Loading';
import PreviousButton from '../../common/components/PreviousButton';
import CardTroll from '../../trolls/components/CardTroll';
import FlyGrid, { Fly } from './FlyGrid';

interface TrollFliesData {
  flies: Fly[];
  lastUpdate: Date;
}

interface DisplayFliesState {
  trollFlies?: TrollFliesData;
  isLoaded: boolean;
}

export interface DisplayFliesProps {
  loadFlies: () => Promise<TrollFliesData>;
  refreshFlies: () => Promise<void>;
  policyId?: string;
}

const DisplayFlies: React.FC<DisplayFliesProps> = ({ loadFlies, refreshFlies }) => {
  const [{ isLoaded, trollFlies }, setState] = useState<DisplayFliesState>({ isLoaded: false });

  useEffect(() => {
    loadFlies()
      .then(trollFlies => setState({
        trollFlies,
        isLoaded: true
      }))
  }, [loadFlies]);

  const handleRefreshEffects = async () => {
    await refreshFlies();

    setState({
      trollFlies: await loadFlies(),
      isLoaded: true
    })
  }

  if (!isLoaded) {
    return <Loading />
  }

  if (!trollFlies) {
    return (
      <div>
        <Button onClick={() => handleRefreshEffects()}>Charger les mouches</Button>
        <PreviousButton />
      </div>
    )
  }

  const { flies, lastUpdate } = trollFlies;

  return (
    <CardDeck className="trollflies-cards">
      <CardTroll title="Mouches" lastUpdate={lastUpdate} refresh={handleRefreshEffects}>
        <FlyGrid flies={flies} />
      </CardTroll>
    </CardDeck>
  )
}

export default DisplayFlies;