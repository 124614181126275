import React from 'react';
import { Button as ReactStrapButton } from 'reactstrap';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
}

const Button: React.FC<ButtonProps> = ({ color, ...otherProps}) => (
  <ReactStrapButton outline color={color ? color! : "primary"} {...otherProps} />
)

export default Button;