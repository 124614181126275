import { number } from 'yup';
import { Service } from '../../services';

interface Localization {
  x: number;
  y: number;
  n: number;
}

interface Attribute {
  value: number;
  physicalBonus: number;
  magicBonus: number;
}

interface TurnAttribute extends Attribute {
  base: number;
  reserve: number;
}

interface DiceAttribute extends Attribute {
  side: number;
  average: number;
}

interface Vitality extends Attribute {
  current: number;
  max: number;
}

interface Profile {
  vitality: Vitality;
  view: Attribute;
  attack: DiceAttribute;
  dodge: DiceAttribute;
  damage: DiceAttribute;
  regeneration: DiceAttribute;
  armor: DiceAttribute;
  magicMastery: Attribute;
  magicResistance: Attribute;
  turn: TurnAttribute;
}

interface Tiredness {
  value: number;
  physicalBonus: number;
}

interface TrollTalent {
  name: string;
  levels: number[];
  bonus: number;
}

interface TrollTalents {
  skills: TrollTalent[];
  spells: TrollTalent[];
}

interface ActionLimitDate {
  value: Date;
  nextEstimated: Date;
}

interface ProgrammedActions {
  nbCounterAttack: number;
  nbParry: number;
  retreats: string[];
}

interface Troll {
  id: number;
  name: string;
  breed: string;
  breedAndLevel: string;
  level: number;
  talents: TrollTalents;
  profile: Profile;
  actionPoints: number;
  concentration: number;
  tiredness: Tiredness;
  actionLimitDate: ActionLimitDate;
  localization: Localization;
  lastUpdate: Date;
  canPlay: boolean;
  isHidden: boolean;
  isInvisible: boolean;
  isIntangible: boolean;
  isSticky: boolean;
  isDown: boolean;
  isRunning: boolean;
  isLevitating: boolean;
  programmedActions: ProgrammedActions;
}

interface Talent {
  id: number;
  name: string;
}

interface Talents {
  skills: Talent[];
  spells: Talent[];
}

interface Breed {
  id: string;
  name: string;
}

interface TrollServicesProps {
  getTroll: (id: number) => Promise<Troll>;
  getTalents: () => Promise<Talents>;
  getBreeds: () => Promise<Breed[]>;
}

const TrollServices: Service<TrollServicesProps> = (serviceContext) => ({
  getTroll: (id) => serviceContext.fetchApi<Troll>(`api/trolls/${id}`),
  getTalents: () => serviceContext.fetchApi<Talents>(`api/trolls/talents`),
  getBreeds: () => serviceContext.fetchApi<Breed[]>(`api/trolls/breeds`),
})

export default TrollServices;