import React from 'react';
import Table, { Metadata } from '../../common/components/Table';
import { CardDeck } from 'reactstrap';
import Card from '../../common/components/Cards/Card';
import CardHeader from '../../common/components/Cards/CardHeader';
import CardBody from '../../common/components/Cards/CardBody';
import AddTroll from './AddTroll';
import AddGuild from './AddGuild';
import BadgeIcon from '../../common/components/BadgeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

interface Guild {
  id: number;
  name: string;
}

interface Troll {
  id: number;
  name: string;
}

interface DiplomacyPolicyProps {
  trolls: Troll[];
  guilds: Guild[];
  addTroll: (trollId: number) => void;
  addGuild: (guildId: number) => void;
  deleteTroll: (troll: Troll) => void;
  deleteGuild: (guildId: Guild) => void;
}

const DisplayPolicy: React.FC<DiplomacyPolicyProps> = ({ trolls, guilds, addTroll, addGuild, deleteTroll, deleteGuild }) => {
  const trollMetadatas: Metadata<Troll>[] = [{
    title: "Id",
    key: "id"
  }, {
    title: "Nom",
    key: "name"
  }, {
    title: "",
    render: row => <BadgeIcon tooltip="Exclure le troll" onClick={() => deleteTroll(row)}><FontAwesomeIcon icon={faTrashAlt} size="2x" /></BadgeIcon>
  }];

  const guildMetadatas: Metadata<Guild>[] = [{
    title: "Id",
    key: "id"
  }, {
    title: "Nom",
    key: "name"
  }, {
    title: "",
    render: row => <BadgeIcon tooltip="Exclure la guilde" onClick={() => deleteGuild(row)}><FontAwesomeIcon icon={faTrashAlt} size="2x" /></BadgeIcon>
  }];

  return (
    <CardDeck>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h5><b className="text-nowrap">Trolls</b></h5>
          <div className="card-header-icons">
            <AddTroll addTroll={addTroll} />
          </div>
        </CardHeader>
        <CardBody>
          <Table rows={trolls} metadatas={trollMetadatas} />
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h5><b className="text-nowrap">Guildes</b></h5>
          <div className="card-header-icons">
            <AddGuild addGuild={addGuild} />
          </div>
        </CardHeader>
        <CardBody>
          <Table rows={guilds} metadatas={guildMetadatas} />
        </CardBody>
      </Card>
    </CardDeck>
  )
}

export default DisplayPolicy;