import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import BadgeIcon from '../../common/components/BadgeIcon';
import { faFilter, faHandshake } from '@fortawesome/free-solid-svg-icons';

import '../font-icons/css/IconMaps.css';
import { MapMenuItems } from '../models/Map';
import { SearchContext } from '../contexts/SearchContext';
import BadgeNotification from '../../common/components/BadgeNotification';

interface MapMenuProps {
  activeMenu: MapMenuItems;
  onChange?: (menu: MapMenuItems) => void;
}

const MapMenu: React.FC<MapMenuProps> = ({ activeMenu, onChange }) => {
  const { result } = useContext(SearchContext);
  const handleChange = (menu: MapMenuItems) => {
    if (onChange) {
      onChange(menu);
    }
  }

  const nbTrolls = result.highlights.details.reduce((count, d) => count + d.trolls.length, 0);
  const nbMonsters = result.highlights.details.reduce((count, d) => count + d.monsters.length, 0);

  return (
    <>
      <MenuFontAwesomeIcon className="ml-1" icon={faFilter} tooltip="Filtrer la vue" value={MapMenuItems.filters} activeMenu={activeMenu} onChange={handleChange} />
      <MenuFontAwesomeIcon className="ml-1" icon={faHandshake} tooltip="Diplomatie" value={MapMenuItems.diplomacy} activeMenu={activeMenu} onChange={handleChange} />
      <MenuIcon className="ml-1" value={MapMenuItems.trolls} tooltip="Chercher un troll" counter={nbTrolls} activeMenu={activeMenu} onChange={handleChange}><i className="icon-troll" /></MenuIcon>
      <MenuIcon className="ml-1" value={MapMenuItems.monsters} tooltip="Chercher des monstres" counter={nbMonsters} activeMenu={activeMenu} onChange={handleChange}><i className="icon-monster" /></MenuIcon>
    </>
  )
}

interface MenuIconProps {
  value: MapMenuItems;
  activeMenu: MapMenuItems;
  counter?: number;
  tooltip?: string | React.ReactNode;
  className?: string;
  onChange: (menu: MapMenuItems) => void;
}

const MenuIcon: React.FC<MenuIconProps> = ({ value, activeMenu, counter, tooltip, className, children, onChange }) => {
  return (
    <BadgeIcon tooltip={tooltip} className={`icon-img icon-button${!className ? "" : ` ${className}`}`} disabled={activeMenu === value} onClick={() => onChange(value)}>
      <BadgeNotification position="topright" counter={counter || 0}>
        {children}
      </BadgeNotification>
    </BadgeIcon>
  )
}

interface MenuFontAwesomeIconProps extends MenuIconProps {
  icon: IconProp;
}

const MenuFontAwesomeIcon: React.FC<MenuFontAwesomeIconProps> = ({ icon, value, activeMenu, counter, tooltip, onChange }) => {
  return (
    <MenuIcon className="ml-1" tooltip={tooltip} value={value} activeMenu={activeMenu} counter={counter} onChange={onChange}>
        <FontAwesomeIcon icon={icon} size="2x" />
    </MenuIcon>
  )
}

export default MapMenu;