import { useFormik } from 'formik';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';

interface ScriptSettings {
  id: string;
  name: string;
  limit: number;
  max: number;
}

export interface AccountSettings {
  scripts: ScriptSettings[];
}

export interface UpdateAccountSettingsRequest {
  scripts: Omit<ScriptSettings, "name" | "max">[];
}

export interface ScriptSettingsProps {
  settings: AccountSettings;
  saveSettings: (settings: UpdateAccountSettingsRequest) => Promise<void>;
}

const ScriptSettings: React.FC<ScriptSettingsProps> = ({ settings, saveSettings }) => {
  const { isSubmitting, values, handleChange, handleSubmit } = useFormik({
    initialValues: settings,
    onSubmit: async (settings, { setSubmitting }) => {
      try {
        await saveSettings({
          scripts: settings.scripts.map(s => ({
            id: s.id,
            limit: s.limit
          }))
        });
      }
      catch {
        setSubmitting(false);
      }
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        {
          values.scripts.map((script, index) => {
            return (
              <Row key={index}>
                <Col><b>{script.name}</b></Col>
                <Col>
                  <Input type="range" onChange={handleChange} disabled={isSubmitting} name={`scripts.${index}.limit`} value={script.limit} min={0} max={script.max} />
                </Col>
                <Col><span>{script.limit}/{script.max}</span></Col>
              </Row>
            )
          })
        }
      </Container>
      <Button type="submit" disabled={isSubmitting}>Enregistrer</Button>
    </form>
  )
}

export default ScriptSettings;