import React from 'react';
import AuthenticatedRoute from '../authentication/components/AuthenticatedRoute';
import ViewTroll from './views/ViewTroll';

const Routes: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <AuthenticatedRoute exact path='/policies/:policyId/trolls/:id' component={ViewTroll} />
      <AuthenticatedRoute exact path='/trolls/:id' component={ViewTroll} />
    </React.Fragment>
  )
}

export default Routes;