import React from 'react';
import { useParams } from 'react-router';
import { useService } from '../../services';
import Invitations from '../components/Invitations';
import PolicyServices from '../services/PolicyServices';

const PolicyInvitations: React.FC = () => {
  const { id: policyId } = useParams<{ id: string }>();
  const policyServices = useService(PolicyServices);

  return (
    <Invitations
      policyId={policyId}
      getInvitations={policyServices.getInvitations}
      getPolicy={policyServices.getPolicy}
      removeInvitation={policyServices.removeInvitation}
      sendInvitation={policyServices.sendInvitation}
    />
  )
}

export default PolicyInvitations;