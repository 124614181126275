import React, { useContext } from 'react';
import { NavItem, NavItemProps, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../authentication/components/AuthContext';


interface NavMenuProfileProps extends NavItemProps {
}

export const NavMenuProfile: React.FC<NavMenuProfileProps> = (props) => {

  const { authenticated } = useContext(AuthContext);
  if (!authenticated) {
    return <></>
  }
  
  return (
    <NavItem {...props}>
      <NavLink tag={Link} to="/accounts">Profil</NavLink>
    </NavItem>
  )
}

export default NavMenuProfile;