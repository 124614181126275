import React from 'react';
import { TrollProps } from './DetailedTroll';
import { CardDeck } from 'reactstrap';
import CardTroll from './CardTroll';
import TrollProfile from './TrollProfile';
import TrollSituation from './TrollSituation';
import './DisplayTroll.scss';

interface DisplayTrollProps {
  troll: TrollProps;
}

const DisplayTroll: React.FC<DisplayTrollProps> = ({ troll }) => {
  const { profile, lastUpdate, ...situation } = troll;

  return (
    <div>
      <CardDeck className="trollprofile-cards">
        <CardTroll title="Profil" lastUpdate={lastUpdate}>
          <TrollProfile {...profile} />
        </CardTroll>
        <CardTroll title="Situation" lastUpdate={lastUpdate}>
          <TrollSituation
            vitality={profile.vitality}
            {...situation} />
        </CardTroll>
      </CardDeck>
    </div>
  )
}

export default DisplayTroll;