import React, { useContext} from 'react';
import { Redirect, useHistory } from 'react-router';
import SignInForm from '../components/SignInForm';
import AuthenticationServices, { AuthenticateRequest } from '../services/AuthenticationServices';
import { AuthContext } from '../components/AuthContext';
import { useService } from '../../services';

interface SignInProps {
  redirectUrl?: string;
}

const SignIn: React.FC<SignInProps> = props => {
  const { authenticated, signin } = useContext(AuthContext);
  const history = useHistory();
  const { redirectUrl } = props;
  const authenticationServices = useService(AuthenticationServices);

  function authenticate(request: AuthenticateRequest): Promise<void> {
    return authenticationServices.signin(request)
      .then(user => {
        signin(user);
        return user;
      })
      .then(() => history.push(!redirectUrl ? "/" : redirectUrl));
  }

  if (authenticated) {
    return <Redirect to={!redirectUrl ? "/" : redirectUrl} />
  }
  return <SignInForm onSignIn={request => authenticate(request)} />
}

export default SignIn;