import { Service } from '../../services';

interface Account {
  login: string;
  password: string;
  trollId: number;
  scriptToken: string;
}

interface ForgetPassword {
  login: string;
  newPassword: string;
  scriptToken: string;
}

interface ScriptSettings {
  id: string;
  name: string;
  limit: number;
  max: number;
}

interface AccountSettings {
  scripts: ScriptSettings[];
}

interface UpdateAccountSettingsRequest {
  scripts: Omit<ScriptSettings, "name" | "max">[];
}

interface UpdatePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

interface UpdateAccountRequest {
  scriptToken: string;
}

interface Guild {
  id: number;
  name: string;
}

interface Troll {
  id: number;
  name: string;
  breed: string;
  level: number;
  guild?: Guild;
  avatar: string;
}

interface MyAccount {
  login: string;
  troll: Troll;
}

interface AccountServicesProps {
  createAccount: (request: Account) => Promise<void>;
  getMyAccount: () => Promise<MyAccount>;
  resetForgetPassword: (request: ForgetPassword) => Promise<void>;
  getAccountSettings: () => Promise<AccountSettings>;
  updateAccountSettings: (request: UpdateAccountSettingsRequest) => Promise<void>;
  updatePassword: (request: UpdatePasswordRequest) => Promise<void>;
  updateAccount: (request: UpdateAccountRequest) => Promise<void>;
}

const AccountServices: Service<AccountServicesProps> = (serviceContext) => ({
  createAccount: (request) => serviceContext.fetchApi(`api/accounts`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify(request)
  }),
  getMyAccount: () => serviceContext.fetchApi<MyAccount>(`api/accounts`),
  resetForgetPassword: (request) => serviceContext.fetchApi(`api/accounts/forget`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "PUT",
    body: JSON.stringify(request)
  }),
  getAccountSettings: () => serviceContext.fetchApi(`api/accounts/settings`),
  updateAccountSettings: (request) => serviceContext.fetchApi(`api/accounts/settings`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "PUT",
    body: JSON.stringify(request)
  }),
  updatePassword: (request) => serviceContext.fetchApi(`api/accounts/password`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "PUT",
    body: JSON.stringify(request)
  }),
  updateAccount: (request) => serviceContext.fetchApi(`api/accounts`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "PUT",
    body: JSON.stringify(request)
  }),
})

export default AccountServices;