import { Service } from '../../services';

interface Invitation {
  id: string;
  name: string;
  nbTrolls: number;
  nbInvitations: number;
}

interface InvitationServicesProps {
  getInvitations: () => Promise<Invitation[]>;
  acceptInvitation: (policyId: string) => Promise<void>;
  refuseInvitation: (invitationId: string) => Promise<void>;
}

const InvitationServices: Service<InvitationServicesProps> = (serviceContext) => ({
  getInvitations: () => serviceContext.fetchApi<Invitation[]>(`api/invitations`),
  acceptInvitation: (invitationId) => serviceContext.fetchApi(`api/invitations/${invitationId}`, {
    method: "PUT"
  }),
  refuseInvitation: (invitationId) => serviceContext.fetchApi(`api/invitations/${invitationId}`, {
    method: "DELETE"
  }),
})

export default InvitationServices;