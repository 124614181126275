import React from 'react';

import './DivTable.scss';

interface DivTableProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
}

const DivTable: React.FC<DivTableProps> = ({ className, ...props }) => {
  return (
    <div className={`div-table${className ? (" " + className) : ""}`} {...props} />
  )
}

export default DivTable;