import React from 'react';
import { ModalFooter as ModalFooterStrap, ModalFooterProps as ModalFooterStrapProps } from 'reactstrap';
import './ModalFooter.scss';

interface ModalFooterProps extends ModalFooterStrapProps {
}

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  return (
    <ModalFooterStrap {...props} />
  )
}

export default ModalFooter;