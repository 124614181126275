import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import CollapseButton from '../../common/components/CollapseButton';
import { MatchDetails } from '../models/Search';
import Trolls from './Trolls';

interface TrollDetailsProps {
  details: MatchDetails[];
}

const TrollDetails: React.FC<TrollDetailsProps> = ({ details }) => {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  const nbTrolls = details.reduce((count, d) => count + d.trolls.length, 0);

  return (
    <>
      { nbTrolls === 0 ? null : <CollapseButton toggle={toggle} collapse={collapse} />}
      <span>{nbTrolls === 0 ? "Aucun" : `${nbTrolls}`} troll{nbTrolls > 1 ? "s" : null} trouvé{nbTrolls > 1 ? "s" : null}</span>
      <Collapse
        isOpen={collapse}
      >
        <Trolls
          rows={
            details
              .map(pos => pos.trolls.map(troll => ({ ...troll, x: pos.x, y: pos.y, n: pos.n })))
              .reduce((agg, troll) => agg.concat(troll), [])
          }
        />
      </Collapse>
    </>
  )
}

export default TrollDetails;