import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import Loading from '../../common/components/Loading';
import { Status } from '../../services';
import MapReducer from '../reducers/MapReducer';
import Map, { MapArea, View, RawArea, MapMenuItems } from '../models/Map';
import Zoom from '../models/Zoom';
import { FilterContext } from './FilterContext';
import { SearchContext } from './SearchContext';
import { SearchResult } from '../models/Search';
import { DetailedCave } from '../models/DetailedMap';

const emptyMap: Map<MapArea> = {
  origin: { x: 0, y: 0, n: 0 },
  radius: 0,
  token: "",
  areas: []
}

interface MapContextProps {
  map: Map<MapArea>;
  view: View;
  zoom: Zoom;
  menu: MapMenuItems;
  refresh?: () => Promise<void>;
  setZoom: (zoom: Zoom) => void;
  setMenu: (menu: MapMenuItems) => void;
  getDetails: (x: number, y: number) => Promise<DetailedCave[]>;
}

export const MapContext = createContext<MapContextProps>({
  map: emptyMap,
  view: { areas: [] },
  zoom: Zoom.default,
  menu: MapMenuItems.filters,
  setZoom: () => { },
  setMenu: () => { },
  getDetails: async () => ([])
});

export interface DefaultMap extends Map<RawArea> {
  search: SearchResult;
  diplomacy?: { id: string, name: string }
}

export interface MapContextProviderProps {
  map: DefaultMap;
  refreshView?: () => Promise<void>;
  getDetails: (x: number, y: number, token: string) => Promise<DetailedCave[]>;
}

export const MapContextProvider: React.FC<MapContextProviderProps> = ({ map: mapProps, refreshView, getDetails, children }) => {
  const { filters, initFilters } = useContext(FilterContext);
  const { result, setDiplomacyCriteria, setResult } = useContext(SearchContext);
  const [{ isLoaded, map, view, zoom, menu }, dispatch]
    = useReducer(MapReducer, { isLoaded: false, zoom: Zoom.default, menu: MapMenuItems.filters });

  const load: () => Promise<void> = useMemo(() => async () => {
    try {
      setDiplomacyCriteria(mapProps.diplomacy);
      dispatch({ type: 'complete_load', map: mapProps });
      setResult(mapProps.search);
      dispatch({ type: 'search', result: mapProps.search });
      initFilters(mapProps);
    }
    catch (error) {
      if (error.status === Status.NotFound) {
        dispatch({ type: 'empty_map' });
      }
      else {
        throw error;
      }
    }
  }, [mapProps, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    dispatch({ type: 'filter', filters });
  }, [dispatch, filters]);

  useEffect(() => {
    dispatch({ type: 'search', result });
  }, [dispatch, result]);

  if (!isLoaded) {
    return <Loading />
  }

  if (!map || !view) {
    return (
      <></>
    )
  }

  const setZoom = (z: Zoom) => dispatch({ type: 'zoom', zoom: z });
  const setMenu = (m: MapMenuItems) => dispatch({ type: 'menu', menu: m });

  return (
    <MapContext.Provider
      value={{
        map,
        view,
        zoom,
        menu,
        refresh: refreshView,
        setZoom,
        setMenu,
        getDetails: (x, y) => getDetails(x, y, map.token)
      }}
    >
      {children}
    </MapContext.Provider>
  )
}