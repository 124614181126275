import React, {  } from 'react';
import { CardDeck } from 'reactstrap';
import CardTroll from './CardTroll';
import TrollTalents, { Talent } from './TrollTalents';

interface Talents {
  skills: Talent[];
  spells: Talent[];
}

interface DisplayTalentsProps {
  talents: Talents;
  lastUpdate: Date;
}

const DisplayTalents: React.FC<DisplayTalentsProps> = ({ talents, lastUpdate }) => {
  return (
    <div>
      <CardDeck className="trolltalents-cards">
        <CardTroll title="Compétences" lastUpdate={lastUpdate}>
          <TrollTalents talents={talents.skills} />
        </CardTroll>
        <CardTroll title="Sortilèges" lastUpdate={lastUpdate}>
          <TrollTalents talents={talents.spells} />
        </CardTroll>
      </CardDeck>
    </div>
  )
}

export default DisplayTalents;