import React from 'react';
import { Card as CardStrap, CardProps as CardStrapProps } from 'reactstrap';
import './Card.scss';

interface CardProps extends CardStrapProps {

}

const Card: React.FC<CardProps> = ({ className, ...props}) => {
  return (
    <CardStrap className={(className ? `${className} ` : "") + "app-card"} {...props} />
  )
}

export default Card;