import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import Input from '../../common/components/Input';
import DisplayField from '../../common/components/DisplayField';
import { SearchContext } from '../contexts/SearchContext';

interface TrollSearchValues {
  id: number;
}

const TrollSearchCriteria: React.FC = () => {
  const { criteria: { troll: trollCriteria }, setTrollCriteria } = useContext(SearchContext);

  const handleChange: (values: Partial<TrollSearchValues>) => void = values => {
    setTrollCriteria({
      id: values.id
    });
  }

  return (
    <Container>
      <DisplayField title="Numéro">
        <Input type="text" name="id" defaultValue={trollCriteria && trollCriteria.id} onChange={e => handleChange({ id: Number(e.target.value)})} placeholder="Numéro de troll" />
      </DisplayField>
    </Container>
  )
}

export default TrollSearchCriteria;