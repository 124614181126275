import React, { PropsWithChildren } from 'react';
import { Table as TableStrap } from 'reactstrap';

import './Table.scss';

export interface TableProps<T> {
  rows: T[];
  metadatas: Metadata<T>[];
  className?: string;
}

export interface Metadata<T> {
  key?: keyof T;
  title: string;
  render?: (row: T) => React.ReactNode;
  className?: string;
}

function Table<T>(
  { rows, metadatas, className }: PropsWithChildren<TableProps<T>>,
) {
  return (
    <TableStrap className={["table-list", className].filter(c => !!c).join(" ")}>
      <Head
        metadatas={metadatas}
      />
      <tbody>
        {
          rows.map((row, index) => (
            <Row
              key={index}
              row={row}
              metadatas={metadatas}
            />
          ))
        }
      </tbody>
    </TableStrap>
  );
}

interface HeadProps {
  metadatas: {
    title: string;
    className?: string;
  }[];
}

const Head: React.FC<HeadProps> = ({
  metadatas,
}) => {
  return (
    <thead>
      <tr>
        {
          metadatas.map((metadata, index) => (
            <th key={index} className={metadata.className}>{metadata.title}</th>
          ))
        }
      </tr>
    </thead>
  );
};

interface RowProps<T> {
  row: T;
  metadatas: Metadata<T>[];
}

function Row<T>(
  { row, metadatas }: RowProps<T>
) {
  return (
    <tr>
      {
        metadatas.map((metadata, index) => (
          <td key={index} className={metadata.className}>
            {!metadata.render ? (!metadata.key ? null : row[metadata.key!]) : metadata.render!(row)}
          </td>
        ))
      }
    </tr>
  );
}

export default Table;