import React, { useState } from 'react';
import DisplayField from '../../common/components/DisplayField';
import { Container } from 'reactstrap';
import Input from '../../common/components/Input';
import BadgeIcon from '../../common/components/BadgeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../common/components/Modals/Modal';
import ModalHeader from '../../common/components/Modals/ModalHeader';
import ModalBody from '../../common/components/Modals/ModalBody';
import ModalFooter from '../../common/components/Modals/ModalFooter';
import Button from '../../common/components/Button';

interface AddTrollProps {
  addTroll: (id: number) => void;
  tooltip?: string | React.ReactNode;
}

const AddTroll: React.FC<AddTrollProps> = ({ addTroll, tooltip }) => {
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [trollId, setTrollId] = useState<number>();

  const handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void = async (e) => {
    e.preventDefault();
    if (trollId) {
      addTroll(trollId);
    }
    setModal(false);
  }
  return (
    <>
      <BadgeIcon tooltip={tooltip || "Ajouter un troll"} onClick={toggle}>
        <FontAwesomeIcon icon={faPlus} />
      </BadgeIcon>
      <Modal backdrop={true} isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Ajouter un troll</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Container>
              <DisplayField title="N° troll">
                <Input type="text" name="id" defaultValue={trollId} onChange={e => setTrollId(Number(e.target.value) || undefined)} placeholder="Numéro de troll" />
              </DisplayField>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="submit">Ajouter</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

export default AddTroll;