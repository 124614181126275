import React, { createContext, useContext } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../authentication/components/AuthContext";

export enum Status {
  Unknown,
  NotFound,
  Unauthorized,
  Forbidden,
  Badrequest
}

interface FetchError {
  status: Status;
  message?: string;
}

interface BadRequestError {
  errorCode: string;
  message: string;
}

async function getJsonContent<T>(response: Response): Promise<T> {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1 ? await response.json() as T : {} as T;
}

interface ServiceContextProps {
  fetchApi<Result>(request: RequestInfo, init?: RequestInit): Promise<Result>;
}

function defaultFetchApi<T>(): Promise<T> {
  return new Promise<T>(() => { })
}
const initialState: ServiceContextProps = { fetchApi: defaultFetchApi };

const ServiceContext = createContext<ServiceContextProps>(initialState);

function displayError(error: any) {
  if (error.message) {
    toast.error(error.message);
  }
}

export const ServiceProvider: React.FC<{}> = (props) => {
  const authContext = useContext(AuthContext);

  async function fetchApi<Result>(request: RequestInfo, init?: RequestInit): Promise<Result> {
    let responseError: FetchError;
    try {
      var response = await fetch(request, init);

      if (response.ok) {
        return await getJsonContent<Result>(response);
      }

      switch (response.status) {
        case 400:
          responseError = { status: Status.Badrequest, message: (await getJsonContent<BadRequestError>(response)).message };
          break;
        case 401:
          authContext.signout();
          responseError = { status: Status.Unauthorized, message: (await getJsonContent<{ message: string }>(response)).message };
          break;
        case 403:
          responseError = { status: Status.Forbidden };
          break;
        case 404:
          responseError = { status: Status.NotFound };
          break;
        default:
          responseError = { status: Status.Unknown, message: (await getJsonContent<{ message: string }>(response)).message };
          break;
      }
      displayError(responseError);
    }
    catch (error) {
      responseError = { status: Status.Unknown };
    }

    throw responseError;
  }

  return (
    <ServiceContext.Provider value={{ fetchApi }}>
      {props.children}
    </ServiceContext.Provider>
  )
}

export type Service<T> = (serviceContext: ServiceContextProps) => T;

export function useService<T>(serviceFactory: Service<T>): T {
  const serviceContext = useContext(ServiceContext);
  return serviceFactory(serviceContext);
}