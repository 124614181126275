import React from 'react';

import './Switch.scss';

export interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {

}

const Switch: React.FC<SwitchProps> = ({children, ...props}) => (
  <label className="switch">
    <input type="checkbox" {...props} />
    <span className="slider round"></span>
    {children}
  </label>
)

export default Switch;