import React from 'react';
import { CardFooter as CardFooterStrap, CardFooterProps as CardFooterStrapProps } from 'reactstrap';

interface CardProps extends CardFooterStrapProps {

}

const CardFooter: React.FC<CardProps> = (props) => {
  return (
    <CardFooterStrap {...props} />
  )
}

export default CardFooter;