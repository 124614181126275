import React from 'react';
import Breadcrumb from '../../common/components/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../common/components/Breadcrumb/BreadcrumbItem';
import { Link } from 'react-router-dom';
import TrollName from '../../policies/components/TrollName';

interface MapBreadcrumbProps {
  policyId?: string;
  policyName?: string;
  trollId: number;
  trollName: string;
}

const MapBreadcrumb: React.FC<MapBreadcrumbProps> = ({ policyId, policyName, trollId, trollName }) => {
  return (
    <Breadcrumb>
      {policyId ? <BreadcrumbItem><Link to={`/policies/${policyId!}`}>{policyName || "Groupe"}</Link></BreadcrumbItem> : null}
      <BreadcrumbItem><TrollName id={trollId} name={trollName} policyId={policyId} isActive={true} /></BreadcrumbItem>
      <BreadcrumbItem active>Vue</BreadcrumbItem>
    </Breadcrumb>
  )
}

export default MapBreadcrumb;