import React from 'react';
import { Input as ReactStrapInput, InputProps as ReactStrapInputProps } from 'reactstrap';
import './Input.scss';

export interface InputProps extends ReactStrapInputProps {

}

const Input: React.FC<InputProps> = ({ className, ...props}) => (
    <ReactStrapInput className={["input", className].filter(c => !!c).join(" ")} {...props} />
)

export default Input;