import React from 'react';
import { useParams } from 'react-router';
import { useService } from '../../services';
import DiplomacyServices from '../services/DiplomacyServices';
import DisplayDiplomacy from '../components/DisplayDiplomacy';



const Diplomacy: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const diplomacyServices = useService(DiplomacyServices);

  const getDiplomacy = () => diplomacyServices.getDiplomacy(id);
  const addTrollFriend = (trollId: number) => diplomacyServices.addToDiplomacy(id, {
    friends: {
      trolls: [trollId],
      guilds: []
    }
  });
  const addTrollEnemy = (trollId: number) => diplomacyServices.addToDiplomacy(id, {
    enemies: {
      trolls: [trollId],
      guilds: []
    }
  });
  const addGuildFriend = (guildId: number) => diplomacyServices.addToDiplomacy(id, {
    friends: {
      trolls: [],
      guilds: [guildId]
    }
  });
  const addGuildEnemy = (guildId: number) => diplomacyServices.addToDiplomacy(id, {
    enemies: {
      trolls: [],
      guilds: [guildId]
    }
  });
  const deleteTrollFriend = (trollId: number) => diplomacyServices.deleteFromDiplomacy(id, {
    friends: {
      trolls: [trollId],
      guilds: []
    }
  });
  const deleteTrollEnemy = (trollId: number) => diplomacyServices.deleteFromDiplomacy(id, {
    enemies: {
      trolls: [trollId],
      guilds: []
    }
  });
  const deleteGuildFriend = (guildId: number) => diplomacyServices.deleteFromDiplomacy(id, {
    friends: {
      trolls: [],
      guilds: [guildId]
    }
  });
  const deleteGuildEnemy = (guildId: number) => diplomacyServices.deleteFromDiplomacy(id, {
    enemies: {
      trolls: [],
      guilds: [guildId]
    }
  })

  return (
      <DisplayDiplomacy
        load={getDiplomacy}
        addTrollFriend={addTrollFriend}
        addTrollEnemy={addTrollEnemy}
        addGuildFriend={addGuildFriend}
        addGuildEnemy={addGuildEnemy}
        deleteTrollFriend={deleteTrollFriend}
        deleteTrollEnemy={deleteTrollEnemy}
        deleteGuildFriend={deleteGuildFriend}
        deleteGuildEnemy={deleteGuildEnemy}
      />
  )
}

export default Diplomacy;