import React from 'react';

interface DivBodyProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
}

const DivBody: React.FC<DivBodyProps> = ({ className, ...props }) => {
  return (
    <div className={`div-table-body${className ? (" " + className) : ""}`} {...props} />
  )
}

export default DivBody;