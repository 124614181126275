import React from 'react';
import Table, { Metadata } from '../../common/components/Table';

import './TrollTalents.scss';

export interface Talent {
  name: string;
  levels: number[];
  bonus: number;
}

interface TrollTalentsProps {
  talents: Talent[];
}

const DisplayBonus: React.FC<{ bonus: number }> = ({ bonus}) => (
  <>{!bonus ? null : <small> {bonus > 0 ? "+" : null}{bonus}%</small>}</>
)

const Knowledge: React.FC<Talent> = ({ levels, bonus }) => {
  return (
    <ul className="talent-knowledge">{
        levels.map((knowledge, level) => (
          <li key={level}><span>Niveau {level + 1} : </span><span>{knowledge}%</span><DisplayBonus bonus={bonus} /></li>
        ))
      }
    </ul>
  )
}

const TrollTalents: React.FC<TrollTalentsProps> = ({ talents }) => {
  const metadatas: Metadata<Talent>[] = [{
    key: "name",
    title: "Nom"
  }, {
    title: "Maitrise",
    render: row => <Knowledge {...row} />
  }];

  return <Table rows={talents} metadatas={metadatas} />
}

export default TrollTalents;