import React from 'react';

import './Donut.scss';

interface DonutProps {
  current: number;
  max: number;
  legend?: string;
  size?: string | number;
  className?: string;
}

const Donut: React.FC<DonutProps> = ({ current, max, legend, size, className }) => {
  var percent = max === 0 ? 100 : Math.round(100 * current / max);
  return (
    <svg width={size} height={size} viewBox="0 0 42 42" className={`donut${!className ? "": ` ${className}`}`}>
      <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954"></circle>
      <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" strokeDasharray={`${100 - percent} ${percent}`} strokeDashoffset={25 - percent}></circle>

      <circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" strokeDasharray={`${percent} ${100 - percent}`} strokeDashoffset="25"></circle>

      <g className={`chart-text${!legend ? "" : " chart-text-with-legend"}`}>
        <text x="50%" y="50%" className="chart-legend">{legend}</text>
        <text x="50%" y="50%" className="chart-number">{current}/{max}</text>
      </g>
    </svg>
  )
}

export default Donut;