import React from 'react';
import AddPolicy from './views/AddPolicy';
import AuthenticatedRoute from '../authentication/components/AuthenticatedRoute';
import Policies from './views/Policies';
import Policy from './views/Policy';
import { Switch } from 'react-router';
import ViewMap from './views/ViewMap';
import PolicyInvitations from './views/PolicyInvitations';
import PolicySettings from './views/PolicySettings';

const Routes: React.FC<{}> = () => {
  return (
    <Switch>
      <AuthenticatedRoute exact path='/policies' component={Policies} />
      <AuthenticatedRoute exact path='/policies/add' component={AddPolicy} />
      <AuthenticatedRoute exact path='/policies/:id' component={Policy} />
      <AuthenticatedRoute exact path='/policies/:id/settings' component={PolicySettings} />
      <AuthenticatedRoute exact path='/policies/:id/map' component={ViewMap} />
      <AuthenticatedRoute exact path='/policies/:id/invitations' component={PolicyInvitations} />
    </Switch>
  )
}

export default Routes;