import React from 'react';
import { useParams } from 'react-router';
import { useService } from '../../services';
import TrollServices from '../../trolls/services/TrollServices';
import { Filters } from '../components/PolicyFilters';
import ViewPolicy from '../components/ViewPolicy';
import PolicyServices from '../services/PolicyServices';

const Policy: React.FC<{}> = () => {
  const { id: policyId } = useParams<{ id: string }>();
  const policyServices = useService(PolicyServices);
  const trollServices = useService(TrollServices);

  const getPolicy = async (policyId: string, filters: Partial<Filters>) => {
    const { id, name, trolls, isOwner } = await policyServices.getPolicy(policyId, filters);
    return {
      id,
      name,
      isOwner,
      trolls: trolls.map(({
        isHidden,
        isInvisible,
        isIntangible,
        isSticky,
        isDown,
        isRunning,
        isLevitating,
        programmedActions,
        actionPoints,
        canPlay,
        ...troll
      }) => ({
        ...troll,
        state: {
          isHidden,
          isInvisible,
          isIntangible,
          isSticky,
          isDown,
          isRunning,
          isLevitating
        },
        actionPoints: {
          actionPoints,
          canPlay
        },
        programmedActions
      }))
    }
  }

  return (
    <ViewPolicy
      id={policyId}
      getPolicy={getPolicy}
      refreshTroll={policyServices.refreshTroll}
      excludeTroll={policyServices.excludeTroll}
      getBreeds={trollServices.getBreeds}
      getTalents={trollServices.getTalents}
    />
  )
}

export default Policy;