import React from 'react';
import { ModalBody as ModalBodyStrap, ModalBodyProps as ModalBodyStrapProps } from 'reactstrap';
import './ModalBody.scss';

interface ModalBodyProps extends ModalBodyStrapProps {
}

const ModalBody: React.FC<ModalBodyProps> = (props) => {
  return (
    <ModalBodyStrap {...props} />
  )
}

export default ModalBody;