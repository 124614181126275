import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import CollapseButton from '../../common/components/CollapseButton';
import { MatchDetails } from '../models/Search';
import Monsters from './Monsters';

interface MonsterDetailsProps {
  details: MatchDetails[];
}

const MonsterDetails: React.FC<MonsterDetailsProps> = ({ details }) => {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  const nbMonsters = details.reduce((count, d) => count + d.monsters.length, 0);

  return (
    <>
      { nbMonsters === 0 ? null : <CollapseButton toggle={toggle} collapse={collapse} />}
      <span>{nbMonsters === 0 ? "Aucun" : `${nbMonsters}`} monstre{nbMonsters > 1 ? "s" : null} trouvé{nbMonsters > 1 ? "s" : null}</span>
      <Collapse
        isOpen={collapse}
      >
        <Monsters
          rows={
            details
              .map(pos => pos.monsters.map(monster => ({ ...monster, x: pos.x, y: pos.y, n: pos.n })))
              .reduce((agg, monster) => agg.concat(monster), [])
          }
        />
      </Collapse>
    </>
  )
}

export default MonsterDetails;