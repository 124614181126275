import React, { useContext } from 'react';
import { AuthContext } from '../../authentication/components/AuthContext';
import { NavItem, NavItemProps, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';


interface NavMenuConnexionProps extends NavItemProps {
}

export const NavMenuConnexion: React.FC<NavMenuConnexionProps> = (props) => {
  const history = useHistory();
  function redirectToSignIn() {
    history.push("/signin");
  }
  function redirectToSignOut() {
    history.push("/signout");
  }
  const { authenticated } = useContext(AuthContext);
  if (!authenticated) {
    return (
      <NavItem {...props}>
        <NavLink tag={Link} to="#" onClick={redirectToSignIn}>
          Connexion
        </NavLink>
      </NavItem>
    )
  }
  return (
    <NavItem {...props}>
      <NavLink tag={Link} to="#" onClick={redirectToSignOut}>
        Déconnexion
      </NavLink>
    </NavItem>
  )
}

export default NavMenuConnexion;