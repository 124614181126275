import React from 'react';
import { Badge } from 'reactstrap';
import Tooltip from '../../common/components/Tooltip';

import './BadgeTroll.scss';

interface BadgeTrollProps {
  breed: string;
  content: string;
  tooltip?: string | React.ReactNode;
  className?: string;
}

const BadgeTroll: React.FC<BadgeTrollProps> = ({ breed, content, tooltip, className }) => {
  return (
    <Badge className={`badge-troll badge-${breed.toLowerCase()}${!className ? "" : ` ${className}`}`}>
      {tooltip ? <Tooltip description={tooltip}>{content}</Tooltip> : <span>{content}</span>}
    </Badge>
  )
}

export default BadgeTroll;