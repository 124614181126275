import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import DisplayField from '../../common/components/DisplayField';
import BadgeTroll from '../../trolls/components/BadgeTroll';
import ScriptToken from './ScriptToken';

interface Guild {
  id: number;
  name: string;
}

export interface TrollAccountValues {
  id: number;
  name: string;
  breed: string;
  level: number;
  avatar: string;
  guild?: Guild;
}

interface TrollAccountProps {
  troll: TrollAccountValues;
  updateScriptToken: (scriptToken: string) => Promise<void>;
}

const TrollAccount: React.FC<TrollAccountProps> = ({ troll: { id, name, breed, level, avatar, guild }, updateScriptToken }) => {
  return (
    <Container>
      <Row>
        <Col>
          <Container>
            <DisplayField title="Troll">
              <span className="text-nowrap">{name} ({id})</span>
            </DisplayField>
            <DisplayField title="Race">
              <BadgeTroll className="text-nowrap" breed={breed} content={`${breed} ${level}`} />
            </DisplayField>
            <DisplayField title="Guilde">
              {guild && <span className="text-nowrap">{guild.name} ({guild.id})</span>}
            </DisplayField>
            <ScriptToken updateScriptToken={updateScriptToken} />
          </Container>
        </Col>
        <Col><img src={avatar} alt={`Blason de ${name} (${id})`} /></Col>
      </Row>
    </Container>
  )
}

export default TrollAccount;