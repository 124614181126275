import React from 'react';
import Map from './Map';
import { CardDeck } from 'reactstrap';
import Card from '../../common/components/Cards/Card';
import CardHeader from '../../common/components/Cards/CardHeader';
import CardBody from '../../common/components/Cards/CardBody';
import { MapContextProvider, MapContextProviderProps } from '../contexts/MapContext';
import MapFiltersHeader from './MapFiltersHeader';
import CardFooter from '../../common/components/Cards/CardFooter';
import MapFiltersFooter from './MapFiltersFooter';
import { FilterContextProvider } from '../contexts/FilterContext';
import { SearchContextProvider, SearchContextProviderProps } from '../contexts/SearchContext';
import MapInfos from './MapInfos';

interface DisplayMapProps extends SearchContextProviderProps, MapContextProviderProps {
  policyId?: string;
  title: string;
}

const DisplayMap: React.FC<DisplayMapProps> = ({ title, map, refreshView, getDetails, getBestiary, search, getDiplomacies }) => {
  return (
    <SearchContextProvider search={search} getDiplomacies={getDiplomacies} getBestiary={getBestiary}>
      <FilterContextProvider>
        <MapContextProvider map={map} refreshView={refreshView} getDetails={getDetails}>
          <CardDeck>
            <Card>
              <CardHeader>
                <MapFiltersHeader title={title} />
              </CardHeader>
              <CardBody>
                <MapInfos />
              </CardBody>
              <CardFooter>
                <MapFiltersFooter />
              </CardFooter>
            </Card>
          </CardDeck>
          <Map />
        </MapContextProvider>
      </FilterContextProvider>
    </SearchContextProvider>
  )
}

export default DisplayMap;