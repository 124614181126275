import { Service } from '../../services';

interface Equipment {
  id: number;
  name: string;
  spell: string;
  description: string;
  packed: boolean;
  identified: boolean;
  weight: number;
}

interface Owner {
  date: Date;
  used: Equipment[];
  others: { [type: string]: Equipment[] };
}

interface EquipmentServicesProps {
  getEquipments: (trollId: number) => Promise<Owner>;
  refreshEquipments: (trollId: number) => Promise<void>;
}

const EquipmentServices: Service<EquipmentServicesProps> = (serviceContext) => ({
  getEquipments: (trollId) => serviceContext.fetchApi<Owner>(`api/trolls/${trollId}/equipments`),
  refreshEquipments: (trollId) => serviceContext.fetchApi(`api/trolls/${trollId}/equipments`, {
    method: "PUT",
  })
})

export default EquipmentServices;