import React from 'react';
import AuthenticatedRoute from '../authentication/components/AuthenticatedRoute';
import Diplomacy from './views/Diplomacy';
import Diplomacies from './views/Diplomacies';
import AddDiplomacy from './views/AddDiplomacy';
import { Switch } from 'react-router-dom';

const Routes: React.FC<{}> = () => {
  return (
    <Switch>
      <AuthenticatedRoute exact path='/diplomacies' component={Diplomacies} />
      <AuthenticatedRoute exact path='/diplomacies/add' component={AddDiplomacy} />
      <AuthenticatedRoute exact path='/diplomacies/:id' component={Diplomacy} />
    </Switch>
  )
}

export default Routes;