import React from 'react';

interface FlyEffectProps {
  vitality?: number;
  view?: number;
  attack?: number;
  dodge?: number;
  damage?: number;
  regeneration?: number;
  armor?: number;
  turn?: number;
  magicMastery?: number;
  magicResistance?: number;
}

const FlyEffect: React.FC<FlyEffectProps> = ({ vitality, view, attack, dodge, damage, regeneration, armor, turn, magicMastery, magicResistance }) => {
  const effects = [];

  if (vitality) {
    effects.push(<Effect title="PV" value={vitality} />);
  }

  if (view) {
    effects.push(<Effect title="Vue" value={view} />);
  }

  if (attack) {
    effects.push(<Effect title="Att" value={attack} />);
  }

  if (dodge) {
    effects.push(<Effect title="Esq" value={dodge} />);
  }

  if (damage) {
    effects.push(<Effect title="Deg" value={damage} />);
  }

  if (regeneration) {
    effects.push(<Effect title="Reg" value={regeneration} />);
  }

  if (armor) {
    effects.push(<Effect title="Arm" value={armor} />);
  }

  if (turn) {
    effects.push(<Effect title="Tour" value={turn} unit="min" />);
  }

  if (magicMastery) {
    effects.push(<Effect title="MM" value={magicMastery} unit="%" />);
  }

  if (magicResistance) {
    effects.push(<Effect title="RM" value={magicResistance} unit="%" />);
  }

  const first = effects.shift();

  return (
    <span>
      {first}
      {effects.map((effect, index) => (
        <React.Fragment key={index}>
          <span> | </span>
          {effect}
        </React.Fragment>
      ))}
    </span>
  )
}

interface EffectProps {
  title: string;
  value: number;
  unit?: string;
}
const Effect: React.FC<EffectProps> = ({ title, value, unit }) => {
  const sign = value > 0 ? "+" : "";
  return <span><b>{title} : </b>{sign}{value}{unit}</span>
}

export default FlyEffect;