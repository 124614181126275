import { Service } from '../../services';

interface FlyType {
  name: string;
  vitality?: number;
  view?: number;
  attack?: number;
  dodge?: number;
  damage?: number;
  regeneration?: number;
  armor?: number;
  turn?: number;
  magicMastery?: number;
  magicResistance?: number;
}

interface Fly {
  id: number;
  name: string;
  type: FlyType;
  age: number;
  isPresent: boolean;
}

interface Troll {
  date: Date;
  flies: Fly[];
}

interface FlyServicesProps {
  getFlies: (trollId: number) => Promise<Troll>;
  refreshFlies: (trollId: number) => Promise<void>;
}

const FlyServices: Service<FlyServicesProps> = (serviceContext) => ({
  getFlies: (trollId) => serviceContext.fetchApi<Troll>(`api/trolls/${trollId}/flies`),
  refreshFlies: (trollId) => serviceContext.fetchApi(`api/trolls/${trollId}/flies`, {
    method: "PUT",
  })
})

export default FlyServices;