import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import BadgeIcon from '../../common/components/BadgeIcon';
import PaginedTable from '../../common/components/PaginedTable';
import { Metadata } from '../../common/components/Table';
import { FilterContext } from '../contexts/FilterContext';
import { Monster } from '../models/DetailedMap';
import Position from '../models/Position';

interface MonsterPosition extends Monster, Position {
}

const Monsters: React.FC<{ rows: MonsterPosition[] }> = ({ rows }) => {
  const { move } = useContext(FilterContext);
  const metadatas: Metadata<MonsterPosition>[] = [{
    key: "id",
    title: "Id"
  }, {
    key: "name",
    title: "Nom"
  }, {
    key: "level",
    title: "Niveau"
  }, {
    key: "x",
    title: "X"
  }, {
    key: "y",
    title: "Y"
  }, {
    key: "n",
    title: "N"
  }, {
    title: "",
    render: troll => <BadgeIcon tooltip="Centrer la vue" onClick={() => move({ x: troll.x, y: troll.y, n: troll.n})}><FontAwesomeIcon icon={faBullseye} size="2x" /></BadgeIcon>
  }];

  return <PaginedTable rows={rows} metadatas={metadatas} />
}

export default Monsters;