import React, { useState } from 'react';
import Button from '../../common/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BadgeIcon from '../../common/components/BadgeIcon';
import Modal from '../../common/components/Modals/Modal';
import ModalHeader from '../../common/components/Modals/ModalHeader';
import ModalBody from '../../common/components/Modals/ModalBody';
import ModalFooter from '../../common/components/Modals/ModalFooter';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import Input from '../../common/components/Input';
import ErrorArea from '../../common/components/ErrorArea';

interface AddTrollProps {
  policyId: string;
  policyName: string;
  sizeIcon?: SizeProp;
  icon? : IconProp;
  className?: string;
  sendInvitation: (trollId: number) => Promise<void>;
}

interface AddTrollValues {
  trollId: number;
}

const AddTroll: React.FC<AddTrollProps> = ({ policyName, sizeIcon, icon, className, sendInvitation }) => {
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);

  const { values: { trollId }, errors, handleSubmit, handleChange, isSubmitting } = useFormik<AddTrollValues>({
    initialValues: {
      trollId: 0
    },
    validationSchema: Yup.object().shape({
      trollId: Yup.number()
        .min(1, "N° troll incorrect")
        .required("Requis")
    }),
    onSubmit: async ({ trollId }, { resetForm }) => {
      await sendInvitation(Number((trollId)));
      setModal(false);
      resetForm();
    }
  })

  return (
    <>
      <BadgeIcon className={`icon-img icon-button${!className ? "" : ` ${className}`}`} tooltip="Inviter un troll" onClick={toggle}>
        <FontAwesomeIcon icon={icon || faEnvelopeOpenText} size={sizeIcon} />
      </BadgeIcon>
      <Modal backdrop={true} isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Ajouter un troll au groupe {policyName}</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Input type="text" name="trollId" onChange={handleChange} value={trollId} placeholder="N° troll" />
            <ErrorArea>{errors.trollId}</ErrorArea>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={isSubmitting} color="primary">Ajouter</Button>{' '}
            <Button disabled={isSubmitting} color="secondary" onClick={toggle}>Annuler</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

export default AddTroll;