import React from 'react';
import { Badge } from 'reactstrap';
import Tooltip from '../../common/components/Tooltip';

import './BadgeActionPoints.scss';

export interface BadgeActionPointsProps extends React.HTMLAttributes<HTMLElement> {
  actionPoints: number;
  canPlay: boolean;
}

const BadgeActionPoints: React.FC<BadgeActionPointsProps> = ({ actionPoints, canPlay, className, ...props }) => {
  return (
    <Badge {...props} className={`badge-action-points badge-${canPlay ? "ready" : "unready"} ${className}`}>
      <Tooltip description={canPlay ? "Disponible" : "Non disponible"}>{actionPoints}PA</Tooltip>
    </Badge>
  )
}

export default BadgeActionPoints;