import React from 'react';
import Loading from '../../common/components/Loading';
import { Row, Col, CardDeck } from 'reactstrap';
import DisplayDate from '../../common/components/DisplayDate';
import Table, { Metadata } from '../../common/components/Table';
import { Link } from 'react-router-dom';
import Card from '../../common/components/Cards/Card';
import CardHeader from '../../common/components/Cards/CardHeader';
import CardBody from '../../common/components/Cards/CardBody';
import CardFooter from '../../common/components/Cards/CardFooter';
import Breadcrumb from '../../common/components/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../common/components/Breadcrumb/BreadcrumbItem';

interface Position {
  x: number;
  y: number;
  n: number;
}

interface Item {
  id: number;
  name: string;
  distance: number;
  position: Position;
}

interface Monster extends Item {
  level?: number;
}

interface Troll extends Item {
  breed: string;
  level: number;
}

export interface TrollViewProps {
  origin: Position;
  radius: number;
  date: Date;
  trolls: Troll[];
  monsters: Monster[];
  treasures: Item[];
  places: Item[];
  mushrooms: Item[];
}

interface Localization {
  id: number;
  name: string;
  distance: number;
  x: number;
  y: number;
  n: number;
}

interface TrollLocalization extends Localization {
  breed: string;
  level: number;
}

interface MonsterLocalization extends Localization {
  level?: number;
}

interface ViewGridsProps {
  trollId: number;
  policyId?: string;
  load: () => Promise<TrollViewProps>;
}

interface ViewGridsState {
  isLoaded: boolean;
  origin: Position;
  radius: number;
  date: Date;
  trolls: TrollLocalization[];
  monsters: Localization[];
  treasures: Localization[];
  places: Localization[];
  mushrooms: Localization[];
}

class ViewGrids extends React.PureComponent<ViewGridsProps, ViewGridsState> {
  constructor(props: ViewGridsProps) {
    super(props);

    this.state = {
      isLoaded: false,
      origin: { x: 0, y: 0, n: 0 },
      radius: 0,
      date: new Date(),
      trolls: [],
      monsters: [],
      treasures: [],
      places: [],
      mushrooms: [],
    }
  }

  public render() {
    const { isLoaded, origin, radius, date, trolls, monsters, treasures, places, mushrooms } = this.state;
    if (!isLoaded) {
      return <Loading />
    }

    const { policyId, trollId } = this.props;

    return (
      <>
      <Breadcrumb>
        {!policyId ? null : <BreadcrumbItem><Link to={`/policies/${policyId!}`}>Groupe</Link></BreadcrumbItem>}
        <BreadcrumbItem>{trollId}</BreadcrumbItem>
        <BreadcrumbItem active>Vue</BreadcrumbItem>
      </Breadcrumb>
        <CardDeck>
          <Card>
            <CardHeader>Paramètres</CardHeader>
            <CardBody>
              <Row>
                <Col><b>Origine</b></Col>
                <Col><span>X={origin.x},Y={origin.y},N={origin.n}</span></Col>
                <Col><b>Rayon</b></Col>
                <Col><span>{radius}</span></Col>
              </Row>
            </CardBody>
            <CardFooter><small>Mis à jour <DisplayDate date={date} /></small></CardFooter>
          </Card>
        </CardDeck>
        <h2>Trolls</h2>
        <TrollViewGrid rows={trolls} />
        <h2>Monstres</h2>
        <MonsterViewGrid rows={monsters} />
        <h2>Trésors</h2>
        <ItemViewGrid rows={treasures} />
        <h2>Lieux</h2>
        <ItemViewGrid rows={places} />
        <h2>Champignons</h2>
        <ItemViewGrid rows={mushrooms} />
      </>
    )
  }

  public componentDidMount() {
    this.loadView();
  }

  private async loadView(): Promise<Omit<ViewGridsState, "isLoaded">> {
    const { load } = this.props;
    var trollView = await load();

    const newView: Omit<ViewGridsState, "isLoaded"> = {
      origin: trollView.origin,
      radius: trollView.radius,
      date: trollView.date,
      trolls: trollView.trolls.map(troll => ({
        id: troll.id,
        name: troll.name,
        breed: troll.breed,
        level: troll.level,
        distance: troll.distance,
        x: troll.position.x,
        y: troll.position.y,
        n: troll.position.n,
      })),
      monsters: trollView.monsters.map(monster => ({
        id: monster.id,
        name: monster.name,
        level: monster.level,
        distance: monster.distance,
        x: monster.position.x,
        y: monster.position.y,
        n: monster.position.n,
      })),
      treasures: trollView.treasures.map(treasure => ({
        id: treasure.id,
        name: treasure.name,
        distance: treasure.distance,
        x: treasure.position.x,
        y: treasure.position.y,
        n: treasure.position.n,
      })),
      places: trollView.places.map(place => ({
        id: place.id,
        name: place.name,
        distance: place.distance,
        x: place.position.x,
        y: place.position.y,
        n: place.position.n,
      })),
      mushrooms: trollView.mushrooms.map(mushroom => ({
        id: mushroom.id,
        name: mushroom.name,
        distance: mushroom.distance,
        x: mushroom.position.x,
        y: mushroom.position.y,
        n: mushroom.position.n,
      })),
    }

    this.setState({
      isLoaded: true,
      ...newView
    });

    return newView;
  }
}

interface ItemViewGridRow {
  id: number;
  name: string;
  distance: number;
  x: number;
  y: number;
  n: number;
}

interface TrollViewGridRow extends ItemViewGridRow {
  breed: string;
  level: number;
}

interface MonsterViewGridRow extends ItemViewGridRow {
  level?: number;
}

const ItemViewGrid: React.FC<{ rows: ItemViewGridRow[] }> = ({ rows }) => {
  const metadatas: Metadata<ItemViewGridRow>[] = [{
    key: "distance",
    title: "Dist."
  }, {
    key: "id",
    title: "Id"
  }, {
    key: "name",
    title: "Nom"
  }, {
    key: "x",
    title: "X"
  }, {
    key: "y",
    title: "Y"
  }, {
    key: "n",
    title: "N"
  }];

  return <Table rows={rows} metadatas={metadatas} />
}

const TrollViewGrid: React.FC<{ rows: TrollViewGridRow[] }> = ({ rows }) => {
  const metadatas: Metadata<TrollViewGridRow>[] = [{
    key: "distance",
    title: "Dist."
  }, {
    key: "id",
    title: "Id"
  }, {
    key: "name",
    title: "Nom"
  }, {
    key: "breed",
    title: "Race"
  }, {
    key: "level",
    title: "Niveau"
  }, {
    key: "x",
    title: "X"
  }, {
    key: "y",
    title: "Y"
  }, {
    key: "n",
    title: "N"
  }];

  return <Table rows={rows} metadatas={metadatas} />
}

const MonsterViewGrid: React.FC<{ rows: MonsterViewGridRow[] }> = ({ rows }) => {
  const metadatas: Metadata<MonsterViewGridRow>[] = [{
    key: "distance",
    title: "Dist."
  }, {
    key: "id",
    title: "Id"
  }, {
    key: "name",
    title: "Nom"
  }, {
    key: "level",
    title: "Niveau"
  }, {
    key: "x",
    title: "X"
  }, {
    key: "y",
    title: "Y"
  }, {
    key: "n",
    title: "N"
  }];

  return <Table rows={rows} metadatas={metadatas} />
}

export default ViewGrids;