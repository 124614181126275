import Position from './Position';

export enum MapMenuItems {
  filters,
  diplomacy,
  trolls,
  monsters
}

export interface BaseCave {
  n: number;
}

interface RawCave extends BaseCave {
  nbTrolls: number;
  nbMonsters: number;
  nbTreasures: number;
  nbMushrooms: number;
  nbPlaces: number;
}

interface MapCave extends BaseCave {
  hasTroll: boolean;
  hasMonster: boolean;
  hasTreasure: boolean;
  hasMushroom: boolean;
  hasPlace: boolean;
  isHighlight: boolean;
  isFriend: boolean;
  isEnemy: boolean;
}

export interface BaseArea {
  x: number;
  y: number;
}

export interface Area<TCave extends BaseCave> extends BaseArea {
  caves: TCave[];
}

export interface MapArea extends Area<MapCave> {
}

export interface RawArea extends Area<RawCave> {
}

export interface View {
  areas: { [y: number]: { [x: number]: LightArea } };
}

export interface LightArea {
  x: number;
  y: number;
  hasTroll: boolean;
  hasMonster: boolean;
  hasTreasure: boolean;
  hasMushroom: boolean;
  hasPlace: boolean;
  isHighlight: boolean;
  isFriend: boolean;
  isEnemy: boolean;
}

export default interface Map<TArea extends BaseArea> {
  origin: Position;
  radius: number;
  date?: Date;
  token: string;
  areas: TArea[];
}