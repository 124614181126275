import React, { useEffect, useState } from 'react';
import { CardGroup } from 'reactstrap';
import Button from '../../common/components/Button';
import Loading from '../../common/components/Loading';
import PreviousButton from '../../common/components/PreviousButton';
import CardEquipments from './CardEquipments';
import { Equipment } from './DisplayEquipment';

interface Owner {
  date: Date;
  used: Equipment[];
  others: { [type: string]: Equipment[] };
}

export interface TrollEquipmentsProps {
  loadEquipments: () => Promise<Owner>;
  refreshEquipments: () => Promise<void>;
}

interface TrollEquipmentsState {
  isLoaded: boolean;
  owner?: Owner;
}

const TrollEquipments: React.FC<TrollEquipmentsProps> = ({ loadEquipments, refreshEquipments }) => {
  const [{ isLoaded, owner }, setState] = useState<TrollEquipmentsState>({ isLoaded: false });

  useEffect(() => {
    loadEquipments()
      .then(owner => setState({
        isLoaded: true,
        owner
      }));
  }, [loadEquipments]);

  const handleRefreshEquipments = async () => {
    await refreshEquipments();
    setState({
      owner: await loadEquipments(),
      isLoaded: true
    })
  }

  if (!isLoaded) {
    return <Loading />
  }

  if (!owner) {
    return (
      <div>
        <Button onClick={() => refreshEquipments()}>Charger l'équipement</Button>
        <PreviousButton />
      </div>
    )
  }

  const { date, used, others } = owner;

  const types = Object.keys(others);
  const groups = [];
  for (let index = 0; index < Math.ceil(types.length / 2) * 2; index += 2) {
    groups.push(types.slice(index, index + 2));
  }
  return (
    <>
      <CardGroup>
        <CardEquipments lastUpdate={date} equipments={used} type="Equipements" refresh={handleRefreshEquipments}>
          {
            groups.map((group, groupIndex) => (
              <CardGroup key={groupIndex}>
                {group.map((type, index) => (
                  <CardEquipments key={index} equipments={others[type]} type={type} />
                ))}
              </CardGroup>
            ))
          }
        </CardEquipments>
      </CardGroup>
    </>
  )
}

export default TrollEquipments;