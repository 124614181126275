import React, { useState } from 'react';
import DisplayPolicy from './DisplayPolicy';

interface Guild {
  id: number;
  name: string;
}

interface Troll {
  id: number;
  name: string;
}

interface DiplomacyPolicy {
  trolls: Troll[];
  guilds: Guild[];
}

interface Diplomacy {
  friends: DiplomacyPolicy;
  enemies: DiplomacyPolicy;
}

interface DiplomacyPoliciesProps extends Diplomacy {
  onChange?: (Diplomacy: Diplomacy) => void;
}

const DiplomacyPolicies: React.FC<DiplomacyPoliciesProps> = ({ friends, enemies, onChange }) => {

  const handleChange = (p: Partial<Diplomacy>) => {
    const newPolicies = {
      ...{ friends, enemies },
      ...p
    }
    if (!!onChange) {
      onChange(newPolicies);
    }
  }

  const addTrollFriend = (trollId: number) => {
    if (!friends.trolls.some(friend => friend.id === trollId)) {
      friends.trolls.push({ id: trollId, name: "" });
      handleChange({ friends });
    }
  }

  const addTrollEnemy = (trollId: number) => {
    if (!enemies.trolls.some(enemy => enemy.id === trollId)) {
      enemies.trolls.push({ id: trollId, name: "" });
      handleChange({ enemies });
    }
  }

  const addGuildFriend = (guildId: number) => {
    if (!friends.guilds.some(friend => friend.id === guildId)) {
      friends.guilds.push({ id: guildId, name: "" });
      handleChange({ friends });
    }
  }

  const addGuildEnemy = (guildId: number) => {
    if (!enemies.guilds.some(enemy => enemy.id === guildId)) {
      enemies.guilds.push({ id: guildId, name: "" });
      handleChange({ enemies });
    }
  }

  const deleteTrollFriend = (trollId: number) => {
    if (friends.trolls.some(friend => friend.id === trollId)) {
      handleChange({
        friends: {
          ...friends,
          trolls: friends.trolls.filter(f => f.id !== trollId)
        }
      });
    }
  }

  const deleteTrollEnemy = (trollId: number) => {
    if (enemies.trolls.some(enemy => enemy.id === trollId)) {
      handleChange({
        enemies: {
          ...enemies,
          trolls: enemies.trolls.filter(enemy => enemy.id !== trollId)
        }
      });
    }
  }

  const deleteGuildFriend = (guildId: number) => {
    if (friends.guilds.some(friend => friend.id === guildId)) {
      handleChange({
        friends: {
          ...friends,
          guilds: friends.guilds.filter(f => f.id !== guildId)
        }
      });
    }
  }

  const deleteGuildEnemy = (guildId: number) => {
    if (enemies.guilds.some(enemy => enemy.id === guildId)) {
      handleChange({
        enemies: {
          ...enemies,
          guilds: enemies.guilds.filter(enemy => enemy.id !== guildId)
        }
      });
    }
  }

  return (
    <div>
      <h2>Amis</h2>
      <DisplayPolicy
        {...friends}
        addTroll={addTrollFriend}
        addGuild={addGuildFriend}
        deleteTroll={troll => deleteTrollFriend(troll.id)}
        deleteGuild={guild => deleteGuildFriend(guild.id)}
      />
      <h2>Ennemis</h2>
      <DisplayPolicy
        {...enemies}
        addTroll={addTrollEnemy}
        addGuild={addGuildEnemy}
        deleteTroll={troll => deleteTrollEnemy(troll.id)}
        deleteGuild={guild => deleteGuildEnemy(guild.id)}
      />
    </div>
  )
}

export default DiplomacyPolicies;