import React from 'react';
import { CardHeader as CardHeaderStrap, CardHeaderProps as CardHeaderStrapProps } from 'reactstrap';
import './CardHeader.scss';

interface CardProps extends CardHeaderStrapProps {

}

const CardHeader: React.FC<CardProps> = (props) => {
  return (
    <CardHeaderStrap {...props} />
  )
}

export default CardHeader;