import React from 'react';
import { Modal as ModalStrap, ModalProps as ModalStrapProps } from 'reactstrap';

interface ModalProps extends ModalStrapProps {
}

const Modal: React.FC<ModalProps> = (props) => {
  return (
    <ModalStrap {...props} />
  )
}

export default Modal;