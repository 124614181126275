import React from 'react';
import DisplayField from '../../common/components/DisplayField';

export interface TirednessProps {
  value: number;
  physicalBonus: number;
}

const Tiredness: React.FC<TirednessProps & { name: string }> = ({ name, value, physicalBonus }) => {
  const pb = physicalBonus < 0 ? `${physicalBonus}` : `+${physicalBonus}`;
  return (
    <DisplayField title={name}>{`${value} ${pb}`}</DisplayField>
  )
}

export default Tiredness;