import React, {  } from 'react';
import { Link } from 'react-router-dom';
import AddTroll from './AddTroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Table, { Metadata } from '../../common/components/Table';
import BadgeIcon from '../../common/components/BadgeIcon';

export interface Policy {
  id: string;
  name: string;
  nbTrolls: number;
  nbInvitations: number;
  isOwner: boolean;
}

interface PoliciesProps {
  policies: Policy[];
  refresh: () => Promise<void>;
  deletePolicy: (policyId: string) => Promise<void>;
  sendInvitation: (policyId: string, trollid: number) => Promise<void>;
  leavePolicy: (policyId: string) => Promise<void>;
}

const Policies: React.FC<PoliciesProps> = ({ policies, refresh, deletePolicy, sendInvitation, leavePolicy }) => {
  const removePolicy: (row: Policy) => Promise<void> = async row => {
    if (window.confirm(`Supprimer ${row.name} ?`)) {
      await deletePolicy(row.id);
      await refresh();
    }
  }

  const addInvitation: (policyId: string, trollId: number) => Promise<void> = async (policyId, trollId) => {
    await sendInvitation(policyId, trollId)
    await refresh();
  }

  const leave: (row: Policy) => Promise<void> = async row => {
    if (window.confirm(`Quitter le groupe ${row.name} ?`)) {
      await leavePolicy(row.id);
      await refresh();
    }
  }

  const metadatas: Metadata<Policy>[] = [{
    title: "Nom",
    render: row => (<Link to={`/policies/${row.id}`}>{row.name}</Link>)
  }, {
    key: "nbTrolls",
    title: "Trolls"
  }, {
    key: "nbInvitations",
    title: "Invitations"
  }, {
    title: "",
    render: (row) => (<BadgeIcon tooltip="Quitter le groupe" onClick={() => leave(row)}><FontAwesomeIcon icon={faSignOutAlt} size="2x" /></BadgeIcon>)
  }, {
    title: "",
    render: (row) => (row.isOwner ? <AddTroll policyId={row.id} policyName={row.name} sizeIcon="2x" sendInvitation={(trollId) => addInvitation(row.id, trollId)} /> : null)
  }, {
    title: "",
    render: row => (row.isOwner ? <BadgeIcon tooltip="Supprimer le groupe" onClick={() => removePolicy(row)}><FontAwesomeIcon icon={faTrashAlt} size="2x" /></BadgeIcon> : null)
  }];

  return (
    <Table rows={policies} metadatas={metadatas} />
  )
}

export default Policies;