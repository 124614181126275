import React, { createContext, useState } from 'react';

export interface UserContext {
  id: string;
  login: string;
  trollid?: number;
  avatar?: string;
}

export interface AuthContextProps {
  authenticated: boolean;
  user?: UserContext;
  signin: (user: UserContext) => void;
  signout: () => void;
}

const initialState: AuthContextProps = { authenticated: false, signin: () => { }, signout: () => { } };

export const AuthContext = createContext<AuthContextProps>(initialState);

export const AuthProvider: React.FC = (props) => {
  const [authContext, updateAuthContext] = useState(initialState);

  function signin(user: UserContext) {
    updateAuthContext({ ...authContext, authenticated: true, user });
  }

  function signout() {
    updateAuthContext({ ...authContext, authenticated: false, user: undefined });
  }

  const initialContext = { ...authContext, signin, signout };

  return (
    <AuthContext.Provider value={initialContext}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;