import React from 'react';
import ViewGrids, { TrollViewProps } from '../components/ViewGrids';
import { useService } from '../../services';
import { useParams } from 'react-router-dom';
import ViewServices from '../services/ViewServices';

const TrollView: React.FC = () => {
    const { id, policyId } = useParams<{ id: string, policyId?: string }>();
    const trollId = parseInt(id);
    const viewServices = useService(ViewServices);

    const loadView: () => Promise<TrollViewProps> = () => viewServices.getView(trollId);

    return <ViewGrids {...{ trollId, policyId }} load={loadView} />
}

export default TrollView;