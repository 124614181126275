import { faPlus, faTrashAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BadgeIcon from '../../common/components/BadgeIcon';
import BadgeLinkIcon from '../../common/components/BadgeLinkIcon';
import Breadcrumb from '../../common/components/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../common/components/Breadcrumb/BreadcrumbItem';
import Card from '../../common/components/Cards/Card';
import CardBody from '../../common/components/Cards/CardBody';
import CardHeader from '../../common/components/Cards/CardHeader';
import Loading from '../../common/components/Loading';
import Table, { Metadata } from '../../common/components/Table';
import AddTroll from './AddTroll';
import TrollName from './TrollName';

interface InvitationTroll {
  id: number;
  name: string;
  breed: string;
  level: number;
}

interface Policy {
  name: string;
  isOwner: boolean;
}

export interface Invitation {
  trolls: InvitationTroll[];
}

interface InvitationDetailsState {
  trolls: InvitationTroll[];
  policy: Policy;
  isLoaded: boolean;
}

interface InvitationDetailsProps {
  policyId: string;
  getInvitations: (policyId: string) => Promise<Invitation>;
  getPolicy: (policyId: string) => Promise<Policy>;
  removeInvitation: (policyId: string, trollId: number) => Promise<void>;
  sendInvitation: (policyId: string, trollid: number) => Promise<void>;
}

const Invitations: React.FC<InvitationDetailsProps> = ({ policyId, getInvitations, getPolicy, removeInvitation, sendInvitation }) => {
  const [state, setState] = useState<InvitationDetailsState>({ trolls: [], policy: { name: "", isOwner: false }, isLoaded: false });
  const { isLoaded, policy: { isOwner, name: policyName }, trolls } = state;

  const reloadInvitations = async () => {
    const { trolls } = await getInvitations(policyId);
    setState({
      ...state,
      trolls
    })
  }

  useEffect(() => {
    getPolicy(policyId)
      .then(async policy => {
        const { trolls } = await getInvitations(policyId)
        setState({
          policy,
          trolls,
          isLoaded: true
        })
      })
  }, []);

  if (!isLoaded) {
    return <Loading />
  }

  const remove: (row: InvitationTroll) => Promise<void> = async row => {
    if (window.confirm(`Supprimer l'invitation pour ${row.name} (${row.id}) ?`)) {
      await removeInvitation(policyId, row.id);
      await reloadInvitations();
    }
  }

  const handleSendInvitation = async (trollId: number) => {
    await sendInvitation(policyId, trollId);
    toast.success(`Troll n°${trollId} ajouté`);
    await reloadInvitations();
  }

  const metadatas: Metadata<InvitationTroll>[] = [{
    title: "Nom",
    render: ({ id, name }) => <TrollName id={id} name={name} isActive={false} />
  }, {
    key: "breed",
    title: "Race",
    className: "d-none d-xl-table-cell"
  }, {
    key: "level",
    title: "Niveau",
    className: "d-none d-xl-table-cell"
  }]

  if (isOwner) {
    metadatas.push({
      title: "",
      render: row => <BadgeIcon tooltip="Supprimer l'invitation" onClick={() => remove(row)}><FontAwesomeIcon icon={faTrashAlt} size="2x" /></BadgeIcon>
    })
  }

  return (
    <>
      <Breadcrumb>
        {policyId ? <BreadcrumbItem><Link to={`/policies/${policyId}`}>{policyName}</Link></BreadcrumbItem> : null}
        <BreadcrumbItem active>Invitations</BreadcrumbItem>
      </Breadcrumb>
      <h1 className="d-flex justify-content-between">
        <span>{policyName}</span>
        <div className="text-nowrap">
          <BadgeLinkIcon
            tooltip="Voir le groupe"
            to={`/policies/${policyId}`}
          >
            <FontAwesomeIcon icon={faUsers} />
          </BadgeLinkIcon>
          {isOwner ? <AddTroll className="ml-1" policyId={policyId} icon={faPlus} policyName={policyName} sendInvitation={trollId => handleSendInvitation(trollId)} /> : null}
        </div>
      </h1>
      <Card>
        <CardHeader>Invitations en attente</CardHeader>
        <CardBody><Table rows={trolls} metadatas={metadatas} /></CardBody>
      </Card>
    </>
  )
}

export default Invitations;