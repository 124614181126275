import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DisplayField from '../../common/components/DisplayField';
import Input from '../../common/components/Input';
import ErrorArea from '../../common/components/ErrorArea';
import Button from '../../common/components/Button';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import BadgeIcon from '../../common/components/BadgeIcon';
import { Col, InputGroup, InputGroupAddon, Row } from 'reactstrap';

interface ScriptTokenProps {
  updateScriptToken: (scriptToken: string) => Promise<void>;
}

const ScriptToken: React.FC<ScriptTokenProps> = ({ updateScriptToken }) => {
  const [isEdition, setEdition] = useState(false);
  const { isSubmitting, values: { scriptToken }, errors, touched, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      scriptToken: ""
    },
    validationSchema: Yup.object().shape({
      scriptToken: Yup.string()
        .required("Requis")
    }),
    onSubmit: async ({ scriptToken }, { setSubmitting }) => {
      try {
        await updateScriptToken(scriptToken);
        setEdition(false);
        toast.success("Modifications enregistrées")
      }
      catch {
        setSubmitting(false);
      }
    }
  })

  if (!isEdition) {
    return (
      <DisplayField title="Mot de passe restreint">
        <BadgeIcon onClick={() => setEdition(true)} tooltip="Modifier le mot de passe restreint de mon troll">
          <FontAwesomeIcon icon={faEdit} size="2x" />
        </BadgeIcon>
      </DisplayField>
    )
  }

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit}>
          <InputGroup>
            <Input disabled={isSubmitting} type="text" name="scriptToken" placeholder="Mot de passe restreint" value={scriptToken} onChange={handleChange} onBlur={handleBlur} />
            <InputGroupAddon addonType="append">
              <FontAwesomeIcon icon={faCheckCircle} size="2x" onClick={() => handleSubmit()} color="green" />
            </InputGroupAddon>
            <InputGroupAddon addonType="append">
              <FontAwesomeIcon icon={faTimesCircle} size="2x" onClick={() => setEdition(false)} color="red" />
            </InputGroupAddon>
          </InputGroup>
          <Button type="submit" className="d-none" disabled={isSubmitting}>Enregistrer</Button>
          {errors.scriptToken && touched.scriptToken && <ErrorArea>{errors.scriptToken}</ErrorArea>}
        </form>
      </Col>
    </Row>
  )
}

export default ScriptToken;