import React from 'react';
import { Link } from 'react-router-dom';

interface TrollNameProps {
  id: number;
  policyId?: string;
  name: string;
  isActive: boolean;
}

const LinkTroll: React.FC<TrollNameProps> = (troll) => {
  const link = !troll.policyId ? `/trolls/${troll.id}` : `/policies/${troll.policyId!}/trolls/${troll.id}`;
  return <Link to={link}><Troll {...troll} /></Link>
}

const Troll: React.FC<TrollNameProps> = ({ id, name }) => (
  <>
    <span>{name}</span> <span>({id})</span>
  </>
)

const TrollName: React.FC<TrollNameProps> = (troll) => {
  return troll.isActive ? <LinkTroll {...troll} /> : <Troll {...troll} />
}

export default TrollName;