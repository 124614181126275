import React from 'react';

import BadgeIcon from './BadgeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface RefreshButtonProps {
  disabled?: boolean;
  confirmMessage?: string;
  size?: SizeProp;
  tooltip?: string | React.ReactNode;
  className?: string
  refresh: () => Promise<void>
}

const RefreshButton: React.FC<RefreshButtonProps> = ({ disabled, confirmMessage, size, tooltip, className, refresh }) => {
  const handleRefresh: () => void = async () => {
    if (window.confirm(confirmMessage || "Le nombre d'appels aux scripts publics est limité. Etes vous sur ?")) {
      await refresh();
    }
  }

  return (
    <BadgeIcon className={className} tooltip={tooltip || "Mettre à jour"} disabled={disabled} onClick={handleRefresh}>
      <FontAwesomeIcon icon={faSyncAlt} size={size} />
    </BadgeIcon>
  )
}

export default RefreshButton;