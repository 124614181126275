import 'bootstrap/dist/css/bootstrap.css';

import * as ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthProvider from './authentication/components/AuthContext';
import { ServiceProvider } from './services';
import AuthLoader from './authentication/components/AuthLoader';
import ThemeProvider from './common/components/ThemeContext';
import { Router } from 'react-router';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

ReactDOM.render(
  <Router history={history}>
    <ThemeProvider>
      <AuthProvider>
        <ServiceProvider>
          <AuthLoader>
            <App />
          </AuthLoader>
        </ServiceProvider>
      </AuthProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
