import { stringify } from 'querystring';
import { Service } from '../../services';

interface Policy {
  id: string;
  name: string;
}

interface AddPolicy {
  name: string;
}

interface PolicyFilters {
  actionDate: Date;
  shortBreed: string;
  skill: number;
  spell: number;
}

interface LightPolicy extends Policy {
  nbTrolls: number;
  nbInvitations: number;
  isOwner: boolean;
}

interface FullPolicy extends Policy {
  trolls: Troll[];
  isOwner: boolean;
}

interface Vitality {
  current: number;
  max: number;
}

interface Localization {
  x: number;
  y: number;
  n: number;
}

interface Tiredness {
  value: number;
  physicalBonus: number;
}

interface ActionLimitDate {
  value: Date;
  nextEstimated: Date;
}

interface LightTroll {
  id: number;
  name: string;
  breed: string;
  level: number;
}

interface ProgrammedActions {
  nbCounterAttack: number;
  nbParry: number;
  retreats: string[];
}

interface Troll {
  id: number;
  name: string;
  breed: string;
  level: number;
  breedAndLevel: string;
  actionPoints: number;
  concentration: number;
  tiredness: Tiredness;
  actionLimitDate: ActionLimitDate;
  vitality: Vitality;
  isActive: boolean;
  canPlay: boolean;
  localization: Localization;
  lastUpdate: Date;
  isHidden: boolean;
  isInvisible: boolean;
  isIntangible: boolean;
  isSticky: boolean;
  isDown: boolean;
  isRunning: boolean;
  isLevitating: boolean;
  programmedActions: ProgrammedActions;
  isOwner: boolean;
}

interface Position {
  x: number;
  y: number;
  n: number;
}

interface LightCave {
  n: number;
  nbTrolls: number;
  nbMonsters: number;
  nbTreasures: number;
  nbPlaces: number;
  nbMushrooms: number;
}

interface LightArea {
  x: number;
  y: number;
  caves: LightCave[];
}

interface MatchPosition {
  x: number;
  y: number;
  n: number[];
}

interface ItemMatch {
  id: number;
  name: string;
}

interface TrollMatch extends ItemMatch {
  breed: string;
  level: number;
  shortBreed: string;
  guild?: { id: number, name: string }
}

interface MonsterMatch extends ItemMatch {
  level?: number;
}

interface MatchDetails {
  x: number;
  y: number;
  n: number;
  trolls: TrollMatch[];
  monsters: MonsterMatch[];
}

interface Matches {
  positions: MatchPosition[];
  details: MatchDetails[];
}

interface Search {
  highlights: Matches;
  friends: Matches;
  enemies: Matches;
}

interface MapView {
  origin: Position;
  radius: number;
  token: string;
  areas: LightArea[];
  search: Search;
  diplomacy?: { id: string, name: string };
  policy: { id: string, name: string };
}

enum PolicyRight {
  Owner = 0,
  Refresh = 1,
  ReadOnly = 2
}

interface PolicyServicesProps {
  addPolicy: (request: AddPolicy) => Promise<{ id: string }>;
  getPolicy: (policyId: string, filters?: Partial<PolicyFilters>) => Promise<FullPolicy>;
  getPolicies: () => Promise<LightPolicy[]>;
  deletePolicy: (id: string) => Promise<void>;
  excludeTroll: (policyId: string, trollId: number) => Promise<void>;
  refreshTroll: (trollId: number) => Promise<void>;
  leavePolicy: (policyId: string) => Promise<void>;
  getPolicyViewMap: (policyId: string) => Promise<MapView>;
  getInvitations: (policyId: string) => Promise<{ trolls: LightTroll[] }>;
  sendInvitation: (policyId: string, trollId: number) => Promise<void>;
  removeInvitation: (policyId: string, trollId: number) => Promise<void>;
  grantOwner: (policyId: string, trollId: number) => Promise<void>;
  grantMember: (policyId: string, trollId: number) => Promise<void>;
}

export const PolicyServices: Service<PolicyServicesProps> = (serviceContext) => ({
  addPolicy: request => serviceContext.fetchApi(`api/policies`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify(request)
  }),
  getPolicy: (policyId, filters) => serviceContext.fetchApi<FullPolicy>(`api/policies/${policyId}${!filters ? "" : `?${stringify({ ...filters, actionDate: filters.actionDate ? filters.actionDate.toISOString() : undefined }).toString()}`}`),
  getPolicies: () => serviceContext.fetchApi<LightPolicy[]>(`api/policies`),
  deletePolicy: (id) => serviceContext.fetchApi(`api/policies/${id}`, {
    method: "DELETE",
  }),
  excludeTroll: (policyId, trollId) => serviceContext.fetchApi(`api/policies/${policyId}/trolls/${trollId}`, {
    method: "DELETE",
  }),
  refreshTroll: (trollId) => serviceContext.fetchApi(`api/trolls/${trollId}`, {
    method: "PUT",
  }),
  leavePolicy: (policyId) => serviceContext.fetchApi(`api/accounts/policies/${policyId}`, {
    method: "DELETE"
  }),
  getPolicyViewMap: (policyId) => serviceContext.fetchApi<MapView>(`api/policies/${policyId}/views`),
  getInvitations: (policyId) => serviceContext.fetchApi<{ trolls: LightTroll[] }>(`api/policies/${policyId}/invitations`),
  sendInvitation: (policyId, trollId) => serviceContext.fetchApi(`api/policies/${policyId}/invitations`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify({ trollId })
  }),
  removeInvitation: (policyId, trollId) => serviceContext.fetchApi(`api/policies/${policyId}/invitations/${trollId}`, {
    method: "DELETE"
  }),
  grantOwner: (policyId, trollId) => serviceContext.fetchApi(`api/policies/${policyId}/rights/${trollId}`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "PUT",
    body: JSON.stringify({ right: PolicyRight.Owner })
  }),
  grantMember: (policyId, trollId) => serviceContext.fetchApi(`api/policies/${policyId}/rights/${trollId}`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "PUT",
    body: JSON.stringify({ right: PolicyRight.Refresh })
  }),
})

export default PolicyServices;