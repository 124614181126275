import React from 'react';
import { Badge, BadgeProps } from 'reactstrap';
import './BadgeIcon.scss';
import Tooltip from './Tooltip';


interface IconProps {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface TooltipProps {
  tooltip?: string | React.ReactNode;
}

interface BadgeIconProps extends BadgeProps, IconProps, TooltipProps {
}

const BaseTooltip: React.FC<TooltipProps> = ({ tooltip, children }) => {
  if (tooltip) {
    return <Tooltip description={tooltip}>{children}</Tooltip>
  }

  return <>{children}</>
}

const BadgeIcon: React.FC<BadgeIconProps> = ({ disabled, className, onClick, tooltip, children, ...props}) => {
  const onBadgeClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!disabled && onClick) {
      onClick(event);
    }
  }
  const classNames = ["icon-img", className].filter(c => !!c);
  if (disabled) {
    classNames.push("icon-img-disabled");
  }
  if (!!onClick) {
    classNames.push("icon-button");
  }
  return (
    <Badge className={classNames.join(" ")} onClick={onBadgeClick} {...props}>
      <BaseTooltip tooltip={tooltip}>{children}</BaseTooltip>
    </Badge>
  )
}

export default BadgeIcon;