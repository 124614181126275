import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Container } from 'reactstrap';
import Button from '../../common/components/Button';
import DisplayField from '../../common/components/DisplayField';
import Input from '../../common/components/Input';
import ErrorArea from '../../common/components/ErrorArea';
import { AuthContext } from '../../authentication/components/AuthContext';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import Modal from '../../common/components/Modals/Modal';
import ModalHeader from '../../common/components/Modals/ModalHeader';
import ModalBody from '../../common/components/Modals/ModalBody';
import ModalFooter from '../../common/components/Modals/ModalFooter';
import BadgeIcon from '../../common/components/BadgeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export interface UpdatePasswordValues {
  oldPassword: string;
  newPassword: string;
}

interface UpdatePasswordForm extends UpdatePasswordValues {
  confirmPassword: string;
}

interface UpdatePasswordProps {
  updatePassword: (values: UpdatePasswordValues) => Promise<void>;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({ updatePassword }) => {
  const defaultValues: UpdatePasswordForm = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);

  const { isSubmitting, values: { oldPassword, newPassword, confirmPassword }, errors, touched, handleChange, handleSubmit, handleReset, handleBlur } = useFormik({
    initialValues: defaultValues,
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string()
        .required("Requis"),
      newPassword: Yup.string()
        .min(8, "Le nouveau mot de passe doit faire au moins 8 caractères")
        .required("Requis"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), ''], 'Mot de passe différent')
        .required("Requis"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { oldPassword, newPassword } = values;
        await updatePassword({ oldPassword, newPassword });
        toast.success("Mot de passe modifié");
        setModal(false);
        history.push("#");
      }
      catch {
        setSubmitting(false);
      }
    }
  });

  return (
    <>
      <BadgeIcon disabled={isOpen} onClick={toggle} tooltip="Modifier mon mot de passe">
        <FontAwesomeIcon icon={faEdit} size="2x" />
      </BadgeIcon>
      <Modal backdrop={true} isOpen={isOpen} toggle={toggle} onClosed={() => handleReset(null)}>
        <ModalHeader>Modifier le mot de passe</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <input readOnly hidden name="username" autoComplete="username" value={user?.login} />
            <Container>
              <DisplayField title="Ancien mot de passe">
                <Input disabled={isSubmitting} type="password" autoComplete="current-password" name="oldPassword" value={oldPassword} onChange={handleChange} onBlur={handleBlur} />
                {errors.oldPassword && touched.oldPassword && <ErrorArea>{errors.oldPassword}</ErrorArea>}
              </DisplayField>
              <DisplayField title="Nouveau mot de passe">
                <Input disabled={isSubmitting} type="password" autoComplete="new-password" name="newPassword" value={newPassword} onChange={handleChange} onBlur={handleBlur} />
                {errors.newPassword && touched.newPassword && <ErrorArea>{errors.newPassword}</ErrorArea>}
              </DisplayField>
              <DisplayField title="Confirmer mot de passe">
                <Input disabled={isSubmitting} type="password" autoComplete="new-password" name="confirmPassword" value={confirmPassword} onChange={handleChange} onBlur={handleBlur} />
                {errors.confirmPassword && touched.confirmPassword && <ErrorArea>{errors.confirmPassword}</ErrorArea>}
              </DisplayField>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={isSubmitting}>Enregistrer</Button>
            <Button disabled={isSubmitting} color="secondary" onClick={toggle}>Annuler</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

export default UpdatePassword;