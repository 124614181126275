import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BadgeIcon from './BadgeIcon';

import './CollapseButton.scss';

interface CollapseButtonProps {
  collapse: boolean;
  toggle: () => void;
}

const CollapseButton: React.FC<CollapseButtonProps> = ({ collapse, toggle}) => {
  return (
    <BadgeIcon className="collapse-button" onClick={toggle}>
      <FontAwesomeIcon icon={collapse ? faMinus : faPlus} />
    </BadgeIcon>
  )
}

export default CollapseButton;