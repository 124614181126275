import React from 'react';
import BadgeIcon from '../../common/components/BadgeIcon';

import { ReactComponent as CounterAttackIcon } from '../images/counterattack.svg';
import { ReactComponent as ParryIcon } from '../images/parry.svg';
import { ReactComponent as RetreatIcon } from '../images/retreat.svg';

import './ProgrammedActions.scss';

export interface ProgrammedActionsProps {
  nbCounterAttack: number;
  nbParry: number;
  retreats: string[];
}

const Retreats: React.FC<{ retreats: string[] }> = ({ retreats }) => {
  return (
    <>
      <span>{retreats.length} Retraite{retreats.length > 1 ? "s" : ""} :</span>
      <ul className="retreats-details">
        {retreats.map((retreat, index) => (<li key={index}>{retreat}</li>))}
      </ul>
    </>
  )
}

const ProgrammedActions: React.FC<ProgrammedActionsProps & React.HTMLAttributes<HTMLElement>> = ({ nbCounterAttack, nbParry, retreats, className }) => {
  return (
    <>
      <div className={`troll-programmed-actions${className ? ` ${className}` : ""}`}>
        {nbCounterAttack > 0 ? <BadgeIcon className="status-icon" tooltip={`${nbCounterAttack} Contre-Attaque${nbCounterAttack > 1 ? "s" : ""}`}><CounterAttackIcon /></BadgeIcon> : null}
        {nbParry > 0 ? <BadgeIcon className="status-icon" tooltip={`${nbParry} Parade${nbParry > 1 ? "s" : ""}`}><ParryIcon /></BadgeIcon> : null}
        {retreats && retreats.length > 0 ? <BadgeIcon className="status-icon" tooltip={<Retreats retreats={retreats} />}><RetreatIcon /></BadgeIcon> : null}
      </div>
    </>
  )
}

export default ProgrammedActions;