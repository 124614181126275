import React, { useState, useEffect } from 'react';
import Input, { InputProps } from './Input';

interface NumericInputProps extends Omit<InputProps, "onChange" | "value" | "type"> {
  value: number;
  onChange?: (value: number) => void;
  onBlur?: (value: number) => void;
}

const NumericInput: React.FC<NumericInputProps> = ({ onChange, onBlur, value, ...props}) => {
  const [currentValue, setValue] = useState<string>(`${value}`);
  useEffect(() => {
    setValue(`${value}`);
  }, [value])

  const onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void = e => {
    setValue(e.target.value);
    const val = Number(e.target.value);
    if (!!onChange && !Number.isNaN(val)) {
      onChange(val);
    }
  }

  const onInputBlur: () => void = () => {
    const val = Number(currentValue);
    if (!!onBlur && !Number.isNaN(val)) {
      onBlur(val);
    }
  }
  return <Input type="text" {...props} value={currentValue} onChange={onValueChange} onBlur={onInputBlur} />
}

export default NumericInput;