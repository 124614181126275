import React from 'react';
import Switch from '../components/Switch';
import { Themes } from '../components/ThemeContext';

import './SwitchTheme.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

interface SwitchThemeProps {
  theme: Themes;
  setTheme: (theme: Themes) => void;
}

const SwitchTheme: React.FC<SwitchThemeProps> = ({ theme, setTheme }) => {
  const isDark = theme === Themes.dark;

  return (
    <div className="switch-theme">
      <Switch checked={isDark} onChange={e => setTheme(e.target.checked ? Themes.dark : Themes.default)}>
        <FontAwesomeIcon className="theme-icon light-icon" icon={faSun} />
        <FontAwesomeIcon className="theme-icon dark-icon" icon={faMoon} />
      </Switch>
    </div>
  )
}

export default SwitchTheme;