import React from 'react';
import { Breadcrumb as BreadcrumbStrap, BreadcrumbProps as BreadcrumbPropsStrap } from 'reactstrap';
import './Breadcrumb.scss';

interface BreadcrumbProps extends BreadcrumbPropsStrap {

}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ className, ...props}) => {
  return (
    <BreadcrumbStrap className={`breadcrumb-container${!className ? "" : ` ${className}`}`} {...props} />
  )
}

export default Breadcrumb;