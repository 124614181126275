import React from 'react';
import DisplayDate from '../../common/components/DisplayDate';
import TrollSituation from '../../trolls/components/TrollSituation';
import TrollName from './TrollName';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';

import './TrollCardDeck.scss';
import RefreshButton from '../../common/components/RefreshButton';
import Card from '../../common/components/Cards/Card';
import CardHeader from '../../common/components/Cards/CardHeader';
import CardBody from '../../common/components/Cards/CardBody';
import CardFooter from '../../common/components/Cards/CardFooter';
import BadgeIcon from '../../common/components/BadgeIcon';
import BadgeLinkIcon from '../../common/components/BadgeLinkIcon';
import BadgeTroll from '../../trolls/components/BadgeTroll';
import { TrollStatusProps } from '../../trolls/components/TrollStatus';
import CardColumns from '../../common/components/Cards/CardColumns';

interface Vitality {
  current: number;
  max: number;
}

interface Localization {
  x: number;
  y: number;
  n: number;
}

interface Tiredness {
  value: number;
  physicalBonus: number;
}

interface ActionLimitDate {
  value: Date;
  nextEstimated: Date;
}

interface Troll extends TrollStatusProps {
  id: number;
  name: string;
  breed: string;
  level: number;
  breedAndLevel: string;
  concentration: number;
  tiredness: Tiredness;
  actionLimitDate: ActionLimitDate;
  lastUpdate: Date;
  vitality: Vitality;
  localization: Localization;
  isActive: boolean;
}

interface TrollCardDeckProps {
  policyId: string;
  isOwner: boolean;
  trolls: Troll[];
  excludeTroll: (troll: Troll) => Promise<void>;
  refreshTroll: (troll: Troll) => Promise<void>;
}

const TrollCardDeck: React.FC<TrollCardDeckProps> = ({ policyId, isOwner, trolls, excludeTroll, refreshTroll }) => {
  return (
    <CardColumns className="troll-cards">
      <TrollCards policyId={policyId} isOwner={isOwner} trolls={trolls} excludeTroll={excludeTroll} refreshTroll={refreshTroll} />
    </CardColumns>
  )
}

interface TrollCardProps {
  policyId: string;
  isOwner: boolean;
  excludeTroll: (troll: Troll) => Promise<void>;
  refreshTroll: (troll: Troll) => Promise<void>;
}

const TrollCards: React.FC<{ trolls: Troll[] } & TrollCardProps> = ({ trolls, policyId, isOwner, excludeTroll, refreshTroll }) => (
  <>
    {trolls.map(troll => (
      <TrollCard troll={troll} policyId={policyId} isOwner={isOwner} excludeTroll={excludeTroll} refreshTroll={refreshTroll} key={troll.id}>
        {troll.isActive ? <TrollSituation {...troll} /> : <b>En attente</b>}
      </TrollCard>
    ))}
  </>
)

const TrollCard: React.FC<{ troll: Troll } & TrollCardProps> = ({ troll, children, policyId, isOwner, excludeTroll, refreshTroll }) => {
  const { id, name, breed, level, breedAndLevel, isActive, lastUpdate } = troll;

  return (
    <Card outline>
      <CardHeader className="d-flex justify-content-between">
        <span><TrollName id={id} name={name} isActive={isActive} policyId={policyId} /></span>
        <div className="card-icons text-nowrap">
          <div className="card-icon-tools d-flex justify-content-between align-items-center">
            {!breed ? null : <BadgeTroll className="d-flex badge-troll-level" tooltip={`${breed} ${level}`} breed={breed} content={breedAndLevel} />}
            <small className="text-nowrap">
              <BadgeLinkIcon
                className="ml-1"
                disabled={!isActive}
                to={`/policies/${policyId}/trolls/${id}/map`}
                tooltip="Afficher la vue"
              >
                <FontAwesomeIcon icon={faEye} size="2x" />
              </BadgeLinkIcon>
              <RefreshButton className="ml-1" disabled={!isActive} refresh={() => refreshTroll(troll)} size="2x" />
              {
                isOwner ? (
                  <BadgeIcon className="ml-1" tooltip="Exclure le troll" onClick={() => excludeTroll(troll)}>
                    <FontAwesomeIcon icon={faTrashAlt} size="2x" />
                  </BadgeIcon>
                ) : null
              }
            </small>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {children}
      </CardBody>
      <CardFooter>
        <small>Dernière mise à jour : <DisplayDate date={lastUpdate} /></small>
      </CardFooter>
    </Card>
  )
}

export default TrollCardDeck;