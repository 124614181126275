import React from 'react';
import DisplayField from '../../common/components/DisplayField';

export interface AttributeProps {
  value: number;
  physicalBonus: number;
  magicBonus: number;
}

const Attribute: React.FC<AttributeProps & { name: string }> = ({ name, value, physicalBonus, magicBonus }) => {
  const pb = physicalBonus < 0 ? `${physicalBonus}` : `+${physicalBonus}`;
  const mb = magicBonus < 0 ? `${magicBonus}` : `+${magicBonus}`;
  return (
    <DisplayField title={name}>
      <span>{value + physicalBonus + magicBonus} </span>
      <small>
        <small>({`${value} ${pb} ${mb}`})</small>
      </small>
    </DisplayField>
  )
}

export default Attribute;