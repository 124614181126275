import React from 'react';
import { useService } from '../../services';
import DiplomacyServices from '../services/DiplomacyServices';
import DiplomacyGrid from '../components/DiplomacyGrid';
import BadgeLinkIcon from '../../common/components/BadgeLinkIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Diplomacies: React.FC = () => {
  const diplomacyServices = useService(DiplomacyServices);

  const loadDiplomacies = () => diplomacyServices.getDiplomacies();
  const deleteDiplomacy = (id: string) => diplomacyServices.deleteDiplomacy(id);
  const setDefaultDiplomacy = (id: string, isDefault: boolean) => diplomacyServices.setDefaultDiplomacy(id, isDefault);

  return (
    <div>
      <h1 className="d-flex justify-content-between">
        <span>Diplomaties</span>
        <BadgeLinkIcon to="/diplomacies/add" tooltip="Ajouter une diplomatie">
          <FontAwesomeIcon icon={faPlus} />
        </BadgeLinkIcon>
      </h1>
      <DiplomacyGrid
        loadDiplomacies={loadDiplomacies}
        deleteDiplomacy={deleteDiplomacy}
        setDefaultDiplomacy={setDefaultDiplomacy}
      />
    </div>
    )
}

export default Diplomacies;