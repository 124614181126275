import React from 'react';

import './BadgeNotification.scss';

export type BadgePosition =
  | "topright"
  | "topleft";

interface BadgeNotificationProps {
  position: BadgePosition;
  counter: number;
}

const BadgeNotification: React.FC<BadgeNotificationProps> = ({ position, counter, children }) => {
  return (
    <div className="badge-notification">
      {children}
      {counter > 0 ? <div className={`badge badge-${position} `}>{counter > 9 ? "9+" : `${counter}`}</div> : null}
    </div>
  )
}

export default BadgeNotification;