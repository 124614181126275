import React, { useEffect, useState } from 'react';
import { DefaultMap, MapContextProviderProps } from '../../map/contexts/MapContext';
import { SearchContextProviderProps } from '../../map/contexts/SearchContext';
import DisplayMap from '../../map/components/DisplayMap';
import { SearchCriteria, SearchResult } from '../../map/models/Search';
import Loading from '../../common/components/Loading';
import { Status } from '../../services';
import Breadcrumb from '../../common/components/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../common/components/Breadcrumb/BreadcrumbItem';
import { Link } from 'react-router-dom';

interface DisplayTrollMapProps extends Omit<MapContextProviderProps, "map">, Omit<SearchContextProviderProps, "search"> {
  policyId: string;
  loadMap: (policyId: string) => Promise<DefaultMap & { policy: Policy }>;
  search: (id: string, criteria: SearchCriteria) => Promise<SearchResult>;
}

interface Policy {
  id: string;
  name: string;
}

interface TrollMapState {
  isLoaded: boolean;
  map?: DefaultMap;
  policy?: Policy;
}

const TrollMap: React.FC<DisplayTrollMapProps> = ({ policyId, loadMap, search, ...props }) => {
  const [state, setState] = useState<TrollMapState>({ isLoaded: false });
  const { isLoaded, policy, map } = state;

  useEffect(() => {
    loadMap(policyId)
      .then(result => {
        const { policy: policyResult, ...mapResult } = result;
        setState({ isLoaded: true, policy: policyResult, map: mapResult });
      })
      .catch(error => {
        if (error.status === Status.NotFound) {
          setState({
            isLoaded: true
          })
        }
        else {
          throw error;
        }
      });
  }, []);

  if (!isLoaded) {
    return <Loading />
  }

  if (!map) {
    return (
      <div></div>
    )
  }

  const aggregateSearch = async (criteria: SearchCriteria) => {
    return await search(map!.token, criteria);
  }

  return (
    <>
      <Breadcrumb>
        {policy ? <BreadcrumbItem><Link to={`/policies/${policy.id}`}>{policy.name}</Link></BreadcrumbItem> : null}
        <BreadcrumbItem active>Vue agrégée</BreadcrumbItem>
      </Breadcrumb>
      <DisplayMap title={policy?.name || "Vue agrégée"} map={map} search={aggregateSearch} {...props} />
    </>
  )
}

export default TrollMap;