import React, { useEffect, useState } from 'react';
import Card from '../../common/components/Cards/Card';
import CardBody from '../../common/components/Cards/CardBody';
import CardHeader from '../../common/components/Cards/CardHeader';
import Loading from '../../common/components/Loading';
import Account from './Account';
import ScriptSettings, { AccountSettings, UpdateAccountSettingsRequest } from './ScriptSettings';
import TrollAccount, { TrollAccountValues } from './TrollAccount';
import { UpdatePasswordValues } from './UpdatePassword';
import CardColumns from '../../common/components/Cards/CardColumns';

interface MyAccount {
  login: string;
  troll: TrollAccountValues;
}

interface AccountDeckProps {
  loadAccount: () => Promise<MyAccount>;
  loadSettings: () => Promise<AccountSettings>;
  saveSettings: (settings: UpdateAccountSettingsRequest) => Promise<void>;
  saveAccount: (account: { scriptToken: string }) => Promise<void>;
  updatePassword: (request: UpdatePasswordValues) => Promise<void>;
}

const AccountDeck: React.FC<AccountDeckProps> = ({ loadAccount, loadSettings, saveSettings, saveAccount, updatePassword }) => {
  const [settings, setSettings] = useState<AccountSettings>();
  const [account, setAccount] = useState<MyAccount>();

  useEffect(() => {
    loadAccount().then(setAccount);
    loadSettings().then(setSettings);
  }, []);

  return (
    <CardColumns className="max-columns-count-2">
      <Card>
        <CardHeader>Troll</CardHeader>
        <CardBody>
          {account && account.troll
            && <TrollAccount troll={account.troll} updateScriptToken={scriptToken => saveAccount({ scriptToken })} />}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Profil</CardHeader>
        <CardBody>
          {account ? <Account {...account} updatePassword={updatePassword} /> : <Loading />}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Appels aux scripts publics</CardHeader>
        <CardBody>
          {!settings ? <Loading /> : <ScriptSettings settings={settings} saveSettings={saveSettings} />}
        </CardBody>
      </Card>
    </CardColumns>
  )
}

export default AccountDeck;