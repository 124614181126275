import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faGhost, faRunning } from '@fortawesome/free-solid-svg-icons';
import BadgeIcon from '../../common/components/BadgeIcon';

import { ReactComponent as StickyIcon } from '../images/sticked.svg';
import { ReactComponent as DownIcon } from '../images/down.svg';
import { ReactComponent as LevitationIcon } from '../images/levitation.svg';

import './State.scss';


export interface StateProps {
  isHidden: boolean;
  isInvisible: boolean;
  isIntangible: boolean;
  isSticky: boolean;
  isDown: boolean;
  isRunning: boolean;
  isLevitating: boolean;
}

const State: React.FC<StateProps & React.HTMLAttributes<HTMLElement>> = ({
  isHidden,
  isInvisible,
  isIntangible,
  isSticky,
  isDown,
  isRunning,
  isLevitating,
  className
}) => {
  const notVisible = isHidden || isInvisible;
  const notVisibleTooltip = notVisible ? (isHidden && isInvisible ? "Camouflé & Invisible" : isHidden ? "Camouflé" : "Invisible") : "";

  return (
    <div className={`troll-state${className ? ` ${className}` : ""}`}>
      {notVisible ? <BadgeIcon className="status-icon" tooltip={notVisibleTooltip}><FontAwesomeIcon icon={faEyeSlash} /></BadgeIcon> : null}
      {isIntangible ? <BadgeIcon className="status-icon" tooltip="Intangible"><FontAwesomeIcon icon={faGhost} /></BadgeIcon> : null}
      {isSticky ? <BadgeIcon className="status-icon" tooltip="Glué"><StickyIcon /></BadgeIcon> : null}
      {isRunning ? <BadgeIcon className="status-icon" tooltip="En course"><FontAwesomeIcon icon={faRunning} /></BadgeIcon> : null}
      {isDown ? <BadgeIcon className="status-icon" tooltip="A terre"><DownIcon /></BadgeIcon> : null}
      {isLevitating ? <BadgeIcon className="status-icon" tooltip="Lévite"><LevitationIcon /></BadgeIcon> : null}
    </div>
  )
}

export default State;