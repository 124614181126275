import { Service } from '../../services';

interface Counter {
  name: string;
  value: number;
  limit: number;
}

interface Category {
  name: string;
  current: number;
  limit: number;
  counters: Counter[];
}

interface Script {
  trollId: number;
  categories: Category[];
}

interface ScriptServicesProps {
  getScriptCounters: (trollId: number) => Promise<Script>;
}

const ScriptServices: Service<ScriptServicesProps> = (serviceContext) => ({
  getScriptCounters: (trollId) => serviceContext.fetchApi<Script>(`api/trolls/${trollId}/scripts`),
})

export default ScriptServices;