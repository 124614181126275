import React, { useContext, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.scss';
import NavMenuProfile from './NavMenuProfile';
import NavMenuPolicies from './NavMenuPolicies';
import { ThemeContext, Themes } from '../components/ThemeContext';
import SwitchTheme from './SwitchTheme';
import NavMenuDiplomacies from './NavMenuDiplomacies';
import NavMenuConnexion from './NavMenuConnexion';
import NavMenuAvatar from './NavMenuAvatar';

const NavMenu: React.FC = () => {
  const [isMenuOpen, expandMenu] = useState(false);
  const [isMenuOpened, setIsOpened] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);
  const isDark = theme === Themes.dark;
  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" dark={isDark} light={!isDark}>
        <Container>
          <NavbarToggler onClick={() => expandMenu(!isMenuOpen)} className="mr-2" />
          <NavbarBrand className="mr-2" tag={Link} to="/">Troll d'IT</NavbarBrand>
          <Collapse onEntering={() => setIsOpened(true)} onExited={() => setIsOpened(false)} className={`flex-sm-row-reverse${isMenuOpened && " order-1" || ""}`} isOpen={isMenuOpen} navbar>
            <ul className="navbar-nav" onClick={() => expandMenu(false)}>
              <NavMenuPolicies />
              <NavMenuDiplomacies />
              <NavMenuProfile />
              <NavMenuConnexion />
            </ul>
          </Collapse>
          <NavbarText className="mr-2">
            <SwitchTheme {...{ theme, setTheme }} />
          </NavbarText>
          <NavMenuAvatar />
        </Container>
      </Navbar>
    </header>
  );
}

export default NavMenu;