import React from 'react';
import { ModalHeader as ModalHeaderStrap, ModalHeaderProps as ModalHeaderStrapProps } from 'reactstrap';
import './ModalHeader.scss';

interface ModalHeaderProps extends ModalHeaderStrapProps {
}

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  return (
    <ModalHeaderStrap {...props} />
  )
}

export default ModalHeader;