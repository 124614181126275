import React from 'react';
import { useService } from '../../services';
import AccountDeck from '../components/AccountDeck';
import AccountServices from '../services/AccountServices';

const MyAccount: React.FC = () => {
  const accountServices = useService(AccountServices);
  
  return (
    <>
      <AccountDeck
        loadAccount={accountServices.getMyAccount}
        loadSettings={accountServices.getAccountSettings}
        saveSettings={accountServices.updateAccountSettings}
        saveAccount={accountServices.updateAccount}
        updatePassword={accountServices.updatePassword}
      />
    </>
  )
}

export default MyAccount;