import { Service } from '../../services';

interface Monster {
  id: number;
  name: string;
}

interface Family {
  id: number;
  name: string;
}

interface Bestiary {
  monsters: Monster[];
  families: Family[];
}

interface BestiaryServicesProps {
  getBestiary: () => Promise<Bestiary>;
}

const BestiaryServices: Service<BestiaryServicesProps> = (serviceContext) => ({
  getBestiary: () => serviceContext.fetchApi<Bestiary>(`api/bestiary`),
})

export default BestiaryServices;