import React from 'react';

import './DisplayEquipment.scss';

export interface Equipment {
  id: number;
  name: string;
  spell: string;
  description: string;
  packed: boolean;
  identified: boolean;
  weight: number;
}

interface DisplayEquipmentProps {
  equipment: Equipment;
  collapsed: boolean;
}

const DisplayEquipment: React.FC<DisplayEquipmentProps> = ({ equipment: { id, name, spell, description, weight, packed, identified }, collapsed}) => (
  <div className="display-equipment">
    <span className="equipment-title">
      [{id}] {identified ? name.trim() : "Non identifié"} <span className="equipment-spell">{spell}</span>{packed ? <span className="equipment-packed"> [B]</span> : null}
    </span>
    <div className={`equipment-description${collapsed ? " equipment-description-collapsed" : ""}`}>
      {description}{!!weight || weight === 0 ? <span> (poids: {weight}min)</span> : null}
    </div>
  </div>
)

export default DisplayEquipment;