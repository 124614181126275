import React from 'react';
import { useFormik } from 'formik';
import Button from '../../common/components/Button';
import ErrorArea from '../../common/components/ErrorArea';
import * as Yup from 'yup';
import Input from '../../common/components/Input';

interface PolicyFormProps {
  onSubmit: (policy: { name: string }) => Promise<{ id: string }>;
  onSuccess: (id: string) => void
}

const PolicyForm: React.FC<PolicyFormProps> = ({ onSubmit, onSuccess }) => {

  const { isSubmitting, values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, "3 caractères min")
        .max(50, "50 caractères max")
        .required("Requis")
    }),
    onSubmit: async (policy, { setSubmitting }) => {
      try {
        const { id } = await onSubmit(policy);
        onSuccess(id);
      }
      catch {
        setSubmitting(false);
      }
    }
  })
  return (
    <form onSubmit={handleSubmit}>
      <Input type="text" disabled={isSubmitting} name="name" placeholder="Nom" value={values.name} onChange={handleChange} />
      <ErrorArea>{errors.name}</ErrorArea>
      <Button type="submit" disabled={isSubmitting}>
        Enregistrer
      </Button>
    </form>
  )
}

export default PolicyForm;