import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from './Button';

const PreviousButton: React.FC = () => {
  const history = useHistory();

  const goBack: () => void = () => {
    history.goBack();
  }

  return <Button color="secondary" onClick={goBack}>Retour</Button>
}

export default PreviousButton;