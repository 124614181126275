import React from 'react';
import { BreadcrumbItem as BreadcrumbItemStrap, BreadcrumbItemProps as BreadcrumbItemPropsStrap } from 'reactstrap';
import './BreadcrumbItem.scss';

interface BreadcrumbItemProps extends BreadcrumbItemPropsStrap {

}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = props => {
  return (
    <BreadcrumbItemStrap {...props} />
  )
}

export default BreadcrumbItem;