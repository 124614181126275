import { Service } from '../../services';

export interface AuthenticateRequest {
  login: string;
  password: string;
}

export interface User {
  id: string;
  login: string;
  trollId?: number;
  avatar?: string;
}

interface AuthenticationServicesProps {
  signin: (request: AuthenticateRequest) => Promise<User>;
  signout: () => Promise<void>;
  validate: () => Promise<User>;
}

const authenticationServices: Service<AuthenticationServicesProps> = (serviceContext) => ({
  signin: request => serviceContext.fetchApi<User>(`api/authentication/signin`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify(request)
  }),
  signout: () => serviceContext.fetchApi<void>(`api/authentication/signout`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST"
  }),
  validate: () => serviceContext.fetchApi(`api/authentication/validate`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST"
  }),
})

export default authenticationServices;