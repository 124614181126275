import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Loading from '../../common/components/Loading';
import DropdownList from '../../common/components/DropdownList';
import Input from '../../common/components/Input';
import DisplayField from '../../common/components/DisplayField';
import { SearchContext } from '../contexts/SearchContext';
import { BaseMonster, Bestiary, MonsterFamily } from '../models/Bestiary';

interface MonsterSearchCriteriaState {
  isLoaded: boolean;
  bestiary?: Bestiary;
}

interface MonsterSearchCriteriaValues {
  id: number;
  level: { min?: number, max?: number };
  baseMonster: BaseMonster;
  family: MonsterFamily;
}

const MonsterSearchCriteria: React.FC = () => {
  const { criteria, getBestiary, setMonsterCriteria } = useContext(SearchContext);
  const [{ isLoaded, bestiary }, setState] = useState<MonsterSearchCriteriaState>({ isLoaded: false });

  const { monster: monsterCriteria } = criteria;
  const { id, level, baseMonster, family } = monsterCriteria || { };

  useEffect(() => {
    getBestiary()
      .then(bestiary => setState({ isLoaded: true, bestiary }));
  }, [getBestiary]);

  const handleChange: (values: Partial<MonsterSearchCriteriaValues>) => void = values => {
    setMonsterCriteria({
      ...monsterCriteria,
      ...values
    });
  }

  if (!isLoaded || !bestiary) {
    return <Loading />
  }

  return (
    <Container>
      <DisplayField title="Numéro">
        <Input type="text" name="id" defaultValue={id} onChange={e => handleChange({ id: Number(e.target.value) || undefined })} placeholder="Numéro du monstre" />
      </DisplayField>
      <DisplayField title="Niveau">
        <Container>
          <Row>
            <Col>
              <Input type="text" name="min" defaultValue={level && level.min} onChange={e => handleChange({ level: { ...level, min: Number(e.target.value) || undefined} })} placeholder="Niveau min" />
            </Col>
            <Col>
              <Input type="text" name="max" defaultValue={level && level.max} onChange={e => handleChange({ level: { ...level, max: Number(e.target.value) || undefined} })} placeholder="Niveau max" />
            </Col>
          </Row>
        </Container>
      </DisplayField>
      <DisplayField title="Monstre">
        <DropdownList<BaseMonster> source={bestiary.monsters} value={baseMonster} title="name" description="Chercher" onChange={monster => handleChange({ baseMonster: monster })} />
      </DisplayField>
      <DisplayField title="Famille">
        <DropdownList<MonsterFamily> source={bestiary.families} value={family} title="name" description="Chercher" onChange={family => handleChange({ family })} />
      </DisplayField>
    </Container>
  )
}

export default MonsterSearchCriteria;