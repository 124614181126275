import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './Loading.scss';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface LoadingProps {
  size?: SizeProp;
}

const Loading: React.FC<LoadingProps> = ({ size }) => {
  return (
    <div className="spinner">
      <FontAwesomeIcon icon={faCircleNotch} size={ size || "4x"} spin />
    </div>
  )
}

export default Loading;