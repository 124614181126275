import React, { useState } from 'react';
import Card from '../../common/components/Cards/Card';
import CardBody from '../../common/components/Cards/CardBody';
import CardFooter from '../../common/components/Cards/CardFooter';
import CardHeader from '../../common/components/Cards/CardHeader';
import DisplayDate from '../../common/components/DisplayDate';
import DisplayEquipment, { Equipment } from './DisplayEquipment';

import './CardEquipments.scss';
import RefreshButton from '../../common/components/RefreshButton';
import BadgeIcon from '../../common/components/BadgeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface CardEquipmentsprops {
  type: string;
  equipments: Equipment[];
  lastUpdate?: Date;
  className?: string;
  refresh?: () => Promise<void>;
}

const CardEquipments: React.FC<CardEquipmentsprops> = ({ type, equipments, lastUpdate, className, refresh, children }) => {
  const [collapsed, collapse] = useState(true);
  return (
    <Card className={className}>
      <CardHeader className="d-flex justify-content-between">
        <h5><b className="text-nowrap">{type}</b></h5>
        <div className="card-header-icons text-nowrap">
          <BadgeIcon tooltip={collapsed ? "Afficher le détail des équipements" : "Masquer le détail des équipements"} onClick={() => collapse(!collapsed)}>
            <FontAwesomeIcon icon={faInfoCircle} className={collapsed ? "equipment-collapsed" : "equipment-expanded"} />
          </BadgeIcon>
          {!refresh ? null : <RefreshButton className="ml-1" refresh={() => refresh()} />}
        </div>
      </CardHeader>
      <CardBody>
        <ul className="display-equipment-list">
          {equipments.map(equipment => (<li key={equipment.id}><DisplayEquipment equipment={equipment} collapsed={collapsed} /></li>))}
        </ul>
        {children}
      </CardBody>
      {!lastUpdate ? null : <CardFooter>
        <small>Dernière mise à jour : <DisplayDate date={lastUpdate} /></small>
      </CardFooter>}
    </Card>
  )
}

export default CardEquipments;