import { faUser, faUsers, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import BadgeIcon from '../../common/components/BadgeIcon';
import BadgeLinkIcon from '../../common/components/BadgeLinkIcon';
import Breadcrumb from '../../common/components/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../common/components/Breadcrumb/BreadcrumbItem';
import Card from '../../common/components/Cards/Card';
import CardBody from '../../common/components/Cards/CardBody';
import CardColumns from '../../common/components/Cards/CardColumns';
import CardHeader from '../../common/components/Cards/CardHeader';
import Loading from '../../common/components/Loading';

interface Policy {
	id: string;
	name: string;
	isOwner: boolean;
	trolls: Troll[];
}

interface Troll {
	id: number;
	name: string;
	isOwner: boolean;
}

interface PolicyRightsProps {
	loadPolicy: () => Promise<Policy>;
	grantOwner: (trollId: number) => Promise<void>;
	grantMember: (trollId: number) => Promise<void>;
}

interface PolicyRightsState {
	policy?: Policy;
	isLoaded: boolean;
}

const PolicyRights: React.FC<PolicyRightsProps> = ({ loadPolicy, grantOwner, grantMember }) => {
	const [{ policy, isLoaded }, setState] = useState<PolicyRightsState>({ isLoaded: false })

	useEffect(() => {
		loadPolicy().then(p => setState({
			policy: p,
			isLoaded: true
		}))
	})

	if (!isLoaded) {
		return <Loading />
	}

	if (!policy) {
		return <div>Groupe inconnu</div>
	}

	const { id, name, isOwner, trolls } = policy;

	return (
		<>
			<Breadcrumb>
				{id ? <BreadcrumbItem><Link to={`/policies/${id}`}>{name}</Link></BreadcrumbItem> : null}
				<BreadcrumbItem active>Paramètres</BreadcrumbItem>
			</Breadcrumb>
			<h1 className="d-flex justify-content-between">
				<span>{name}</span>
				<div className="text-nowrap">
					<BadgeLinkIcon
						tooltip="Voir le groupe"
						to={`/policies/${id}`}
					>
						<FontAwesomeIcon icon={faUsers} />
					</BadgeLinkIcon>
				</div>
			</h1>
			<Card>
				<CardHeader>Gestion des droits</CardHeader>
				<CardBody>
					<CardColumns className="max-columns-count-2">
						<Card>
							<CardHeader>Membres</CardHeader>
							<CardBody>
								<ul className="list-unstyled">
									{trolls.filter(troll => !troll.isOwner).map(({ id, name }, index) => (
										<li key={index}>
											<span>{name} ({id})</span>
											{isOwner && <BadgeIcon
												className="ml-2"
												onClick={() => grantOwner(id)}
												tooltip="Promouvoir administrateur"
											>
												<FontAwesomeIcon icon={faUserTie} />
											</BadgeIcon>}
										</li>
									))}
								</ul>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>Administrateurs</CardHeader>
							<CardBody>
								<ul className="list-unstyled">
									{trolls.filter(troll => troll.isOwner).map(({ id, name }, index) => (
										<li key={index}>
											<span>{name} ({id})</span>
											{isOwner && <BadgeIcon
												className="ml-2"
												onClick={() => grantMember(id)}
												tooltip="Rétrograder membre"
											>
												<FontAwesomeIcon icon={faUser} />
											</BadgeIcon>}
										</li>
									))}
								</ul>
							</CardBody>
						</Card>
					</CardColumns>
				</CardBody>
			</Card>
		</>
	)
}

export default PolicyRights;