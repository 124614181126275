import React from 'react';
import Table, { Metadata } from '../../common/components/Table';

export interface Effect {
  name: string;
  type: string;
  value: string;
  duration: number;
}

interface EffectGridProps {
  effects: Effect[];
}

const EffectGrid: React.FC<EffectGridProps> = ({ effects }) => {
  const metadatas: Metadata<Effect>[] = [{
    key: "name",
    title: "Nom"
  }, {
    key: "type",
    title: "Type"
  }, {
    key: "value",
    title: "Effet"
  }, {
    key: "duration",
    title: "Durée"
  }];

  return <Table rows={effects} metadatas={metadatas} />
}

export default EffectGrid;