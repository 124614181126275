import React, {  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import Table, { Metadata } from '../../common/components/Table';
import BadgeIcon from '../../common/components/BadgeIcon';

export interface Invitation {
  id: string;
  name: string;
  nbTrolls: number;
  nbInvitations: number;
}

interface InvitationsProps {
  invitations: Invitation[];
  refresh: () => Promise<void>;
  acceptInvitation: (invitationId: string) => Promise<void>;
  refuseInvitation: (invitationId: string) => Promise<void>;
}

const Invitations: React.FC<InvitationsProps> = ({ invitations, refresh , acceptInvitation, refuseInvitation}) => {

  const accept: (row: Invitation) => Promise<void> = async row => {
    if (window.confirm(`Accepter l'invitation du groupe ${row.name} ?`)) {
      await acceptInvitation(row.id);
      await refresh();
    }
  }

  const refuse: (row: Invitation) => Promise<void> = async row => {
    if (window.confirm(`Refuser l'invitation du groupe ${row.name} ?`)) {
      await refuseInvitation(row.id);
      await refresh();
    }
  }

  const metadatas: Metadata<Invitation>[] = [{
    key: "name",
    title: "Nom"
  }, {
    key: "nbTrolls",
    title: "Trolls"
  }, {
    key: "nbInvitations",
    title: "Invitations"
  }, {
    title: "",
    render: (row) => (<BadgeIcon tooltip="Accepter l'invitation" onClick={() => accept(row)}><FontAwesomeIcon icon={faSignInAlt} size="2x" /></BadgeIcon>)
  }, {
    title: "",
    render: row => (<BadgeIcon tooltip="Refuser l'invitation" onClick={() => refuse(row)}><FontAwesomeIcon icon={faTrashAlt} size="2x" /></BadgeIcon>)
  }];

  return (
    <Table rows={invitations} metadatas={metadatas} />
  )
}



export default Invitations;