import React, { useEffect, useState } from 'react';
import TrollGrid from './TrollGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEnvelopeOpenText, faEye, faList, faSearch, faTh } from '@fortawesome/free-solid-svg-icons';
import TrollCardDeck from './TrollCardDeck';
import Loading from '../../common/components/Loading';
import { toast } from 'react-toastify';
import BadgeIcon from '../../common/components/BadgeIcon';
import BadgeLinkIcon from '../../common/components/BadgeLinkIcon';

import PolicyFilters, { Filters } from './PolicyFilters';
import Modal from '../../common/components/Modals/Modal';
import ModalHeader from '../../common/components/Modals/ModalHeader';
import { ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../common/components/Button';
import { useFormik } from 'formik';
import { TrollStatusProps } from '../../trolls/components/TrollStatus';

interface Vitality {
  current: number;
  max: number;
}

interface Localization {
  x: number;
  y: number;
  n: number;
}

interface Tiredness {
  value: number;
  physicalBonus: number;
}

interface ActionLimitDate {
  value: Date;
  nextEstimated: Date;
}

interface Troll extends TrollStatusProps {
  id: number;
  name: string;
  breed: string;
  level: number;
  breedAndLevel: string;
  concentration: number;
  tiredness: Tiredness;
  actionLimitDate: ActionLimitDate;
  lastUpdate: Date;
  vitality: Vitality;
  isActive: boolean;
  localization: Localization;
}

export interface ViewPolicyValues {
  id: string;
  name: string;
  trolls: Troll[];
  isOwner: boolean;
}
interface Breed {
  id: string;
  name: string;
}
interface Talent {
  id: number;
  name: string;
}
interface Talents {
  skills: Talent[];
  spells: Talent[];
}

interface PolicyProps {
  id: string;
  getPolicy: (id: string, filters: Partial<Filters>) => Promise<ViewPolicyValues>;
  refreshTroll: (trollId: number) => Promise<void>;
  excludeTroll: (policyId: string, trollId: number) => Promise<void>;
  getBreeds: () => Promise<Breed[]>;
  getTalents: () => Promise<Talents>;
}

enum DisplayMode {
  cards,
  list
}

interface PolicyState {
  name: string;
  mode: DisplayMode;
  isOwner: boolean;
  isLoaded: boolean;
  trolls: Troll[]
}

const ViewPolicy: React.FC<PolicyProps> = ({ id, getPolicy, refreshTroll, excludeTroll, getBreeds, getTalents }) => {
  const getDefaultMode = () => {
    const mode: string | null = localStorage.getItem("policyMode");

    return (!!mode && (DisplayMode as any)[mode]) || DisplayMode.cards;
  }

  const [state, setState] = useState<PolicyState>({
    name: "",
    mode: getDefaultMode(),
    isOwner: false,
    isLoaded: false,
    trolls: []
  });
  const { name, mode, isOwner, isLoaded, trolls } = state;
  const [isSearchModalOpen, displaySearchModal] = useState(false);
  const [breeds, setBreeds] = useState<Breed[]>([]);
  const [talents, setTalents] = useState<Talents>({ skills: [], spells: [] });

  const loadPolicy = async (filters?: Filters) => {
    const policy = await getPolicy(id, filters || {});
    setState({
      ...state,
      name: policy.name,
      isOwner: policy.isOwner,
      isLoaded: true,
      trolls: policy.trolls
    });
    return policy;
  }

  useEffect(() => {
    loadPolicy();
    getBreeds()
      .then(setBreeds);
    getTalents()
      .then(setTalents);
  }, [])

  const { values: filters, handleChange, handleSubmit, handleReset, setFieldValue } = useFormik<Filters>({
    initialValues: {},
    onSubmit: values => {
      loadPolicy(values);
      displaySearchModal(false);
    },
    onReset: () => loadPolicy()
  });

  if (!isLoaded) {
    return <Loading />
  }

  const changeDisplayMode = (mode: DisplayMode) => {
    localStorage.setItem("policyMode", DisplayMode[mode]);
    setState({ ...state, mode });
  }

  const handleExcludeTroll = async (troll: { id: number, name: string }) => {
    const { id: trollId, name } = troll;
    if (window.confirm(`Supprimer ${name} (${trollId}) ?`)) {
      await excludeTroll(id, trollId);
      toast.success(`${name} (${trollId}) supprimé`);
      await loadPolicy();
    }
  }

  const handleRefreshTroll = async (troll: { id: number, name: string }) => {
    const { id: trollId, name } = troll;
    await refreshTroll(trollId);
    toast.success(`${name} (${trollId}) mis à jour`);
    await loadPolicy();
  }

  const handleFiltersChange = (e: React.ChangeEvent<any>) => {
    if (!!e.target.currentDate) {
      setFieldValue(e.target.name, e.target.currentDate);
    }
    else if (!!e.target.ddlValue && !!e.target.ddlValue) {
      setFieldValue(e.target.name, e.target.ddlValue);
    }
    else {
      handleChange(e);
    }
  }

  return (
    <div>
      <h1 className="d-flex justify-content-between">
        <span>{name}</span>
        <div className="text-nowrap">
          <BadgeIcon
            tooltip="Rechercher un troll"
            onClick={() => displaySearchModal(true)}
          >
            <FontAwesomeIcon icon={faSearch} color={Object.keys(filters).length ? "red" : undefined} />
          </BadgeIcon>
          <BadgeLinkIcon
            className="ml-1"
            tooltip="Vue aggrégée du groupe"
            to={`/policies/${id}/map`}
          >
            <FontAwesomeIcon icon={faEye} />
          </BadgeLinkIcon>
          <BadgeLinkIcon
            className="ml-1"
            tooltip="Invitations"
            to={`/policies/${id}/invitations`}
          >
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
          </BadgeLinkIcon>
          <BadgeIcon
            className="ml-1"
            disabled={mode === DisplayMode.list}
            tooltip="Mode liste"
            onClick={() => changeDisplayMode(DisplayMode.list)}
          >
            <FontAwesomeIcon icon={faList} />
          </BadgeIcon>
          <BadgeIcon
            className="ml-1"
            disabled={mode === DisplayMode.cards}
            tooltip="Mode fiche"
            onClick={() => changeDisplayMode(DisplayMode.cards)}
          >
            <FontAwesomeIcon icon={faTh} />
          </BadgeIcon>
          <BadgeLinkIcon
            className="ml-1"
            tooltip="Paramètres"
            to={`/policies/${id}/settings`}
          >
            <FontAwesomeIcon icon={faCog} />
          </BadgeLinkIcon>
        </div>
      </h1>
      <Modal isOpen={isSearchModalOpen} toggle={() => displaySearchModal(!isSearchModalOpen)} backdrop={true}>
        <ModalHeader>Rechercher un troll</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody><PolicyFilters filters={filters} onChange={handleFiltersChange} breeds={breeds} talents={talents} /></ModalBody>
          <ModalFooter>
            <Button type="submit">Rechercher</Button>
            <Button color="secondary" onClick={handleReset}>Effacer</Button>
          </ModalFooter>
        </form>
      </Modal>
      <DisplayPolicy mode={mode} policyId={id} trolls={trolls} isOwner={isOwner} excludeTroll={troll => handleExcludeTroll(troll)} refreshTroll={troll => handleRefreshTroll(troll)} />

    </div>
  )
}

interface DisplayPolicyProps {
  mode: DisplayMode;
  policyId: string;
  isOwner: boolean;
  trolls: Troll[];
  excludeTroll: (troll: { id: number, name: string }) => Promise<void>;
  refreshTroll: (troll: { id: number, name: string }) => Promise<void>;
}

const DisplayPolicy: React.FC<DisplayPolicyProps> = ({ mode, ...props }) => {
  switch (mode) {
    case DisplayMode.list:
      return <TrollGrid {...props} />
    default:
      return <TrollCardDeck {...props} />
  }
}

export default ViewPolicy;