import React, { useState, useEffect, useMemo } from 'react';
import Loading from '../../common/components/Loading';
import Table, { Metadata } from '../../common/components/Table';
import { Link } from 'react-router-dom';
import BadgeIcon from '../../common/components/BadgeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarAlt } from '@fortawesome/free-regular-svg-icons';

interface DiplomacyRow {
  id: string;
  name: string;
  isDefault: boolean;
}

interface DiplomacyGridProps {
  loadDiplomacies: () => Promise<DiplomacyRow[]>;
  deleteDiplomacy: (id: string) => Promise<void>;
  setDefaultDiplomacy: (id: string, isDefault: boolean) => Promise<void>;
}

interface DiplomacyGridState {
  rows: DiplomacyRow[];
  isLoaded: boolean;
}

const DiplomacyGrid: React.FC<DiplomacyGridProps> = ({ loadDiplomacies, deleteDiplomacy, setDefaultDiplomacy }) => {
  const [{ isLoaded, rows }, setState] = useState<DiplomacyGridState>({
    rows: [],
    isLoaded: false
  });

  const load = useMemo(() => async () => {
    const diplomacies = await loadDiplomacies();
    setState({
      isLoaded: true,
      rows: diplomacies
    });
  }, [loadDiplomacies])

  useEffect(() => {
    load();
  }, [load]);

  if (!isLoaded) {
    return <Loading />
  }

  const handleDeleteDiplomacy: (diplomacy: DiplomacyRow) => Promise<void> = async ({ id, name }) => {
    if (window.confirm(`Supprimer la diplomacie ${name}`)) {
      await deleteDiplomacy(id);
      await load();
    }
  }

  const handleSetDefaultDiplomacy: (diplomacy: DiplomacyRow) => Promise<void> = async ({ id, isDefault }) => {
    await setDefaultDiplomacy(id, !isDefault);
    await load();
  }

  const metadatas: Metadata<DiplomacyRow>[] = [{
    title: "Nom",
    render: row => <Link to={`/diplomacies/${row.id}`}>{row.name}</Link>
  }, {
    title: "",
    render: row => <BadgeIcon tooltip="Supprimer la diplomatie" onClick={() => handleDeleteDiplomacy(row)}><FontAwesomeIcon icon={faTrashAlt} size="2x" /></BadgeIcon>
  }, {
    title: "",
    render: row => <BadgeIcon tooltip="Diplomatie par défaut dans la vue" onClick={() => handleSetDefaultDiplomacy(row)}><FontAwesomeIcon icon={row.isDefault ? faStar : faStarAlt} color="darkgoldenrod" size="2x" /></BadgeIcon>
  }]

  return <Table rows={rows} metadatas={metadatas} />
}

export default DiplomacyGrid;