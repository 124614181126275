import React, { useState } from 'react';
import { Container } from 'reactstrap';
import DisplayField from '../../common/components/DisplayField';
import Input from '../../common/components/Input';
import Button from '../../common/components/Button';
import DiplomacyPolicies from './DiplomacyPolicies';
import { useHistory } from 'react-router';

interface DiplomacyPolicy {
  trolls: number[];
  guilds: number[];
}

interface Diplomacy {
  name: string;
  friends: DiplomacyPolicy;
  enemies: DiplomacyPolicy;
}

interface Guild {
  id: number;
  name: string;
}

interface Troll {
  id: number;
  name: string;
}

interface FullDiplomacyPolicy {
  trolls: Troll[];
  guilds: Guild[];
}

interface FullDiplomacy {
  id: string;
  name: string;
  friends: FullDiplomacyPolicy;
  enemies: FullDiplomacyPolicy;
}

interface AddDiplomacyFormProps {
  addDiplomacy: (diplomacy: Diplomacy) => Promise<FullDiplomacy>;
  loadMountyhallDiplomacy: () => Promise<FullDiplomacy>;
}

const AddDiplomacyForm: React.FC<AddDiplomacyFormProps> = ({ addDiplomacy, loadMountyhallDiplomacy }) => {
  const [{ name, friends, enemies }, setState] = useState<Omit<FullDiplomacy, "id">>({
    name: "",
    friends: {
      trolls: [],
      guilds: []
    },
    enemies: {
      trolls: [],
      guilds: []
    }
  })

  const history = useHistory();

  const handlePoliciesChange: (policies: { friends: FullDiplomacyPolicy, enemies: FullDiplomacyPolicy }) => void = policies => {
    setState({
      name,
      friends: policies.friends,
      enemies: policies.enemies
    })
  }
  const handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void> = async (e) => {
    try {
      e.preventDefault();
      const diplomacy = await addDiplomacy({
        name,
        friends: {
          trolls: friends.trolls.map(troll => troll.id),
          guilds: friends.guilds.map(guild => guild.id)
        },
        enemies: {
          trolls: enemies.trolls.map(troll => troll.id),
          guilds: enemies.guilds.map(guild => guild.id)
        }
      });
      history.push(`/diplomacies/${diplomacy.id}`);
    }
    catch {
    }
  }
  const handleLoadMountyhallDiplomacy: () => Promise<void> = async () => {
    try {
      const diplomacy = await loadMountyhallDiplomacy();
      setState({ ...diplomacy })
    }
    catch {
    }
  }
  return (
    <div>
      <Button onClick={() => handleLoadMountyhallDiplomacy()}>Charger la diplomatie depuis mountyhall</Button>
      <Container>
        <DisplayField title="Nom">
          <Input type="text" value={name} onChange={e => setState({ friends, enemies, name: e.target.value })} placeholder="Nom de la diplomatie" />
        </DisplayField>
      </Container>
      <form onSubmit={handleSubmit}>
        <Button type="submit">Enregistrer</Button>
      </form>
      <DiplomacyPolicies friends={friends} enemies={enemies} onChange={handlePoliciesChange} />
      <form onSubmit={handleSubmit}>
        <Button type="submit">Enregistrer</Button>
      </form>
    </div>
  )
}

export default AddDiplomacyForm;