import * as React from 'react';
import { Link } from 'react-router-dom';

const Home = () => (
  <div>
    <h1>Bienvenue</h1>
    <p>Lorsque vous <Link to="/policies/add">créez un groupe</Link> vous en êtes le propriétaire et vous pouvez inviter un/des trolls à le rejoindre (pour l'instant un seul propriétaire par groupe).</p>
    <p>Vous pouvez consulter <Link to="/policies">vos groupes</Link> et rejoindre ceux dans lesquels vous avez été invité.</p>
  </div>
);

export default Home;
