import Filters from '../models/Filters';
import Map, { BaseArea } from '../models/Map';
import Position from '../models/Position';

type Action =
  | { type: 'init', map: Map<BaseArea> }
  | { type: 'filter', filters: Filters }
  | { type: 'move', center: Position }

interface FilterState {
  filters: Filters;
  maxRadius?: number;
  maxVerticalRadius?: number;
}

const FilterReducer: (state: FilterState, action: Action) => FilterState = (state, action) => {
  switch (action.type) {
    case('init'):
      const initialRadius = Math.min(action.map.radius, 20);
      return {
        filters: {
          origin: action.map.origin,
          center: action.map.origin,
          radius: initialRadius,
          verticalRadius: Math.ceil(initialRadius / 2),
          showMushrooms: false,
          showTreasures: false
        },
        maxRadius: action.map.radius,
        maxVerticalRadius: Math.ceil(action.map.radius / 2)
      }
    case ('filter'):
      return {
        ...state,
        filters: {
          ...action.filters,
          radius: Math.min(Math.max(0, action.filters.radius), state.maxRadius || Math.max(0, action.filters.radius)),
          verticalRadius: Math.min(Math.max(0, action.filters.verticalRadius), state.maxVerticalRadius || Math.max(0, action.filters.verticalRadius))
        }
      }
    case ('move'):
      return {
        ...state,
        filters: {
          ...state.filters,
          center: {
            ...state.filters.center,
            x: action.center.x,
            y: action.center.y,
            n: action.center.n,
          }
        }
      }
    default:
      return state;
  }
}

export default FilterReducer;