import React, { useContext } from 'react';
import { AuthContext } from '../../authentication/components/AuthContext';
import { NavLink, NavItem, NavItemProps } from 'reactstrap';
import { Link } from 'react-router-dom';


interface NavMenuDiplomaciesProps extends NavItemProps {
}

export const NavMenuDiplomacies: React.FC<NavMenuDiplomaciesProps> = (props) => {
  const { authenticated } = useContext(AuthContext);
  if (!authenticated) {
    return <React.Fragment />
  }

  return (
    <NavItem {...props}>
      <NavLink tag={Link} to="/diplomacies">Diplomaties</NavLink>
    </NavItem>
  )
}

export default NavMenuDiplomacies;