import React, { createContext, useState, useEffect } from 'react';

export enum Themes {
  default,
  dark,
}

export interface ThemeContextProps {
  theme: Themes;
  setTheme: (theme: Themes) => void;
}
function getTheme(): Themes {
  const theme: string | null = localStorage.getItem("theme") ;

  return (!!theme && (Themes as any)[theme]) || Themes.default;
}
const initialTheme: Themes = getTheme();

export const ThemeContext = createContext<ThemeContextProps>({ theme: initialTheme, setTheme: () => { }});

export const ThemeProvider: React.FC<{}> = (props) => {
  const [theme, setTheme] = useState(initialTheme);

  useEffect(() => {
    localStorage.setItem("theme", Themes[theme]);
    document.querySelector(":root")!.className = `theme ${Themes[theme]}`;
  }, [theme])

  const initialContext = { theme, setTheme };

  return (
    <ThemeContext.Provider value={initialContext}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider;