import React, { useContext } from 'react';
import { MapContext } from '../contexts/MapContext';
import RefreshButton from '../../common/components/RefreshButton';

import './MapFiltersHeader.scss';
import MapMenu from './MapMenu';

interface MapFiltersHeaderProps {
  title: string;
}

const MapFiltersHeader: React.FC<MapFiltersHeaderProps> = ({ title }) => {
  const { menu, refresh, setMenu } = useContext(MapContext);

  return (
    <div className="map-filters-header d-flex justify-content-between">
      <span>{title}</span>
      <div className="d-flex text-nowrap">
        <MapMenu activeMenu={menu} onChange={setMenu} />
        {refresh ? <RefreshButton className="ml-1" refresh={() => refresh()} size="2x" /> : null}
      </div>
    </div>
  )
}

export default MapFiltersHeader;