import React, { ChangeEventHandler } from 'react';
import Input from './Input';
import moment from 'moment';

export interface DateInputElement extends HTMLInputElement {
  currentDate?: Date;
}

interface DateInputElementAttributes<T> extends Omit<React.InputHTMLAttributes<T>, "value" | "type"> {
  value?: Date;
}

interface DateInputProps extends DateInputElementAttributes<DateInputElement> {
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange, ...props }) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          name: e.target.name,
          currentDate: e.target.validity.valid ? new Date(e.target.value) : undefined
        }
      });
    }
  }

  const toLocalString = (date: Date) => {
    return moment(date)
      .locale('fr')
      .format('YYYY-MM-DDTHH:mm')
  }

  return (
    <Input type="datetime-local" value={value ? toLocalString(value) : ""} onChange={handleChange} {...props} />
  )
}

export default DateInput;