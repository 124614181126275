import React from 'react';
import './ErrorArea.css';

const ErrorArea: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="fld-error">
      {children}
    </div>
  )
}

export default ErrorArea;