import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faFlask, faScroll, faShieldAlt, faTachometerAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BadgeIcon from '../../common/components/BadgeIcon';
import BadgeTroll from './BadgeTroll';

import { ReactComponent as FlyIcon } from '../../flies/images/fly.svg';
import './TrollMenu.scss';
import BadgeLinkIcon from '../../common/components/BadgeLinkIcon';

export enum Menu {
  Profile,
  Effects,
  Talents,
  Equipments,
  Flies,
  Scripts
}

export interface Troll {
  id: number;
  name: string;
  breed: string;
  level: number;
}

interface TrollMenuProps {
  troll: Troll,
  activeMenu: Menu;
  policyId?: string;
  onChange?: (menu: Menu) => void;
}

const TrollMenu: React.FC<TrollMenuProps> = ({ troll: { id, name, breed, level }, activeMenu, onChange, policyId }) => {
  const handleChange = (menu: Menu) => {
    if (onChange) {
      onChange(menu);
    }
  }

  return (
    <>
      <div className="troll-header d-flex justify-content-between align-items-center">
        <h5><b>{name} ({id})</b></h5>
        <BadgeTroll className="d-flex badge-troll-level" breed={breed} content={`${breed} ${level}`} />
      </div>
      <div className="troll-menu-icons">
        <MenuFontAwesomeIcon icon={faUser} tooltip="Profil" value={Menu.Profile} activeMenu={activeMenu} onChange={handleChange} />
        <MenuFontAwesomeIcon icon={faScroll} tooltip="Compétences et sortilèges" value={Menu.Talents} activeMenu={activeMenu} onChange={handleChange} />
        <BadgeLinkIcon tooltip="Vue" to={!policyId ? `/trolls/${id}/map` : `/policies/${policyId}/trolls/${id}/map`} >
          <FontAwesomeIcon icon={faEye} size="2x" />
        </BadgeLinkIcon>
        <MenuFontAwesomeIcon icon={faFlask} tooltip="Bonus Malus" value={Menu.Effects} activeMenu={activeMenu} onChange={handleChange} />
        <MenuFontAwesomeIcon icon={faShieldAlt} tooltip="Equipement" value={Menu.Equipments} activeMenu={activeMenu} onChange={handleChange} />
        <MenuIcon value={Menu.Flies} tooltip="Mouches" activeMenu={activeMenu} onChange={handleChange}><FlyIcon className="fa-2x svg-inline--fa fa-w-16" /></MenuIcon>
        <MenuFontAwesomeIcon icon={faTachometerAlt} tooltip="Appels aux scripts publics" value={Menu.Scripts} activeMenu={activeMenu} onChange={handleChange} />
      </div>
    </>
  )
}

interface MenuIconProps {
  value: Menu;
  activeMenu: Menu;
  tooltip?: string | React.ReactNode;
  onChange: (menu: Menu) => void;
}

const MenuIcon: React.FC<MenuIconProps> = ({ value, activeMenu, tooltip, children, onChange }) => {
  return (
    <BadgeIcon tooltip={tooltip} disabled={activeMenu === value} onClick={() => onChange(value)}>
      {children}
    </BadgeIcon>
  )
}

interface MenuFontAwesomeIconProps extends MenuIconProps {
  icon: IconProp;
}

const MenuFontAwesomeIcon: React.FC<MenuFontAwesomeIconProps> = ({ icon, value, activeMenu, tooltip, onChange }) => {
  return (
    <MenuIcon value={value} activeMenu={activeMenu} tooltip={tooltip} onChange={onChange}>
      <FontAwesomeIcon icon={icon} size="2x" />
    </MenuIcon>
  )
}

export default TrollMenu;