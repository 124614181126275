import React from 'react';
import { CardColumns as StrapCardColumns, CardColumnsProps as StrapCardColumnsProps } from 'reactstrap';

import './CardColumns.scss';

interface CardColumnsProps extends StrapCardColumnsProps {

}

const CardColumns: React.FC<CardColumnsProps> = (props) => {
  return <StrapCardColumns {...props} />
}

export default CardColumns;