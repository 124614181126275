import React from 'react';
import AuthenticatedRoute from '../authentication/components/AuthenticatedRoute';
import ViewMap from './views/ViewMap';

const Routes: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <AuthenticatedRoute exact path='/policies/:policyId/trolls/:id/map' component={ViewMap} />
      <AuthenticatedRoute exact path='/trolls/:id/map' component={ViewMap} />
    </React.Fragment>
  )
}

export default Routes;