import React, { useContext } from 'react';
import { MapContext } from '../contexts/MapContext';
import DisplayDate from '../../common/components/DisplayDate';

import './MapFiltersFooter.scss';

const MapFiltersFooter: React.FC = () => {
  const { map: { date } } = useContext(MapContext);
  if (!date) {
    return <></>
  }
  
  return (
    <span className="map-filters-footer">Mis à jour <DisplayDate date={date} /></span>
  )
}

export default MapFiltersFooter;