import React, { useState } from 'react';
import DisplayField from '../../common/components/DisplayField';
import { Container } from 'reactstrap';
import Input from '../../common/components/Input';
import BadgeIcon from '../../common/components/BadgeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../common/components/Modals/Modal';
import ModalHeader from '../../common/components/Modals/ModalHeader';
import ModalBody from '../../common/components/Modals/ModalBody';
import ModalFooter from '../../common/components/Modals/ModalFooter';
import Button from '../../common/components/Button';

interface AddGuildProps {
  addGuild: (id: number) => void;
  tooltip?: string | React.ReactNode;
}

const AddGuild: React.FC<AddGuildProps> = ({ addGuild, tooltip }) => {
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [guildId, setGuildId] = useState<number>();

  const handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void = async (e) => {
    e.preventDefault();
    if (guildId) {
      addGuild(guildId);
    }
    setModal(false);
  }
  return (
    <>
      <BadgeIcon tooltip={tooltip || "Ajouter une guilde"} onClick={toggle}>
        <FontAwesomeIcon icon={faPlus} />
      </BadgeIcon>
      <Modal backdrop={true} isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Ajouter une guilde</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Container>
              <DisplayField title="N° guilde">
                <Input type="text" name="id" defaultValue={guildId} onChange={e => setGuildId(Number(e.target.value) || undefined)} placeholder="Numéro de guilde" />
              </DisplayField>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button type="submit">Ajouter</Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

export default AddGuild;