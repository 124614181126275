import * as React from 'react';
import Layout from './components/Layout';
import Home from './components/Home';
import AccountsRoutes from './accounts/Routes';
import AuthenticationRoutes from './authentication/Routes';
import PoliciesRoutes from './policies/Routes';
import TrollsRoutes from './trolls/Routes';
import ViewsRoutes from './views/Routes';
import MapRoutes from './map/Routes';
import DiplomacyRoutes from './diplomacy/Routes';
import AuthenticatedRoute from './authentication/components/AuthenticatedRoute';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './custom.css';
import './themes.scss';
import ErrorBoundary from './common/components/ErrorBoundary';

export default () => (
  <Layout>
    <ErrorBoundary>
      <AuthenticatedRoute exact path='/' component={Home} />
      <AccountsRoutes />
      <AuthenticationRoutes />
      <PoliciesRoutes />
      <TrollsRoutes />
      <MapRoutes />
      <ViewsRoutes />
      <DiplomacyRoutes />
      <ToastContainer position="bottom-right" />
    </ErrorBoundary>
  </Layout>
);
