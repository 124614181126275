import React, { createContext, useReducer } from 'react';
import Filters from '../models/Filters';
import Map, { BaseArea } from '../models/Map';
import Position from '../models/Position';
import FilterReducer from '../reducers/FilterReducer';

const defaultFilter: Filters = {
  origin: { x: 0, y: 0, n: 0 },
  center: { x: 0, y: 0, n: 0 },
  radius: 0,
  showTreasures: false,
  showMushrooms: false,
  verticalRadius: 0
}

interface FilterContextProps {
  filters: Filters;
  initFilters: (map: Map<BaseArea>) => void;
  setFilters: (filters: Filters) => void;
  move: (center: Position) => void;
}

export const FilterContext = createContext<FilterContextProps>({
  filters: defaultFilter,
  initFilters: () => { },
  setFilters: () => { },
  move: () => { },
});

export const FilterContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(FilterReducer, { filters: defaultFilter });

  const initFilters = (map: Map<BaseArea>) => dispatch({ type: 'init', map });
  const setFilters = (filters: Filters) => dispatch({ type: 'filter', filters });
  const move = (center: Position) => dispatch({ type: 'move', center });

  return (
    <FilterContext.Provider value={{ filters: state.filters, initFilters, setFilters, move }}>
      {children}
    </FilterContext.Provider>
  )
}