import React from 'react';
import { useParams } from 'react-router';
import { useService } from '../../services';
import PolicyRights from '../components/PolicyRights';
import PolicyServices from '../services/PolicyServices';

const Policy: React.FC<{}> = () => {
  const { id: policyId } = useParams<{ id: string }>();
  const policyServices = useService(PolicyServices);

  const loadPolicy = async () => {
    const { id, name, trolls, isOwner } = await policyServices.getPolicy(policyId);
    return {
      id,
      name,
      isOwner,
      trolls: trolls.map(({
        id,
        name,
        isOwner
      }) => ({
        id,
        name,
        isOwner
      }))
    }
  }

  return (
    <PolicyRights
      loadPolicy={loadPolicy}
      grantOwner={trollId => policyServices.grantOwner(policyId, trollId)}
      grantMember={trollId => policyServices.grantMember(policyId, trollId)}
    />
  )
}

export default Policy;