import React, { useState, useEffect } from 'react';
import Loading from '../../common/components/Loading';
import DisplayPolicy from './DisplayPolicy';

interface Guild {
  id: number;
  name: string;
}

interface Troll {
  id: number;
  name: string;
}

interface DiplomacyPolicy {
  trolls: Troll[];
  guilds: Guild[];
}

interface DiplomacyPolicies {
  friends: DiplomacyPolicy;
  enemies: DiplomacyPolicy;
}

interface Diplomacy extends DiplomacyPolicies {
  id: string;
  name: string;
}

interface DisplayDiplomacyProps {
  load: () => Promise<Diplomacy>;
  addTrollFriend: (trollId: number) => Promise<DiplomacyPolicies>;
  addTrollEnemy: (trollId: number) => Promise<DiplomacyPolicies>;
  addGuildFriend: (guildId: number) => Promise<DiplomacyPolicies>;
  addGuildEnemy: (guildId: number) => Promise<DiplomacyPolicies>;
  deleteTrollFriend: (trollId: number) => Promise<DiplomacyPolicies>;
  deleteTrollEnemy: (trollId: number) => Promise<DiplomacyPolicies>;
  deleteGuildFriend: (guildId: number) => Promise<DiplomacyPolicies>;
  deleteGuildEnemy: (guildId: number) => Promise<DiplomacyPolicies>;
}

interface DisplayDiplomacyState {
  isLoaded: boolean;
  diplomacy?: Diplomacy;
}

const DisplayDiplomacy: React.FC<DisplayDiplomacyProps> = ({
  load,
  addTrollFriend,
  addTrollEnemy,
  addGuildFriend,
  addGuildEnemy,
  deleteTrollFriend,
  deleteTrollEnemy,
  deleteGuildFriend,
  deleteGuildEnemy
}) => {
  const [{ isLoaded, diplomacy }, setState] = useState<DisplayDiplomacyState>({ isLoaded: false });

  useEffect(() => {
    load().then(d => setState({
      isLoaded: true,
      diplomacy: d
    }))
  }, [load]);

  if (!isLoaded) {
    return <Loading />
  }

  if (!diplomacy) {
    return <div>Diplomatie inconnue</div>
  }

  const refresh: (action: () => Promise<DiplomacyPolicies>) => Promise<void> = async (action) => {
    const newDiplo = await action();
    setState({
      isLoaded: true,
      diplomacy: {
        ...diplomacy,
        ...newDiplo
      }
    })
  }

  const handleDelete: (remove: () => Promise<DiplomacyPolicies>, confirmMessage: string) => Promise<void> = async (remove, confirmMessage) => {
    if (window.confirm(confirmMessage)) {
      await refresh(remove);
    }
  }

  const { name, friends, enemies } = diplomacy;

  return (
    <div>
      <h1>{name}</h1>
      <h2>Amis</h2>
      <DisplayPolicy
        {...friends}
        addTroll={trollId => refresh(() => addTrollFriend(trollId))}
        addGuild={guildId => refresh(() => addGuildFriend(guildId))}
        deleteTroll={troll => handleDelete(() => deleteTrollFriend(troll.id), `Retirer ${!troll.name ? `le troll n°${troll.id}`: `${troll.name} (${troll.id})`} de la liste d'ami`)}
        deleteGuild={guild => handleDelete(() => deleteGuildFriend(guild.id), `Retirer la guilde ${!guild.name ? `n°${guild.id}` : `${guild.name} (${guild.id})`} de la liste d'ami`)}
      />
      <h2>Ennemis</h2>
      <DisplayPolicy
        {...enemies}
        addTroll={trollId => refresh(() => addTrollEnemy(trollId))}
        addGuild={guildId => refresh(() => addGuildEnemy(guildId))}
        deleteTroll={troll => handleDelete(() => deleteTrollEnemy(troll.id), `Retirer ${!troll.name ? `le troll n°${troll.id}`: `${troll.name} (${troll.id})`} de la liste d'ennemi`)}
        deleteGuild={guild => handleDelete(() => deleteGuildEnemy(guild.id), `Retirer la guilde ${!guild.name ? `n°${guild.id}` : `${guild.name} (${guild.id})`} de la liste d'ennemi`)}
      />
    </div>
  )
}

export default DisplayDiplomacy;