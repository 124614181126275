import React from 'react';
import Attribute, { AttributeProps } from './Attribute';
import DiceAttribute, { DiceAttributeProps } from './DiceAttribute';
import DurationAttribute, { DurationAttributeProps } from './DurationAttribute';

export interface VitalityProps extends AttributeProps {
  current: number;
  max: number;
}

export interface ProfileProps {
  vitality: VitalityProps;
  view: AttributeProps;
  attack: DiceAttributeProps;
  dodge: DiceAttributeProps;
  damage: DiceAttributeProps;
  regeneration: DiceAttributeProps;
  armor: DiceAttributeProps;
  magicMastery: AttributeProps;
  magicResistance: AttributeProps;
  turn: DurationAttributeProps;
}

const TrollProfile: React.FC<ProfileProps> = ({ vitality, view, attack, dodge, damage, regeneration, armor, magicMastery, magicResistance, turn }) => (
  <div>
    <Attribute name="PV :" {...vitality} />
    <DurationAttribute name="Tour :" {...turn} />
    <Attribute name="Vue :" {...view} />
    <DiceAttribute name="Attaque :" {...attack} />
    <DiceAttribute name="Esquive :" {...dodge} />
    <DiceAttribute name="Dégats :" {...damage} />
    <DiceAttribute name="Régénération :" {...regeneration} />
    <DiceAttribute name="Armure :" {...armor} />
    <Attribute name="MM :" {...magicMastery} />
    <Attribute name="RM :" {...magicResistance} />
  </div>
)

export default TrollProfile;