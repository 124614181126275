import React, { ErrorInfo } from 'react';

class ErrorBoundary extends React.PureComponent<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Une erreur est survenue.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;