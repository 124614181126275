import { Service } from '../../services';

interface Position {
  x: number;
  y: number;
  n: number;
}

interface LightCave {
  n: number;
  nbTrolls: number;
  nbMonsters: number;
  nbTreasures: number;
  nbPlaces: number;
  nbMushrooms: number;
}

interface LightArea {
  x: number;
  y: number;
  caves: LightCave[];
}

interface MapView {
  trollId: number;
  trollName: string;
  origin: Position;
  radius: number;
  date: Date;
  token: string;
  areas: LightArea[];
  search: Search;
  diplomacy?: { id: string, name: string };
}

interface Item {
  id: number;
  name: string;
}

interface Troll extends Item {
  breed: string;
  level: number;
  shortBreed: string;
  guild?: { id: number, name: string }
}

interface Monster extends Item {
  level?: number;
}

interface Cave {
  n: number;
  trolls: Troll[];
  monsters: Monster[];
  treasures: Item[];
  mushrooms: Item[];
  places: Item[];
}

interface MapDetails {
  x: number;
  y: number;
  caves: Cave[];
}

interface MatchPosition {
  x: number;
  y: number;
  n: number[];
}

interface MatchDetails {
  x: number;
  y: number;
  n: number;
  trolls: Troll[];
  monsters: Monster[];
}

interface Matches {
  positions: MatchPosition[];
  details: MatchDetails[];
}

interface Search {
  highlights: Matches;
  friends: Matches;
  enemies: Matches;
}

interface TrollCriteria {
  id: number;
  diplomacyId: string;
}

interface MonsterCriteria {
  id: number;
  baseMonsterId: number;
  monsterLevelRange: Partial<{ min: number, max: number }>;
  familyId: number;
}

interface MapServicesProps {
  getMapDetails: (trollId: number, token: string, x: number, y: number) => Promise<MapDetails>;
  getMap: (trollId: number) => Promise<MapView>;
  search: (trollId: number, filter: { troll?: Partial<TrollCriteria>, monster?: Partial<MonsterCriteria> }) => Promise<Search>;
  refreshView: (trollId: number) => Promise<void>;
  getAggregateMapDetails: (id: string, x: number, y: number) => Promise<MapDetails>;
  aggregateSearch: (id: string, filter: { troll?: Partial<TrollCriteria>, monster?: Partial<MonsterCriteria> }) => Promise<Search>;
}

const MapServices: Service<MapServicesProps> = (serviceContext) => ({
  getMapDetails: (trollId, token, x, y) => serviceContext.fetchApi(`api/trolls/${trollId}/views/${x},${y}?token=${token}`),
  getMap: (trollId) => serviceContext.fetchApi(`api/trolls/${trollId}/views`),
  search: (trollId, filters) =>
    serviceContext.fetchApi(`api/trolls/${trollId}/views/search`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(filters)
    }),
  refreshView: (trollId) => serviceContext.fetchApi(`api/trolls/${trollId}/views`, {
    method: "PUT",
  }),
  getAggregateMapDetails: (id, x, y) => serviceContext.fetchApi(`api/aggregate/views/${id}/${x},${y}`),
  aggregateSearch: (id, filters) =>
    serviceContext.fetchApi(`api/aggregate/views/${id}/search`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(filters)
    }),
})

export default MapServices;