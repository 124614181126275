import React from 'react';
import BadgeActionPoints, { BadgeActionPointsProps } from './BadgeActionPoints';
import State, { StateProps } from './State';
import ProgrammedActions, { ProgrammedActionsProps } from './ProgrammedActions';

import './TrollStatus.scss';

export interface TrollStatusProps {
  state: StateProps;
  programmedActions: ProgrammedActionsProps;
  actionPoints: BadgeActionPointsProps;
}

const Retreats: React.FC<{ retreats: string[] }> = ({ retreats }) => {
  return (
    <>
      <span>{retreats.length} Retraites :</span>
      <ul className="retreats-details">
        {retreats.map((retreat, index) => (<li key={index}>{retreat}</li>))}
      </ul>
    </>
  )
}

const TrollStatus: React.FC<TrollStatusProps> = ({
  actionPoints,
  programmedActions,
  state
}) => {

  return (
    <>
      <State className="troll-status float-status-left" {...state} />
      <ProgrammedActions className="troll-status float-status-right" {...programmedActions} />
      <div className="troll-status">
        <BadgeActionPoints className="status-action-points" {...actionPoints} />
      </div>
    </>
  )
}

export default TrollStatus;