import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useState } from 'react';
import BadgeIcon from './BadgeIcon';
import Table, { TableProps } from './Table';

import './PaginedTable.scss';

interface PaginedTableProps<T> extends TableProps<T> {
  pageLength?: number;
}

interface PagerProps {
  index: number;
  itemPerPage: number;
  totalItems: number;
  onPageChange: (newPage: number) => void;
}

const Pager: React.FC<PagerProps> = ({ index, itemPerPage, totalItems, onPageChange }) => {
  if (totalItems <= itemPerPage) {
    return null;
  }

  return (
    <div className="pager">
      <BadgeIcon disabled={index === 0} onClick={() => onPageChange(index - 1)}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </BadgeIcon>
      <span>Page {index + 1}</span>
      <BadgeIcon disabled={(index + 1) * itemPerPage >= totalItems} onClick={() => onPageChange(index + 1)}>
        <FontAwesomeIcon icon={faAngleRight} />
      </BadgeIcon>
    </div>
  )
}

function PaginedTable<T>(
  { pageLength, rows, metadatas, className }: PropsWithChildren<PaginedTableProps<T>>,
) {
  const [currentPageIndex, setPageIndex] = useState(0);
  const itemPerPage = pageLength || 20;
  const currentPage = rows.slice(currentPageIndex * itemPerPage, (currentPageIndex + 1) * itemPerPage);

  return (
    <div className="pagined-table">
      <Pager index={currentPageIndex} itemPerPage={itemPerPage} totalItems={rows.length} onPageChange={setPageIndex} />
      <Table<T> rows={currentPage} metadatas={metadatas} className={className} />
      <Pager index={currentPageIndex} itemPerPage={itemPerPage} totalItems={rows.length} onPageChange={setPageIndex} />
    </div>
  )
}

export default PaginedTable;