import React from 'react';
import BadgeTroll from '../../trolls/components/BadgeTroll';

import './MapCave.scss';

interface Position {
  x: number;
  y: number;
  n: number;
}

interface Item {
  id: number;
  name: string;
}

interface Troll extends Item {
  breed: string;
  level: number;
  shortBreed: string;
  guild?: { id: number, name: string };
}

interface Monster extends Item {
  level?: number;
}

interface MapCaveProps {
  position: Position;
  trolls: Troll[];
  monsters: Monster[];
  treasures: Item[];
  mushrooms: Item[];
  places: Item[];
}

const MapCave: React.FC<MapCaveProps> = ({ position, trolls, monsters, treasures, mushrooms, places }) => {
  const { x, y, n} = position;
  
  const openNewWindowOnClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void = e => {
    e.stopPropagation();
    e.preventDefault();
    window.open(e.currentTarget.href, "", "width=750,height=550");
  }

  const getTrollLink: (trollId: number) => string = trollId => {
    return `https://games.mountyhall.com/mountyhall/View/PJView.php?ai_IDPJ=${trollId}`;
  }

  const getGuildLink: (guildId: number) => string = guildId => {
    return `https://games.mountyhall.com/mountyhall/View/AllianceView.php?ai_IDAlliance=${guildId}`;
  }

  const getMonsterLink: (monsterId: number) => string = monsterId => {
    return `https://games.mountyhall.com/mountyhall/View/MonsterView.php?ai_IDPJ=${monsterId}`;
  }

  const isEmpty: boolean = !trolls.length
    && !monsters.length
    && (!treasures.length)
    && (!mushrooms.length)
    && !places.length;

  if (isEmpty) {
    return null;
  }

  return (
    <div className="map-cave">
      <b>{x},{y},{n}</b>
      <ul className="cave-content cave-trolls">
        {trolls.map((troll, index) => (
          <li key={index}>
            <a onClick={openNewWindowOnClick} href={getTrollLink(troll.id)}>
              {troll.name} ({troll.id})
            </a>
            {troll.guild ? <a className="guild-troll" onClick={openNewWindowOnClick} href={getGuildLink(troll.guild.id)}>{troll.guild.name}</a> : null}
            <BadgeTroll breed={troll.breed} content={`${troll.shortBreed}${troll.level}`} />
          </li>
        ))}
      </ul>
      <ul className="cave-content cave-monsters">
        {monsters.map((monster, index) => (
          <li key={index}>
            <a onClick={openNewWindowOnClick} href={getMonsterLink(monster.id)}>
              {monster.name} ({monster.id}){monster.level ? ` - Niv. ${monster.level!}` : null}
            </a>
          </li>
        ))}
      </ul>
      <ul className="cave-content cave-treasures">
        {treasures.map((treasure, index) => (<li key={index}>{treasure.name} ({treasure.id})</li>))}
      </ul>
      <ul className="cave-content cave-mushrooms">
        {mushrooms.map((mushroom, index) => (<li key={index}>{mushroom.name} ({mushroom.id})</li>))}
      </ul>
      <ul className="cave-content cave-places">
        {places.map((place, index) => (<li key={index}>{place.name} ({place.id})</li>))}
      </ul>
    </div>
  )
}

export default MapCave;