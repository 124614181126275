import React from 'react';
import InvitationServices from '../../invitations/services/InvitationServices';

import { useService } from '../../services';
import DisplayPolicies from '../components/DisplayPolicies';
import PolicyServices from '../services/PolicyServices';

const Policies: React.FC = () => {

  const policyServices = useService(PolicyServices);
  const invitationServices = useService(InvitationServices);

  return (
    <DisplayPolicies policyServices={policyServices} invitationServices={invitationServices} />
  )
}

export default Policies;