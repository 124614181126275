import React, { useContext } from 'react';
import { RouteProps, Route, Redirect, RouteComponentProps } from 'react-router';
import { AuthContext } from './AuthContext';

const AuthenticatedRoute: React.FC<RouteProps> = ({
  component: Component,
  render,
  ...otherProps
}
  : {
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    render?: ((props: RouteComponentProps<any>) => React.ReactNode);
  }) => {
  function renderComponent(props: RouteComponentProps) {
    return Component ? <Component {...props} /> : null;
  }
  function renderRedirectToSignIn() {
    return <Redirect to="/signin" />;
  }
  const authContext = useContext(AuthContext);
  
  return (
    <Route
      {...otherProps}
      render={!authContext.authenticated ? renderRedirectToSignIn : (render ? render : renderComponent)}
    />
  )
}

export default AuthenticatedRoute;