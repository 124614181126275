import React from 'react';
import { useParams } from 'react-router';
import { useService } from '../../services';
import TrollServices from '../services/TrollServices';
import EffectServices from '../../effects/services/EffectServices';
import DetailedTroll from '../components/DetailedTroll';
import EquipmentServices from '../../equipments/services/EquipmentServices';
import ScriptServices from '../../scripts/services/ScriptServices';
import FlyServices from '../../flies/services/FlyServices';
import { PolicyServices } from '../../policies/services/PolicyServices';

const ViewTroll: React.FC<{}> = () => {
  const trollServices = useService(TrollServices);
  const effectServices = useService(EffectServices);
  const equipmentServices = useService(EquipmentServices);
  const flyServices = useService(FlyServices);
  const scriptServices = useService(ScriptServices);
  const policyServices = useService(PolicyServices);

  const { id, policyId } = useParams<{ id: string, policyId?: string }>();
  const trollId = parseInt(id);

  const loadTroll = async () => {
    const {
      isHidden,
      isInvisible,
      isIntangible,
      isSticky,
      isDown,
      isRunning,
      isLevitating,
      programmedActions,
      actionPoints,
      canPlay,
      ...troll
    } = await trollServices.getTroll(trollId);

    return {
      ...troll,
      state: {
        isHidden,
        isInvisible,
        isIntangible,
        isSticky,
        isDown,
        isRunning,
        isLevitating
      },
      actionPoints: {
        actionPoints,
        canPlay
      },
      programmedActions
    };
  };
  const loadEffects = async () => {
    const trollEffects = await effectServices.getEffects(trollId);
    return {
      lastUpdate: trollEffects.date,
      effects: trollEffects.effects
    }
  }
  const refreshEffects = () => effectServices.refreshEffects(trollId);

  const loadEquipments = () => equipmentServices.getEquipments(trollId);
  const refreshEquipments = () => equipmentServices.refreshEquipments(trollId);

  const loadFlies = async () => {
    const trollEffects = await flyServices.getFlies(trollId);
    return {
      lastUpdate: trollEffects.date,
      flies: trollEffects.flies
    }
  }
  const refreshFlies = () => flyServices.refreshFlies(trollId);

  const loadCounters = () => scriptServices.getScriptCounters(trollId);

  return (
    <DetailedTroll
      policyId={policyId}
      getPolicy={policyServices.getPolicy}
      loadTroll={loadTroll}
      loadEffects={loadEffects}
      refreshEffects={refreshEffects}
      loadEquipments={loadEquipments}
      refreshEquipments={refreshEquipments}
      loadFlies={loadFlies}
      refreshFlies={refreshFlies}
      loadCounters={loadCounters}
    />
  )
}

export default ViewTroll;