import React from 'react';

import './MapLightArea.scss';
import '../font-icons/css/IconMaps.css';
import { LightArea } from '../models/Map';

interface MapLightAreaProps {
  area: LightArea;
}

const MapLightArea: React.FC<MapLightAreaProps> = React.memo(({ area }) => {

  const areaClass = "map-area" + (area.hasPlace ? " area-place" : "");

  return (
    <div className={areaClass}>
      {area.hasTroll ? <i className="icon-map icon-troll" /> : null}
      {area.hasMonster ? <i className="icon-map icon-monster" /> : null}
      {area.hasTreasure ? <i className="icon-map icon-treasure" /> : null}
      {area.hasMushroom ? <i className="icon-map icon-mushroom" /> : null}
    </div>
  )
}, (prevProps, nextProps) => {
  return prevProps.area.hasTroll === nextProps.area.hasTroll
    && prevProps.area.hasMonster === nextProps.area.hasMonster
    && prevProps.area.hasTreasure === nextProps.area.hasTreasure
    && prevProps.area.hasMushroom === nextProps.area.hasMushroom
    && prevProps.area.hasPlace === nextProps.area.hasPlace
})

export default MapLightArea;