import Map, { LightArea, MapArea, RawArea, View, BaseCave, Area, MapMenuItems } from '../models/Map';
import Filters from '../models/Filters';
import Matches from '../models/Matches';
import Zoom from '../models/Zoom';
import { MatchPositions, SearchResult } from '../models/Search';

type Action =
  | { type: 'loading' }
  | { type: 'complete_load', map: Map<RawArea> }
  | { type: 'filter', filters: Filters }
  | { type: 'search', result: SearchResult }
  | { type: 'empty_map' }
  | { type: 'zoom', zoom: Zoom }
  | { type: 'menu', menu: MapMenuItems };

interface MapState {
  isLoaded: boolean;
  map?: Map<MapArea>;
  filteredAreas?: MapArea[];
  view?: View;
  highlights?: Matches;
  friends?: Matches;
  enemies?: Matches;
  zoom: Zoom;
  menu: MapMenuItems;
}

function isMatch<TCave extends BaseCave, TArea extends Area<TCave>>
  (area: TArea, cave: TCave, matches?: Matches): boolean {
  return !!matches && !!matches[area.y] && !!matches[area.y][area.x] && !!matches[area.y][area.x].some(n => n === cave.n);
}

const toMatches: (matches?: MatchPositions[]) => Matches = matches => {
  const areas: Matches = {};
  if (!!matches) {
    matches.forEach(match => {
      if (!areas[match.y]) {
        areas[match.y] = {
          [match.x]: match.n
        };
      }
      else {
        areas[match.y][match.x] = match.n;
      }
    });
  }

  return areas;
}

const indexSearchResult = (result: SearchResult) => {
  const { highlights, friends, enemies } = result;

  return {
    highlights: highlights && toMatches(highlights.positions),
    friends: friends && toMatches(friends.positions),
    enemies: enemies && toMatches(enemies.positions),
  }
}

const getAreas = (areas: MapArea[], search: SearchResult) => {
  const { highlights, friends, enemies } = indexSearchResult(search);
  return areas.map(area => ({
    ...area,
    caves: area.caves.map(cave => ({
      ...cave,
      isHighlight: isMatch(area, cave, highlights),
      isFriend: isMatch(area, cave, friends),
      isEnemy: isMatch(area, cave, enemies)
    }))
  }))
}

const toMapAreas: (areas: RawArea[]) => MapArea[] = (areas) => {
  return areas.map(area => ({
    ...area,
    caves: area.caves.map(cave => ({
      n: cave.n,
      hasTroll: cave.nbTrolls > 0,
      hasMonster: cave.nbMonsters > 0,
      hasTreasure: cave.nbTreasures > 0,
      hasMushroom: cave.nbMushrooms > 0,
      hasPlace: cave.nbPlaces > 0,
      isHighlight: false,
      isFriend: false,
      isEnemy: false
    }))
  }))
}

const filter: (map: Map<MapArea>, filters: Filters) => MapArea[] = (map, filters) => {
  const { center, radius, verticalRadius, showTreasures, showMushrooms } = filters;
  const filterArea: (area: MapArea) => MapArea = area => {
    const getLength: (a: number, b: number) => number = (a, b) => Math.abs(a - b);
    const getDistance: (a: { x: number, y: number }, b: { x: number, y: number }) => number = (a, b) => (
      Math.max(getLength(a.x, b.x), getLength(a.y, b.y))
    );
    const matchDistance: (distance: number, radius: number) => boolean = (distance, radius) => distance <= radius;

    const matchFilter: (area: MapArea) => boolean = (area) => {
      return matchDistance(getDistance(area, center), radius);
    }

    return matchFilter(area) ? {
      ...area,
      caves: area.caves.filter(cave => getLength(cave.n, center.n) <= verticalRadius).map(cave => ({
        ...cave,
        hasTreasure: showTreasures && cave.hasTreasure,
        hasMushroom: showMushrooms && cave.hasMushroom,
      }))
    } : {
        ...area,
        caves: []
      };
  }

  return map.areas.map(filterArea);
}

const computeView: (mapAreas: MapArea[]) => View = (mapAreas) => {
  function toArea<T extends { x: number, y: number }>(array: T[]): { [y: number]: { [x: number]: T } } {
    const areas: { [y: number]: { [x: number]: T } } = {};
    array.forEach(elt => {
      if (!areas[elt.y]) {
        areas[elt.y] = {
          [elt.x]: elt
        }
      }
      else {
        areas[elt.y][elt.x] = elt;
      }
    });

    return areas;
  }

  const toLightArea: (area: MapArea) => LightArea = area => ({
    x: area.x,
    y: area.y,
    hasTroll: area.caves.some(cave => cave.hasTroll),
    hasMonster: area.caves.some(cave => cave.hasMonster),
    hasTreasure: area.caves.some(cave => cave.hasTreasure),
    hasMushroom: area.caves.some(cave => cave.hasMushroom),
    hasPlace: area.caves.some(cave => cave.hasPlace),
    isEmpty: false,
    isHighlight: area.caves.some(cave => cave.isHighlight),
    isFriend: area.caves.some(cave => cave.isFriend),
    isEnemy: area.caves.some(cave => cave.isEnemy)
  })

  return {
    areas: toArea(mapAreas.map(area => toLightArea(area)))
  }
}

const search: (search: SearchResult, map: Map<MapArea>) => Map<MapArea> = (search, map) => {
  return {
    ...map,
    areas: getAreas(map.areas, search)
  };
}
const MapReducer: (state: MapState, action: Action) => MapState = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {
        isLoaded: false,
        zoom: Zoom.default,
        menu: state.menu
      }
    case 'empty_map':
      return {
        isLoaded: true,
        zoom: Zoom.default,
        menu: state.menu
      }
    case 'complete_load':
      return {
        isLoaded: true,
        map: {
          ...action.map,
          areas: toMapAreas(action.map.areas)
        },
        zoom: Zoom.default,
        menu: state.menu
      }
    case 'filter':
      if (!state.map) {
        return {
          ...state,
          view: undefined,
          detailedView: undefined
        }
      }
      const filteredAreas = filter(state.map, action.filters);
      return {
        ...state,
        filteredAreas,
        view: computeView(filteredAreas),
      };
    case 'search':
      if (!state.map) {
        return {
          ...state,
          view: undefined
        }
      }

      return {
        ...state,
        map: search(action.result, state.map),
        view: state.filteredAreas && computeView(getAreas(state.filteredAreas, action.result))
      };
    case 'zoom':
      return {
        ...state,
        zoom: action.zoom
      }
    case 'menu':
      return {
        ...state,
        menu: action.menu
      }
    default:
      return state;
  }
}

export default MapReducer;
