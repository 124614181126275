import React from 'react';

interface TimeSpanProps {
  totalSeconds: number;
}

const TimeSpan: React.FC<TimeSpanProps> = ({ totalSeconds }) => {
  const toDuration: (s: number) => string = s => {
    var hours = Math.floor(s / 3600);
    var minutes = Math.floor((s - (hours * 3600)) / 60);
    // var seconds = s - (hours * 3600) - (minutes * 60);

    return `${hours}`.padStart(2, '0') + 'h' + `${minutes}`.padStart(2, '0'); //+ ':' + `${seconds}`.padStart(2, '0');
  }
  return <span>{toDuration(totalSeconds)}</span>
}

export default TimeSpan;