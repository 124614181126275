import React from 'react';
import DisplayField from '../../common/components/DisplayField';
import TimeSpan from '../../common/components/TimeSpan';

export interface DurationAttributeProps {
  value: number;
  base: number;
  physicalBonus: number;
  magicBonus: number;
  reserve: number;
}

const DurationAttribute: React.FC<DurationAttributeProps & { name: string }> = ({ name, base, value, physicalBonus, magicBonus, reserve }) => {
  const pbSign = physicalBonus < 0 ? '-' : '+';
  const mbSign = magicBonus < 0 ? '-' : '+';
  const reserveSign = magicBonus < 0 ? '-' : '+';
  return (
    <DisplayField title={name}>
      <span><TimeSpan totalSeconds={value} /> </span>
      <small>
        <small>
          (
          <span><TimeSpan totalSeconds={base} /> </span>
          <span>{reserveSign}<TimeSpan totalSeconds={Math.abs(reserve)} /> </span>
          <span>{pbSign}<TimeSpan totalSeconds={Math.abs(physicalBonus)} /> </span>
          <span>{mbSign}<TimeSpan totalSeconds={Math.abs(magicBonus)} /></span>
          )
      </small>
      </small>
    </DisplayField>
  )
}

export default DurationAttribute;