import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { CardDeck } from 'reactstrap';
import BadgeLinkIcon from '../../common/components/BadgeLinkIcon';
import Card from '../../common/components/Cards/Card';
import CardBody from '../../common/components/Cards/CardBody';
import CardHeader from '../../common/components/Cards/CardHeader';
import Loading from '../../common/components/Loading';
import Invitations, { Invitation } from '../../invitations/components/Invitations';
import Policies, { Policy } from './Policies';

interface InvitationsProps {
  getInvitations: () => Promise<Invitation[]>;
  acceptInvitation: (invitationId: string) => Promise<void>;
  refuseInvitation: (invitationId: string) => Promise<void>;
}

interface InvitationsState {
  invitations: Invitation[];
  isLoaded: boolean;
}


interface PoliciesProps {
  getPolicies: () => Promise<Policy[]>;
  deletePolicy: (policyId: string) => Promise<void>;
  sendInvitation: (policyId: string, trollid: number) => Promise<void>;
  leavePolicy: (policyId: string) => Promise<void>;
}

interface PoliciesState {
  policies: Policy[];
  isLoaded: boolean;
}

interface DisplayPoliciesProps {
  policyServices: PoliciesProps;
  invitationServices: InvitationsProps;
}

const DisplayPolicies: React.FC<DisplayPoliciesProps> = ({ policyServices, invitationServices }) => {
  const [invitationsState, setInvitations] = useState<InvitationsState>({
    invitations: [],
    isLoaded: false
  });

  const loadInvitations = async () => {
    const invitations = await invitationServices.getInvitations();
    setInvitations({
      isLoaded: true,
      invitations,
    });
  }

  const [policiesState, setPolicies] = useState<PoliciesState>({
    policies: [],
    isLoaded: false
  });

  const loadPolicies = async () => {
    const policies = await policyServices.getPolicies();
    setPolicies({
      isLoaded: true,
      policies,
    });
  }

  useEffect(() => {
    loadInvitations();
    loadPolicies();
  }, [])

  return (
    <CardDeck>
      <InvitationsCard {...invitationServices} {...invitationsState} refresh={async () => { loadInvitations(); loadPolicies(); }} />
      <PoliciesCard {...policyServices} {...policiesState} refresh={loadPolicies} />
    </CardDeck>
  )
}

interface InvitationsCardProps {
  invitations: Invitation[];
  isLoaded: boolean;
  refresh: () => Promise<void>;
  acceptInvitation: (invitationId: string) => Promise<void>;
  refuseInvitation: (invitationId: string) => Promise<void>;
}

const InvitationsCard: React.FC<InvitationsCardProps> = ({ isLoaded, ...invitationsProps }) => {
  const { invitations } = invitationsProps;

  if (!invitations.length) {
    return <></>
  }

  if (!isLoaded) {
    return <Loading />
  }

  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between">
          <h5><b className="text-nowrap">Invitations</b></h5>
        </div>
      </CardHeader>
      <CardBody>
        <Invitations {...invitationsProps} />
      </CardBody>
    </Card>
  )
}

interface PoliciesCardProps {
  policies: Policy[];
  isLoaded: boolean;
  refresh: () => Promise<void>;
  deletePolicy: (policyId: string) => Promise<void>;
  sendInvitation: (policyId: string, trollid: number) => Promise<void>;
  leavePolicy: (policyId: string) => Promise<void>;
}

const PoliciesCard: React.FC<PoliciesCardProps> = ({ isLoaded, ...policiesProps }) => {
  if (!isLoaded) {
    return <Loading />
  }

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <h5><b className="text-nowrap">Groupes</b></h5>
        <div className="card-header-icons text-nowrap">
          <BadgeLinkIcon to="/policies/add" tooltip="Ajouter un groupe">
            <FontAwesomeIcon icon={faPlus} />
          </BadgeLinkIcon>
        </div>
      </CardHeader>
      <CardBody>
        <Policies {...policiesProps} />
      </CardBody>
    </Card>
  )
}

export default DisplayPolicies;