import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import DisplayField from '../../common/components/DisplayField';
import { Container } from 'reactstrap';
import ErrorArea from '../../common/components/ErrorArea';
import { useHistory } from 'react-router';

interface AccountFormProps {
  onCreate: (request: Account) => Promise<void>;
}

interface Account {
  login: string;
  password: string;
  trollId: number;
  scriptToken: string;
}

const AccountForm: React.FC<AccountFormProps> = ({ onCreate }) => {
  const history = useHistory();
  const { isSubmitting, errors, touched, values: { login, password, confirmPassword, scriptToken, trollId }, handleSubmit, handleChange, handleBlur } = useFormik({
    initialValues: {
      login: '',
      password: '',
      confirmPassword: '',
      scriptToken: '',
      trollId: 0
    },
    validationSchema: Yup.object().shape({
      login: Yup.string()
        .required("Requis"),
      password: Yup.string()
        .min(8, "Le mot de passe doit faire au moins 8 caractères")
        .required("Requis"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Mot de passe différent')
        .required("Requis"),
      trollId: Yup.number()
        .typeError("N° troll invalide")
        .min(1, "N° troll invalide")
        .required("Requis"),
      scriptToken: Yup.string()
        .required("Requis")
    }),
    onSubmit: async ({ confirmPassword, ...account }, { setSubmitting }) => {
      try {
        await onCreate(account);
      }
      catch {
        setSubmitting(false);
      }
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <DisplayField title="Identifiant">
          <Input disabled={isSubmitting} type="text" name="login" value={login} autoComplete="username" onChange={handleChange} onBlur={handleBlur} />
          {errors.login && touched.login && <ErrorArea>{errors.login}</ErrorArea>}
        </DisplayField>
        <DisplayField title="Mot de passe">
          <Input disabled={isSubmitting} type="password" name="password" value={password} autoComplete="password" onChange={handleChange} onBlur={handleBlur} />
          {errors.password && touched.password && <ErrorArea>{errors.password}</ErrorArea>}
        </DisplayField>
        <DisplayField title="Confimer mot de passe">
          <Input disabled={isSubmitting} type="password" name="confirmPassword" value={confirmPassword} autoComplete="password" onChange={handleChange} onBlur={handleBlur} />
          {errors.confirmPassword && touched.confirmPassword && <ErrorArea>{errors.confirmPassword}</ErrorArea>}
        </DisplayField>
        <DisplayField title="N° troll">
          <Input disabled={isSubmitting} type="text" name="trollId" value={trollId} onChange={handleChange} onBlur={handleBlur} />
          {errors.trollId && touched.trollId && <ErrorArea>{errors.trollId}</ErrorArea>}
        </DisplayField>
        <DisplayField title="Mot de passe restreint">
          <Input disabled={isSubmitting} type="text" name="scriptToken" value={scriptToken} onChange={handleChange} onBlur={handleBlur} />
          {errors.scriptToken && touched.scriptToken && <ErrorArea>{errors.scriptToken}</ErrorArea>}
        </DisplayField>
      </Container>
      <Button type="submit" disabled={isSubmitting}>
        Enregistrer
      </Button>
      <Button disabled={isSubmitting} color="secondary" onClick={() => history.push("/signin")}>
        Se connecter
      </Button>
    </form>
  )
}

export default AccountForm;