import React, { useEffect, useState } from 'react';
import { CardDeck } from 'reactstrap';
import Button from '../../common/components/Button';
import Loading from '../../common/components/Loading';
import PreviousButton from '../../common/components/PreviousButton';
import CardTroll from '../../trolls/components/CardTroll';
import EffectGrid, { Effect } from './EffectGrid';

interface TrollEffectsData {
  effects: Effect[];
  lastUpdate: Date;
}

interface DisplayEffectsState {
  trollEffects?: TrollEffectsData;
  isLoaded: boolean;
}

export interface DisplayEffectsProps {
  loadEffects: () => Promise<TrollEffectsData>;
  refreshEffects: () => Promise<void>;
  policyId?: string;
}

const DisplayEffects: React.FC<DisplayEffectsProps> = ({ loadEffects, refreshEffects }) => {
  const [{ isLoaded, trollEffects }, setState] = useState<DisplayEffectsState>({ isLoaded: false });

  useEffect(() => {
    loadEffects()
      .then(trollEffects => setState({
        trollEffects,
        isLoaded: true
      }))
  }, [loadEffects]);

  const handleRefreshEffects = async () => {
    await refreshEffects();

    setState({
      trollEffects: await loadEffects(),
      isLoaded: true
    })
  }

  if (!isLoaded) {
    return <Loading />
  }

  if (!trollEffects) {
    return (
      <div>
        <Button onClick={() => handleRefreshEffects()}>Charger les bonus malus malus</Button>
        <PreviousButton />
      </div>
    )
  }

  const { effects, lastUpdate } = trollEffects;

  return (
    <CardDeck className="trolleffects-cards">
      <CardTroll title="Bonus Malus" lastUpdate={lastUpdate} refresh={handleRefreshEffects}>
        <EffectGrid effects={effects} />
      </CardTroll>
    </CardDeck>
  )
}

export default DisplayEffects;