import * as React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AuthenticateRequest } from '../services/AuthenticationServices';
import Button from '../../common/components/Button';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import DisplayField from '../../common/components/DisplayField';
import Input from '../../common/components/Input';
import ErrorArea from '../../common/components/ErrorArea';

interface SignInFormProps {
  onSignIn: (request: AuthenticateRequest) => Promise<void>;
}

const SignInForm: React.FC<SignInFormProps> = ({ onSignIn }) => {
  const history = useHistory();
  const { isSubmitting, errors, touched, values: { login, password }, handleSubmit, handleChange, handleBlur } = useFormik({
    initialValues: {
      login: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      login: Yup.string()
        .required("Requis"),
      password: Yup.string()
        .required("Requis")
    }),
    onSubmit: async ({ login, password }, { setSubmitting }) => {
      try {
        await onSignIn({ login, password });
      }
      catch {
        setSubmitting(false);
      }
    }
  });
  return (
    <form onSubmit={handleSubmit}>
      <DisplayField title="Identifiant">
        <Input disabled={isSubmitting} type="text" name="login" value={login} autoComplete="username" onChange={handleChange} onBlur={handleBlur} />
        {errors.login && touched.login && <ErrorArea>{errors.login}</ErrorArea>}
      </DisplayField>
      <DisplayField title="Mot de passe">
        <Input disabled={isSubmitting} type="password" name="password" value={password} autoComplete="password" onChange={handleChange} onBlur={handleBlur} />
        {errors.password && touched.password && <ErrorArea>{errors.password}</ErrorArea>}
      </DisplayField>
      <Button type="submit" disabled={isSubmitting}>
        Se connecter
      </Button>
      <Button disabled={isSubmitting} color="secondary" onClick={() => history.push("/accounts/create")}>
        Créer un compte
      </Button>
      <Link to="/accounts/forget">Mot de passe oublié</Link>
    </form>
  )
}

export default SignInForm;