import { Service } from '../../services';

interface Position {
  x: number;
  y: number;
  n: number;
}

interface GridItem {
  id: number;
  name: string;
  distance: number;
  position: Position;
}

interface GridTroll extends GridItem {
  breed: string;
  level: number;
}

interface GridMonster extends GridItem {
  level?: number;
}

interface GridView {
  trollId: number;
  origin: Position;
  radius: number;
  date: Date;
  trolls: GridTroll[];
  monsters: GridMonster[];
  treasures: GridItem[];
  places: GridItem[];
  mushrooms: GridItem[];
}

interface ViewServicesProps {
  getView: (trollId: number) => Promise<GridView>;
  refreshView: (trollId: number) => Promise<void>;
}

const ViewServices: Service<ViewServicesProps> = (serviceContext) => ({
  getView: (trollId) => serviceContext.fetchApi<GridView>(`api/trolls/${trollId}/views/list`),
  refreshView: (trollId) => serviceContext.fetchApi(`api/trolls/${trollId}/views`, {
    method: "PUT",
  })
})

export default ViewServices;