import React, { useContext } from 'react';
import { NavbarText, NavbarTextProps } from 'reactstrap';
import { AuthContext } from '../../authentication/components/AuthContext';

import './NavMenuAvatar.scss';

interface NavMenuAvatarProps extends NavbarTextProps {
}

export const NavMenuAvatar: React.FC<NavMenuAvatarProps> = (props) => {
  const { user } = useContext(AuthContext);
  if (user && user.avatar) {
    return (
      <NavbarText {...props}>
        <img className="nav-avatar-img" src={user?.avatar} alt={"Avatar"} />
      </NavbarText>
    )
  }

  return <></>;
}

export default NavMenuAvatar;