import React from 'react';
import DateInput from '../../common/components/DateInput';
import DisplayField from '../../common/components/DisplayField';
import DropdownList from '../../common/components/DropdownList';

interface Breed {
  id: string;
  name: string;
}
interface Talent {
  id: number;
  name: string;
}
interface Talents {
  skills: Talent[];
  spells: Talent[];
}

export interface Filters {
  actionDate?: Date;
  shortBreed?: string;
  skillId?: number;
  spellId?: number;
}

interface PolicyFiltersProps {
  filters: Filters;
  breeds: Breed[];
  talents: Talents;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
}

const PolicyFilters: React.FC<PolicyFiltersProps> = ({ filters: { actionDate, shortBreed, skillId, spellId }, breeds, talents: { skills, spells }, onChange }) => {
  const breed = breeds.find(b => b.id === shortBreed);
  const skill = skills.find(s => s.id === skillId);
  const spell = spells.find(s => s.id === spellId);
  
  return (
    <>
      <DisplayField title="Disponible à">
        <DateInput name="actionDate" value={actionDate} onChange={onChange} />
      </DisplayField>
      <DisplayField title="Race">
        <DropdownList name="shortBreed" value={breed} keyValue="id" onChange={(_item, e) => onChange(e)} source={breeds} title="name" />
      </DisplayField>
      <DisplayField title="Compétence">
        <DropdownList name="skillId" value={skill} keyValue="id" onChange={(_item, e) => onChange(e)} source={skills} title="name" />
      </DisplayField>
      <DisplayField title="Sortilège">
        <DropdownList name="spellId" value={spell} keyValue="id" onChange={(_item, e) => onChange(e)} source={spells} title="name" />
      </DisplayField>
    </>
  )
}

export default PolicyFilters;