import React from 'react';
import { Container } from 'reactstrap';
import DisplayField from '../../common/components/DisplayField';
import UpdatePassword, { UpdatePasswordValues } from './UpdatePassword';

interface AccountProps {
  login: string;
  updatePassword: (values: UpdatePasswordValues) => Promise<void>;
}

const Account: React.FC<AccountProps> = ({ login, updatePassword }) => {
  return (
    <>
      <Container>
        <DisplayField title="Identifiant">{login}</DisplayField>
        <DisplayField title="Mot de passe"><UpdatePassword updatePassword={updatePassword} /></DisplayField>
      </Container>
    </>
  )
}

export default Account;