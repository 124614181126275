import React from 'react';
import DisplayDate from '../../common/components/DisplayDate';
import TrollName from './TrollName';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import Table, { Metadata } from '../../common/components/Table';
import RefreshButton from '../../common/components/RefreshButton';

import './TrollGrid.scss';
import BadgeLinkIcon from '../../common/components/BadgeLinkIcon';
import BadgeIcon from '../../common/components/BadgeIcon';
import { TrollStatusProps } from '../../trolls/components/TrollStatus';
import BadgeActionPoints from '../../trolls/components/BadgeActionPoints';
import State from '../../trolls/components/State';
import BadgeTroll from '../../trolls/components/BadgeTroll';
import HealthBar from '../../common/components/HealthBar';

interface Vitality {
  current: number;
  max: number;
}

interface Localization {
  x: number;
  y: number;
  n: number;
}

interface Tiredness {
  value: number;
  physicalBonus: number;
}

interface ActionLimitDate {
  value: Date;
  nextEstimated: Date;
}

interface Troll extends TrollStatusProps {
  id: number;
  name: string;
  breed: string;
  level: number;
  breedAndLevel: string;
  concentration: number;
  tiredness: Tiredness;
  actionLimitDate: ActionLimitDate;
  lastUpdate: Date;
  vitality: Vitality;
  isActive: boolean;
  localization: Localization;
}

const DisplayLastUpdate: React.FC<{ date: Date }> = ({ date }) => (
  <div className="text-description"><small><DisplayDate date={date} /></small></div>
)

const DisplayName: React.FC<Troll & { policyId?: string }> = ({ id, name, lastUpdate, isActive, policyId }) => (
  <>
    <TrollName id={id} name={name} isActive={isActive} policyId={policyId} />
    <DisplayLastUpdate date={lastUpdate} />
  </>
)

const DisplayTiredness: React.FC<Tiredness> = ({ value, physicalBonus }) => {
  return (
    <>{`${value + physicalBonus}`}</>
  )
}

interface TrollGridProps {
  policyId: string;
  isOwner: boolean;
  trolls: Troll[];
  excludeTroll: (troll: Troll) => Promise<void>;
  refreshTroll: (troll: Troll) => Promise<void>;
}

const TrollGrid: React.FC<TrollGridProps> = ({ policyId, isOwner, trolls, excludeTroll, refreshTroll }) => {
  const metadatas: Metadata<Troll>[] = [{
    title: "Nom",
    render: row => <DisplayName policyId={policyId} {...row} />
  }, {
    title: "",
    render: ({ breed, breedAndLevel }) => <BadgeTroll breed={breed} content={breedAndLevel} className="policy-badge-troll" />
  }, {
    title: "PV",
    render: ({ vitality }) => <HealthBar {...vitality} />
  }, {
    title: "Position",
    render: ({ localization }) => localization ? `${localization.x},${localization.y},${localization.n}` : null,
    className: "d-none d-md-table-cell"
  }, {
    title: "PA",
    render: ({ actionPoints }) => <BadgeActionPoints {...actionPoints} />,
    className: "d-xl-none"
  }, {
    title: "",
    render: ({ actionPoints, state }) => <div className="policy-troll-state"><BadgeActionPoints {...actionPoints} /><State {...state} /></div>,
    className: "d-none d-xl-table-cell"
  }, {
    title: "DLA",
    render: ({ actionLimitDate }) => (
      actionLimitDate ?
        <>
          <DisplayDate date={actionLimitDate.value} />
          <div className="text-description">
            <small>Suivante : <DisplayDate date={actionLimitDate.nextEstimated} /></small>
          </div>
        </>
        : null
    )
  }, {
    title: "Fat.",
    render: ({ tiredness }) => tiredness ? <DisplayTiredness {...tiredness} /> : null,
    className: "d-none d-lg-table-cell"
  }, {
    title: "Conc.",
    render: ({ concentration }) => concentration ? `${concentration}%` : null,
    className: "d-none d-lg-table-cell"
  }, {
    title: "",
    render: ({ id, isActive }) => <BadgeLinkIcon tooltip="Afficher la vue" disabled={!isActive} to={`/policies/${policyId}/trolls/${id}/map`}><FontAwesomeIcon icon={faEye} size="2x" /></BadgeLinkIcon>,
    className: "policy-troll-action"
  }, {
    title: "",
    render: row => <RefreshButton disabled={!row.isActive} refresh={() => refreshTroll(row)} size="2x" />,
    className: "policy-troll-action"
  }]

  if (isOwner) {
    metadatas.push({
      title: "",
      render: row => <BadgeIcon tooltip="Exclure le troll" onClick={() => excludeTroll(row)}><FontAwesomeIcon icon={faTrashAlt} size="2x" /></BadgeIcon>,
      className: "policy-troll-action"
    })
  }

  return <Table className="policy-trolls" rows={trolls} metadatas={metadatas} />
}

export default TrollGrid;