import Filters from '../models/Filters';
import { DetailedCave } from '../models/DetailedMap';

type Action =
  | { type: 'filter', filters: Filters }
  | { type: 'load_details', caves: DetailedCave[] }

interface AreaDetailsState {
  caves?: DetailedCave[];
  details?: DetailedCave[];
}

const filterDetails = (filters: Filters, caves?: DetailedCave[]) => {
  return filterDetailedCaves(filters, caves || []);
}

const filterDetailedCaves = (filters: Filters, caves: DetailedCave[]) => {
  const { center, showMushrooms, showTreasures, verticalRadius } = filters;
  return caves.map(cave => ({
    ...cave,
    treasures: showTreasures ? cave.treasures : [],
    mushrooms: showMushrooms ? cave.mushrooms : []
  })).filter(cave => Math.abs(cave.position.n - center.n) <= verticalRadius
    && (cave.trolls.length > 0 || cave.monsters.length > 0 || cave.treasures.length > 0 || cave.mushrooms.length > 0 || cave.places.length > 0)
  );
}

const AreaDetailsReducer: (state: AreaDetailsState, action: Action) => AreaDetailsState = (state, action) => {
  switch (action.type) {
    case 'filter':
      return {
        ...state,
        details: filterDetails(action.filters, state.caves)
      };
    case 'load_details':
      return {
        ...state,
        caves: action.caves
      }
    default:
      return state;
  }
}

export default AreaDetailsReducer;
