import React, { useContext } from 'react';
import { MapContext } from '../contexts/MapContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import ZoomEnum from '../models/Zoom';
import './Zoom.scss';

const Zoom: React.FC = () => {
  const { zoom, setZoom } = useContext(MapContext);
  const { min, max } = { min: ZoomEnum.small, max: ZoomEnum.xLarge };
  return (
    <>
      <FontAwesomeIcon className="zoom-icon" icon={faSearchMinus} onClick={() => setZoom(Math.max(min, zoom - 1))} />
      <input type="range" className="zoom-input" value={zoom} min={min} max={max}
        onChange={(e) => setZoom(Number(e.target.value))} />
      <FontAwesomeIcon className="zoom-icon" icon={faSearchPlus} onClick={() => setZoom(Math.min(max, zoom + 1))} />
    </>
  )
}

export default Zoom;