import React, { useEffect, useState } from 'react';
import Loading from '../../common/components/Loading';
import { ProfileProps } from './TrollProfile';
import { Link } from 'react-router-dom';
import DisplayTroll from './DisplayTroll';
import Breadcrumb from '../../common/components/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../common/components/Breadcrumb/BreadcrumbItem';
import DisplayEffects, { DisplayEffectsProps } from '../../effects/components/DisplayEffects';
import TrollMenu, { Menu } from './TrollMenu';
import DisplayTalents from './DisplayTalents';
import TrollEquipments, { TrollEquipmentsProps } from '../../equipments/components/TrollEquipments';
import TrollCounters, { TrollCountersProps } from '../../scripts/components/TrollCounters';
import DisplayFlies, { DisplayFliesProps } from '../../flies/components/DisplayFlies';
import { TrollStatusProps } from './TrollStatus';

interface Localization {
  x: number;
  y: number;
  n: number;
}

interface Tiredness {
  value: number;
  physicalBonus: number;
}

interface Talent {
  name: string;
  levels: number[];
  bonus: number;
}

interface Talents {
  skills: Talent[];
  spells: Talent[];
}

interface ActionLimitDate {
  value: Date;
  nextEstimated: Date;
}

export interface TrollProps  extends TrollStatusProps {
  id: number;
  name: string;
  breed: string;
  breedAndLevel: string;
  level: number;
  talents: Talents;
  profile: ProfileProps;
  concentration: number;
  tiredness: Tiredness;
  actionLimitDate: ActionLimitDate;
  localization: Localization;
  lastUpdate: Date;
}

interface DetailedTrollProps extends DisplayEffectsProps, TrollEquipmentsProps, DisplayFliesProps, TrollCountersProps {
  policyId?: string;
  getPolicy: (policyId: string) => Promise<{ name: string }>;
  loadTroll: () => Promise<TrollProps>;
}
interface DetailedTrollState {
  isLoaded: boolean;
  troll?: TrollProps;
}

const DetailedTroll: React.FC<DetailedTrollProps> = ({ policyId, getPolicy, loadTroll, loadEffects, refreshEffects, loadEquipments, refreshEquipments, loadFlies, refreshFlies, loadCounters }) => {
  const [{ isLoaded, troll }, setState] = useState<DetailedTrollState>({ isLoaded: false });
  const [activeMenu, setActiveMenu] = useState<Menu>(Menu.Profile);
  const [policyName, setPolicyName] = useState("");

  const getPolicyName = async (policyId: string) => {
    const policy = await getPolicy(policyId);

    return policy?.name;
  }

  useEffect(() => {
    loadTroll()
      .then(troll => setState({ troll, isLoaded: true }));

    if (policyId) {
      getPolicyName(policyId).then(setPolicyName);
    }
  }, []);

  if (!isLoaded) {
    return <Loading />
  }

  if (!troll) {
    return <div></div>
  }

  const { id, name } = troll;

  return (
    <div>
      <Breadcrumb>
        {policyId ? <BreadcrumbItem><Link to={`/policies/${policyId}`}>{policyName || "Groupe"}</Link></BreadcrumbItem> : null}
        <BreadcrumbItem active>{name} ({id})</BreadcrumbItem>
      </Breadcrumb>
      <TrollMenu troll={troll} activeMenu={activeMenu} onChange={setActiveMenu} policyId={policyId} />
      <DisplayMenu
        menu={activeMenu}
        troll={troll}
        effects={{ loadEffects, refreshEffects }}
        equipments={{ loadEquipments, refreshEquipments }}
        flies={{ loadFlies, refreshFlies }}
        scripts={{ loadCounters }} />
    </div>
  )
}
interface DisplayMenuProps {
  menu: Menu,
  troll: TrollProps,
  effects: DisplayEffectsProps,
  equipments: TrollEquipmentsProps,
  flies: DisplayFliesProps,
  scripts: TrollCountersProps
}

const DisplayMenu: React.FC<DisplayMenuProps> = ({ menu, troll, effects, equipments, flies, scripts }) => {
  const { talents, lastUpdate } = troll;
  switch (menu) {
    case Menu.Profile:
      return <DisplayTroll troll={troll} />
    case Menu.Talents:
      return <DisplayTalents talents={talents} lastUpdate={lastUpdate} />
    case Menu.Effects:
      return <DisplayEffects {...effects} />
    case Menu.Equipments:
      return <TrollEquipments {...equipments} />
    case Menu.Flies:
      return <DisplayFlies {...flies} />
    case Menu.Scripts:
      return <TrollCounters {...scripts} />
    default:
      return <div></div>
  }
}

export default DetailedTroll;