import React, { useContext } from 'react';
import ForgetPasswordForm from '../components/ForgetPasswordForm';
import { useService } from '../../services';
import AccountServices from '../services/AccountServices';
import AuthenticationServices from '../../authentication/services/AuthenticationServices';
import { AuthContext } from '../../authentication/components/AuthContext';
import { useHistory } from 'react-router';

interface ForgetPassword {
  login: string;
  newPassword: string;
  scriptToken: string;
}

const ForgetPassword: React.FC<{}> = () => {
  const { signin } = useContext(AuthContext);
  const history = useHistory();
  const accountServices = useService(AccountServices);
  const authenticationServices = useService(AuthenticationServices);

  function resetForgetPassword(request: ForgetPassword): Promise<void> {
    return accountServices.resetForgetPassword(request)
      .then(() => (authenticationServices.signin({ login: request.login, password: request.newPassword })))
      .then(user => {
        signin(user);
        return user;
      })
      .then(() => history.push("/"));
  }

  return (
    <ForgetPasswordForm onReset={request => resetForgetPassword(request)} />
  )
}

export default ForgetPassword;