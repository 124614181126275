import React, { useState, useEffect, useContext } from 'react';
import { Container } from 'reactstrap';
import DisplayField from '../../common/components/DisplayField';
import DropdownList from '../../common/components/DropdownList';
import Loading from '../../common/components/Loading';
import { SearchContext } from '../contexts/SearchContext';
import Diplomacy from '../models/Diplomacy';

interface TrollSearchCriteriaState {
  isLoaded: boolean;
  diplomacies?: Diplomacy[];
}

const DiplomacySearchCriteria: React.FC = () => {
  const { criteria: { diplomacy: diplomacyCriteria }, setDiplomacyCriteria, getDiplomacies } = useContext(SearchContext);
  const [{ isLoaded, diplomacies }, setState] = useState<TrollSearchCriteriaState>({ isLoaded: false });

  useEffect(() => {
    getDiplomacies()
      .then(diplomacies => setState({ isLoaded: true, diplomacies }));
  }, [getDiplomacies]);

  const handleChange: (diplomacy?: Diplomacy) => void = diplomacy => {
    setDiplomacyCriteria(diplomacy);
  }

  if (!isLoaded || !diplomacies) {
    return <Loading />
  }

  return (
    <Container>
      <DisplayField title="Diplomatie">
        <DropdownList<Diplomacy> source={diplomacies} value={diplomacyCriteria} title="name" description="Chercher" onChange={handleChange} />
      </DisplayField>
    </Container>
  )
}

export default DiplomacySearchCriteria;