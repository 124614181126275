import React from 'react';
import { Badge, BadgeProps } from 'reactstrap';
import './BadgeLinkIcon.scss';
import { Link } from 'react-router-dom';
import Tooltip from './Tooltip';

interface LinkProps {
  disabled?: boolean;
  to: string;
}

interface TooltipProps {
  tooltip?: string | React.ReactNode;
}

interface BadgeIconProps extends BadgeProps, LinkProps, TooltipProps {
}

const BaseLink: React.FC<LinkProps> = ({ disabled, to, children }) => {
  if (disabled) {
    return <>{children}</>;
  }

  return <Link to={to}>{children}</Link>
}

const BaseTooltip: React.FC<TooltipProps> = ({ tooltip, children }) => {
  if (tooltip) {
    return <Tooltip description={tooltip}>{children}</Tooltip>
  }

  return <>{children}</>
}

const BadgeLinkIcon: React.FC<BadgeIconProps> = ({ disabled, className, tooltip, children, to, ...props }) => {
  const classNames = ["icon-img", className].filter(c => !!c);
  if (disabled) {
    classNames.push("icon-img-disabled");
  }

  return (
    <Badge className={classNames.join(" ")} {...props}>
      <BaseTooltip tooltip={tooltip}>
        <BaseLink disabled={disabled} to={to}>{children}</BaseLink>
      </BaseTooltip>
    </Badge>
  )
}

export default BadgeLinkIcon;