import React from 'react';
import MapServices from '../../map/services/MapServices';
import { useService } from '../../services';
import { useParams } from 'react-router-dom';
import BestiaryServices from '../../bestiary/services/BestiaryServices';
import DiplomacyServices from '../../diplomacy/services/DiplomacyServices';
import PolicyMap from '../components/PolicyMap';
import PolicyServices from '../services/PolicyServices';

const ViewMap: React.FC = () => {
  const { id: policyId } = useParams<{ id: string }>();
  const policyServices = useService(PolicyServices);
  const mapServices = useService(MapServices);
  const bestiaryServices = useService(BestiaryServices);
  const diplomacyServices = useService(DiplomacyServices);

  const getDetails = async (x: number, y: number, token: string) => {
    const details = await mapServices.getAggregateMapDetails(token, x, y);

    return details.caves.map(cave => ({
      position: { x, y, n: cave.n },
      trolls: cave.trolls,
      monsters: cave.monsters,
      treasures: cave.treasures,
      mushrooms: cave.mushrooms,
      places: cave.places
    }));
  }

  return (
    <PolicyMap
      getDetails={getDetails}
      loadMap={policyServices.getPolicyViewMap}
      policyId={policyId}
      getBestiary={bestiaryServices.getBestiary}
      search={mapServices.aggregateSearch}
      getDiplomacies={diplomacyServices.getDiplomacies}
    />
  )
}

export default ViewMap;