import React from 'react';
import MapServices from '../services/MapServices';
import { useService } from '../../services';
import { useParams } from 'react-router-dom';
import BestiaryServices from '../../bestiary/services/BestiaryServices';
import DiplomacyServices from '../../diplomacy/services/DiplomacyServices';
import { SearchCriteria } from '../models/Search';
import TrollMap from '../components/TrollMap';
import PolicyServices from '../../policies/services/PolicyServices';

const ViewMap: React.FC = () => {
  const { id, policyId } = useParams<{ id: string, policyId?: string }>();
  const trollId = parseInt(id);

  const policyServices = useService(PolicyServices);
  const mapServices = useService(MapServices);
  const bestiaryServices = useService(BestiaryServices);
  const diplomacyServices = useService(DiplomacyServices);

  const getDiplomacies = () => diplomacyServices.getDiplomacies();
  const getBestiary = () => bestiaryServices.getBestiary();
  const search = (criteria: Partial<SearchCriteria>) => mapServices.search(trollId, criteria);
  const refreshMap = () => mapServices.refreshView(trollId);
  const getDetails = async (x: number, y: number, token: string) => {
    const details = await mapServices.getMapDetails(trollId, token, x, y);

    return details.caves.map(cave => ({
      position: { x, y, n: cave.n },
      trolls: cave.trolls,
      monsters: cave.monsters,
      treasures: cave.treasures,
      mushrooms: cave.mushrooms,
      places: cave.places
    }));
  }

  const loadMap = async () => {
    const map = await mapServices.getMap(trollId);

    return {
      trollId: trollId,
      trollName: map.trollName,
      origin: map.origin,
      radius: map.radius,
      date: map.date,
      token: map.token,
      areas: map.areas,
      search: map.search,
      diplomacy: map.diplomacy
    }
  }

  return (
      <TrollMap
        loadMap={loadMap}
        getPolicy={policyServices.getPolicy}
        refreshView={refreshMap}
        getDetails={getDetails}
        policyId={policyId}
        getBestiary={getBestiary}
        search={search}
        getDiplomacies={getDiplomacies}
      />
  )
}

export default ViewMap;