import { Service } from '../../services';

interface Effect {
  name: string;
  type: string;
  value: string;
  duration: number;
}

interface Target {
  date: Date;
  effects: Effect[];
}

interface EffectServicesProps {
  getEffects: (trollId: number) => Promise<Target>;
  refreshEffects: (trollId: number) => Promise<void>;
}

const EffectServices: Service<EffectServicesProps> = (serviceContext) => ({
  getEffects: (trollId) => serviceContext.fetchApi<Target>(`api/trolls/${trollId}/effects`),
  refreshEffects: (trollId) => serviceContext.fetchApi(`api/trolls/${trollId}/effects`, {
    method: "PUT",
  })
})

export default EffectServices;