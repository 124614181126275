import React, { useEffect, useState } from 'react';
import { DefaultMap, MapContextProviderProps } from '../contexts/MapContext';
import MapBreadcrumb from './MapBreadcrumb';
import { SearchContextProviderProps } from '../contexts/SearchContext';
import DisplayMap from './DisplayMap';
import Loading from '../../common/components/Loading';
import Button from '../../common/components/Button';
import PreviousButton from '../../common/components/PreviousButton';
import { Status } from '../../services';

interface Troll {
  trollId: number;
  trollName: string;
}

interface TrollMapState extends Troll, DefaultMap {
}

interface DisplayTrollMapProps extends Omit<MapContextProviderProps, "map">, SearchContextProviderProps {
  policyId?: string;
  getPolicy: (policyId: string) => Promise<{ name: string }>;
  loadMap: () => Promise<TrollMapState>;
  refreshView: () => Promise<void>;
}

const TrollMap: React.FC<DisplayTrollMapProps> = ({ policyId, getPolicy, loadMap, refreshView, ...props }) => {
  const [state, setState] = useState<{ map?: TrollMapState, isLoaded: boolean }>({ isLoaded: false });
  const [policyName, setPolicyName] = useState("");

  const getPolicyName = async (policyId: string) => {
    const policy = await getPolicy(policyId);

    return policy?.name;
  }

  useEffect(() => {
    if (policyId) {
      getPolicyName(policyId).then(setPolicyName);
    }

    loadMap()
      .then(m => setState({
        isLoaded: true,
        map: m
      }))
      .catch(error => {
        if (error.status === Status.NotFound) {
          setState({
            isLoaded: true
          })
        }
        else {
          throw error;
        }
      });
  }, [])

  const { isLoaded, map } = state;

  if (!isLoaded) {
    return <Loading />
  }

  const refresh = async () => {
    await refreshView();
    const m = await loadMap();
    setState({
      isLoaded: true,
      map: m
    })
  }

  if (!map) {
    return (
      <div>
        <Button onClick={() => refresh()}>Charger la vue</Button>
        <PreviousButton />
      </div>
    )
  }

  const { trollId, trollName } = map;

  return (
    <>
      <MapBreadcrumb policyId={policyId} policyName={policyName} trollId={trollId} trollName={trollName} />
      <DisplayMap title={trollName} map={map} refreshView={refresh} {...props} />
    </>
  )
}

export default TrollMap;