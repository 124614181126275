import React from 'react';
import { Row, Col } from 'reactstrap';

interface DisplayFieldProps {
  title: string;
  content?: string;
}

const DisplayField: React.FC<DisplayFieldProps> = ({ title, children }) => (
  <Row>
    <Col className="text-nowrap" xs="auto"><b>{title}</b></Col>
    <Col className="text-nowrap">{children}</Col>
  </Row>
)

export default DisplayField;