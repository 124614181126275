import React, { createContext, useState } from 'react';
import { BaseMonster, Bestiary, MonsterFamily } from '../models/Bestiary';
import Diplomacy from '../models/Diplomacy';
import { SearchCriteria, SearchResult } from '../models/Search';

interface TrollCriteria {
  id: number;
}
interface MonsterCriteria {
  id: number;
  level: { min?: number, max?: number };
  baseMonster: BaseMonster;
  family: MonsterFamily;
}

interface Criteria {
  troll: Partial<TrollCriteria>;
  monster: Partial<MonsterCriteria>;
  diplomacy: Diplomacy;
}

const emptyResult: SearchResult = {
  highlights: {
    positions: [],
    details: []
  },
  friends: {
    positions: [],
    details: []
  },
  enemies: {
    positions: [],
    details: []
  }
}

const defaultCriteria: Partial<Criteria> = {}

interface SearchContextProps {
  criteria: Partial<Criteria>;
  result: SearchResult;
  getDiplomacies: () => Promise<Diplomacy[]>;
  getBestiary: () => Promise<Bestiary>;
  setTrollCriteria: (trollCriteria: Partial<TrollCriteria>) => void;
  setMonsterCriteria: (monsterCriteria: Partial<MonsterCriteria>) => void;
  setDiplomacyCriteria: (diplomacyCriteria?: Diplomacy) => void;
  setResult: (result: SearchResult) => void;
  search: () => void;
}

const defaultSearch: SearchContextProps = {
  criteria: defaultCriteria,
  result: emptyResult,
  getDiplomacies: async () => [],
  getBestiary: async () => ({ monsters: [], families: [] }),
  setTrollCriteria: () => { },
  setMonsterCriteria: () => { },
  setDiplomacyCriteria: () => { },
  setResult: () => { },
  search: () => { }
}

export const SearchContext = createContext<SearchContextProps>(defaultSearch);

export interface SearchContextProviderProps {
  search: (criteria: SearchCriteria) => Promise<SearchResult>;
  getDiplomacies: () => Promise<Diplomacy[]>;
  getBestiary: () => Promise<Bestiary>;
}

export const SearchContextProvider: React.FC<SearchContextProviderProps> = ({ search, getDiplomacies, getBestiary, children }) => {
  const [trollCriteria, setTrollCriteria] = useState<Partial<TrollCriteria>>();
  const [monsterCriteria, setMonsterCriteria] = useState<Partial<MonsterCriteria>>();
  const [diplomacyCriteria, setDiplomacyCriteria] = useState<Diplomacy>();
  const [result, setResult] = useState(emptyResult);

  const dispatchSearch = async () => {
    const res = await search({
      troll: {
        id: trollCriteria && trollCriteria.id,
        diplomacyId: diplomacyCriteria && diplomacyCriteria.id
      },
      monster: {
        id: monsterCriteria && monsterCriteria.id,
        monsterLevelRange: monsterCriteria && monsterCriteria.level,
        baseMonsterId: monsterCriteria && monsterCriteria.baseMonster && monsterCriteria.baseMonster.id,
        familyId: monsterCriteria && monsterCriteria.family && monsterCriteria.family.id
      }
    });
    setResult(res);
  }

  return (
    <SearchContext.Provider
      value={{
        criteria: { troll: trollCriteria, monster: monsterCriteria, diplomacy: diplomacyCriteria },
        result,
        getDiplomacies,
        getBestiary,
        setTrollCriteria,
        setMonsterCriteria,
        setDiplomacyCriteria,
        setResult,
        search: dispatchSearch
      }}>
      {children}
    </SearchContext.Provider>
  )
}