import React from 'react';
import { Route } from 'react-router-dom';
import SignIn from './views/SignIn';
import SignOut from './views/SignOut';

const Routes: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <Route exact path='/signin' component={SignIn} />
      <Route exact path='/signout' component={SignOut} />
    </React.Fragment>
  )
}

export default Routes;