import React, { useState, useMemo, useContext, useReducer, useEffect } from 'react';

import './Map.scss';
import MapLightArea from './MapLightArea';
import MapCave from './MapCave';
import { MapContext } from '../contexts/MapContext';
import { LightArea } from '../models/Map';
import DivRow from '../../common/components/DivTable/DivRow';
import DivCell from '../../common/components/DivTable/DivCell';
import DivTable from '../../common/components/DivTable/DivTable';
import DivBody from '../../common/components/DivTable/DivBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleUp, faArrowAltCircleRight, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import Zoom from '../models/Zoom';
import ZoomButtons from './Zoom';
import Position from '../models/Position';
import Loading from '../../common/components/Loading';
import { FilterContext } from '../contexts/FilterContext';
import AreaDetailsReducer from '../reducers/AreaDetailsReducer';
enum Size {
  xl,
  l,
  m,
  s,
  xs
}

interface Range {
  min: number;
  max: number;
}

const getHorizontalAxis: (range: Range) => number[] = ({ min, max }) => {
  return Array(max - min + 1).fill(min).map((minAxis, i) => minAxis + i);
}

const getVerticalAxis: (range: Range) => number[] = ({ min, max }) => {
  return Array(max - min + 1).fill(max).map((maxAxis, i) => maxAxis - i);
}

const HorizontalAxis: React.FC<Range> = React.memo(range => {
  return (
    <DivRow>
      <DivCell className="map-axis map-axis-x" />
      {getHorizontalAxis(range).map(x => (<DivCell key={x} className="map-axis map-axis-x">{x}</DivCell>))}
      <DivCell className="map-axis map-axis-x" />
    </DivRow>
  )
}, (prevProps, nextProps) => {
  return prevProps.min === nextProps.min && prevProps.max === nextProps.max
})

const Map: React.FC = () => {
  const { filters, move } = useContext(FilterContext);
  const { zoom } = useContext(MapContext);
  const { center, radius } = filters;

  const size = useMemo(() => radius <= 5 ? Size.xl : radius <= 10 ? Size.l : radius <= 20 ? Size.m : radius <= 30 ? Size.s : Size.xs, [radius]);

  if (radius < 0) {
    return <div className="view-map-no-view">Vue insuffisante</div>
  }

  const horizontalRange = { min: center.x - radius, max: center.x + radius };
  const moveMap = (x: number, y: number) => move({ x: center.x + x, y: center.y + y, n: center.n });

  return (
    <div className="view-map-container">
      <div className="view-map-zoom-container">
        <ZoomButtons />
      </div>
      <DivTable
        className={`view-map view-map-${Size[size]} view-map-${Zoom[zoom]}`}
      >
        <DivBody>
          <div className="map-arrow map-arrow-vertical map-arrow-top" onClick={() => moveMap(0, radius)}>
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </div>
          <div className="map-arrow map-arrow-horizontal map-arrow-left" onClick={() => moveMap(-radius, 0)}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </div>
          <div className="map-arrow map-arrow-horizontal map-arrow-right" onClick={() => moveMap(radius, 0)}>
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </div>
          <div className="map-arrow map-arrow-vertical map-arrow-bottom" onClick={() => moveMap(0, -radius)}>
            <FontAwesomeIcon icon={faArrowAltCircleDown} />
          </div>
          <HorizontalAxis {...horizontalRange} />
          {
            getVerticalAxis({ min: center.y - radius, max: center.y + radius }).map(y => (
              <MapLine
                key={y}
                y={y}
                horizontalRange={horizontalRange}
              />
            ))
          }
          <HorizontalAxis min={center.x - radius} max={center.x + radius} />
        </DivBody>
      </DivTable>
    </div>
  )
}

interface MapLineProps {
  y: number;
  horizontalRange: Range;
}

const MapLine: React.FC<MapLineProps> = React.memo(({ y, horizontalRange }) => {
  return (
    <DivRow>
      <DivCell className="map-axis map-axis-y">{y}</DivCell>
      {getHorizontalAxis(horizontalRange).map(x => (
        <MapCell
          key={x}
          x={x}
          y={y}
        />
      ))}
      <DivCell className="map-axis map-axis-y">{y}</DivCell>
    </DivRow>
  )
}, (prevProps, nextProps) => {
  return prevProps.y === nextProps.y
    && prevProps.horizontalRange.min === nextProps.horizontalRange.min
    && prevProps.horizontalRange.max === nextProps.horizontalRange.max
})

interface MapCellProps {
  x: number;
  y: number;
}

const MapCell: React.FC<MapCellProps> = ({ x, y }) => {
  const { filters, setFilters, move } = useContext(FilterContext);
  const { view: { areas }, getDetails } = useContext(MapContext);
  const [isSelected, select] = useState(false);
  const [{ caves, details }, dispatch] = useReducer(AreaDetailsReducer, { });

  useEffect(() => {
    if (!!caves) {
      dispatch({ type: 'filter', filters });
    }
  }, [caves, filters]);

  const emptyArea: (x: number, y: number) => LightArea = (x, y) => ({
    x,
    y,
    hasTroll: false,
    hasMonster: false,
    hasTreasure: false,
    hasMushroom: false,
    hasPlace: false,
    isHighlight: false,
    isFriend: false,
    isEnemy: false
  });

  const area = areas[y] && areas[y][x] ? areas[y][x] : emptyArea(x, y);
  const { hasTroll, hasMonster, hasTreasure, hasMushroom, hasPlace, isHighlight, isFriend, isEnemy } = area;

  const { origin } = filters;

  const isEmpty = !hasTroll && !hasMonster && !hasTreasure && !hasMushroom && !hasPlace;
  const isLoaded = !!caves;

  const className = [
    isEmpty ? "area-empty" : null,
    isLoaded ? "area-detail-loaded" : null,
    area.hasPlace ? "area-place" : null,
    origin.x === area.x && origin.y === area.y ? "area-origin" : null,
    isHighlight ? "area-highlight" : null,
    isFriend ? "area-friend" : null,
    isEnemy ? "area-enemy" : null,
    isSelected ? "area-selected" : null,
  ].filter(c => !!c).join(" ");

  const selectCave = async () => {
    !isSelected && select(true);
    if (!isEmpty && !isLoaded) {
      const caves = await getDetails(x, y);
      dispatch({ type: 'load_details', caves })
    }
  }

  const unselectCave = () => {
    isSelected && select(false);
  }

  const renderDetails: () => React.ReactNode = () => {
    return isLoaded
      ? (details || []).map(cave => <MapCave key={cave.position.n} {...cave} />)
      : (<><span>{x},{y}</span><Loading size="1x" /></>);
  }

  const changeOrigin: (newOrigin: Position) => void = newOrigin => {
    setFilters({ ...filters, origin: newOrigin });
    move(newOrigin);
  }

  return (
    <DivCell
      className={className}
      onMouseEnter={selectCave}
      onMouseLeave={unselectCave}
      onTouchStart={selectCave}
      onTouchEnd={unselectCave}
      onDoubleClick={() => changeOrigin({ ...origin, x, y })}
    >
      {isSelected ?
        <>
          <div className="map-area-tooltip">
            <div className="map-area-tooltip-position">{area.x},{area.y}</div>
          </div>
          {!isEmpty ? <div className="map-cave-details">{renderDetails()}</div> : null}
        </>
        : null}
      <MapLightArea area={area} />
    </DivCell>
  )
}


export default Map;