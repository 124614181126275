import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../components/AuthContext';
import { useHistory } from 'react-router';
import AuthenticationServices from '../services/AuthenticationServices';
import { useService } from '../../services';

const SignOutView: React.FC = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const authenticationServices = useService(AuthenticationServices);
  const signout: () => Promise<void> = () => {
    return authenticationServices.signout()
      .then(() => authContext.signout());
  }

  return <SignOut signout={signout} pushToSignIn={() => history.push("/signin")} />
}

interface SignOutProps {
  signout: () => Promise<void>;
  pushToSignIn: () => void;
}

const SignOut: React.FC<SignOutProps> = ({ signout, pushToSignIn }) => {
  const { authenticated } = useContext(AuthContext);

  useEffect(() => {
    if (authenticated) {
      signout().finally(() => pushToSignIn());
    }
  }, [authenticated])
  
  return null;
}

export default SignOutView;