import React from 'react';
import AuthInitializer from './AuthInitializer';
import { useService } from '../../services';
import AuthenticationServices from '../services/AuthenticationServices';

const AuthLoader: React.FC = (props) => {
  var authenticationServices = useService(AuthenticationServices);

  return (
    <AuthInitializer
      validate={authenticationServices.validate}
    >
      {props.children}
    </AuthInitializer>
  )
}

export default AuthLoader;