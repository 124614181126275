import React from 'react';
import AddDiplomacyForm from '../components/AddDiplomacyForm';
import { useService } from '../../services';
import DiplomacyServices from '../services/DiplomacyServices';

interface DiplomacyPolicy {
  trolls: number[];
  guilds: number[];
}

interface Diplomacy {
  name: string;
  friends: DiplomacyPolicy;
  enemies: DiplomacyPolicy;
}

const AddDiplomacy: React.FC = () => {
  const diplomacyServices = useService(DiplomacyServices);

  const addDiplomacy = (diplomacy: Diplomacy) => diplomacyServices.addDiplomacy(diplomacy);
  const loadMountyhallDiplomacy = () => diplomacyServices.loadMountyhallDiplomacy();

  return (
    <AddDiplomacyForm addDiplomacy={addDiplomacy} loadMountyhallDiplomacy={loadMountyhallDiplomacy} />
  )
}

export default AddDiplomacy;