import { Service } from '../../services';

interface Troll {
  id: number;
  name: string;
}

interface Guild {
  id: number;
  name: string;
}

interface DiplomacyPolicy {
  trolls: Troll[];
  guilds: Guild[];
}

interface LightDiplomacyPolicy {
  trolls: number[];
  guilds: number[];
}

interface LightDiplomacy {
  id: string;
  name: string;
  isDefault: boolean;
}

interface DiplomacyPolicies {
  friends: LightDiplomacyPolicy;
  enemies: LightDiplomacyPolicy;
}

interface AddDiplomacy {
  name: string;
  friends: LightDiplomacyPolicy;
  enemies: LightDiplomacyPolicy;
}

interface Diplomacy extends LightDiplomacy {
  friends: DiplomacyPolicy;
  enemies: DiplomacyPolicy;
}

interface DiplomacyServicesProps {
  getDiplomacies: () => Promise<LightDiplomacy[]>;
  getDiplomacy: (id: string) => Promise<Diplomacy>;
  addDiplomacy: (diplomacy: AddDiplomacy) => Promise<Diplomacy>;
  deleteDiplomacy: (id: string) => Promise<void>;
  addToDiplomacy: (id: string, policies: Partial<DiplomacyPolicies>) => Promise<Diplomacy>;
  deleteFromDiplomacy: (id: string, policies: Partial<DiplomacyPolicies>) => Promise<Diplomacy>;
  setDefaultDiplomacy: (id: string, isDefault: boolean) => Promise<void>;
  loadMountyhallDiplomacy: () => Promise<Diplomacy>;
}

const DiplomacyServices: Service<DiplomacyServicesProps> = (serviceContext) => ({
  getDiplomacies: () => serviceContext.fetchApi<LightDiplomacy[]>("api/diplomacies"),
  getDiplomacy: (id) => serviceContext.fetchApi<Diplomacy>(`api/diplomacies/${id}`),
  addDiplomacy: (diplomacy) => serviceContext.fetchApi<Diplomacy>(`api/diplomacies`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify(diplomacy)
  }),
  deleteDiplomacy: (id) => serviceContext.fetchApi(`api/diplomacies/${id}`, {
    method: "DELETE",
  }),
  addToDiplomacy: (id, policies) => serviceContext.fetchApi(`api/diplomacies/${id}/policies`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify(policies)
  }),
  deleteFromDiplomacy: (id, policies) => serviceContext.fetchApi(`api/diplomacies/${id}/policies`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "DELETE",
    body: JSON.stringify(policies)
  }),
  setDefaultDiplomacy: (id, isDefault) => serviceContext.fetchApi(`api/diplomacies/${id}/default`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify(isDefault)
  }),
  loadMountyhallDiplomacy: () => serviceContext.fetchApi<Diplomacy>(`api/diplomacies/mountyhall`),
})

export default DiplomacyServices;