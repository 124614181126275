import React from 'react';
import DisplayDate from '../../common/components/DisplayDate';
import RefreshButton from '../../common/components/RefreshButton';

import Card from '../../common/components/Cards/Card';
import CardHeader from '../../common/components/Cards/CardHeader';
import CardBody from '../../common/components/Cards/CardBody';
import CardFooter from '../../common/components/Cards/CardFooter';

interface CardTrollProps {
  title: string;
  lastUpdate?: Date;
  refresh?: () => Promise<void>;
  hideUpdate?: boolean;
}

const CardTroll: React.FC<CardTrollProps> = ({ title, lastUpdate, refresh, children, hideUpdate }) => (
  <Card outline>
    <CardHeader className="d-flex justify-content-between">
        <h5><b>{title}</b></h5>
        {!refresh ? null : <RefreshButton refresh={() => refresh()} size="2x" />}
    </CardHeader>
    <CardBody>
      {children}
    </CardBody>
    <CardFooter>
      {!hideUpdate ? <small>Dernière mise à jour : {!lastUpdate ? null : <DisplayDate date={lastUpdate!} />}</small> : null}
    </CardFooter>
  </Card>
)

export default CardTroll;