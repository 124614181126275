import React from 'react';
import HealthBar from '../../common/components/HealthBar';
import DisplayField from '../../common/components/DisplayField';
import DisplayDate from '../../common/components/DisplayDate';
import Tiredness from './Tiredness';
import { Container, Col } from 'reactstrap';

import TrollStatus, { TrollStatusProps } from './TrollStatus';

interface Localization {
  x: number;
  y: number;
  n: number;
}

interface Vitality {
  current: number;
  max: number;
}

interface Tiredness {
  value: number;
  physicalBonus: number;
}

interface ActionLimitDate {
  value: Date;
  nextEstimated: Date;
}

interface TrollSituationProps extends TrollStatusProps {
  concentration: number;
  tiredness: Tiredness;
  actionLimitDate: ActionLimitDate;
  vitality: Vitality;
  localization: Localization;
}

const TrollSituation: React.FC<TrollSituationProps> = ({
  concentration,
  tiredness,
  actionLimitDate,
  vitality,
  localization,
  ...status
}) => {
  return (
    <Container>
      <TrollStatus {...status} />
      <Col><HealthBar current={vitality.current} max={vitality.max} /></Col>
      <DisplayField title="DLA :"><DisplayDate date={actionLimitDate.value} /></DisplayField>
      <DisplayField title="DLA suivante :"><DisplayDate date={actionLimitDate.nextEstimated} /></DisplayField>
      <DisplayField title="Position :">
        {`X=${localization.x} Y=${localization.y} N=${localization.n}`}
      </DisplayField>
      <DisplayField title="Concentration :">{concentration}%</DisplayField>
      <Tiredness name="Fatigue :" {...tiredness} />
    </Container>
  )
}

export default TrollSituation;