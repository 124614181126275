import React from 'react';

import './HealthBar.scss';
import { Progress } from 'reactstrap';

interface ProgressbarProps {
  current: number;
  max: number;
}

const HealthBar: React.FC<ProgressbarProps> = ({ current, max }) => {
  if (!max) {
    return null;
  }
  const percent = 100 * current / max;
  const barClassName =
    percent < 25 ? "health-bar-dying" :
    percent < 50 ? "health-bar-very-injured" :
    percent < 75 ? "health-bar-injured" :
    current < max ? "health-bar-light-injured" :
    "health-bar-full";
  return (
    <div>
      <Progress className="health-bar" barClassName={barClassName} value={current} max={max} />
      <div className="health-legend"><small>{current}/{max}</small></div>
    </div>
  )
}

export default HealthBar;