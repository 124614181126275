import React from 'react';
import AuthenticatedRoute from '../authentication/components/AuthenticatedRoute';
import TrollView from './views/TrollView';

const Routes: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <AuthenticatedRoute exact path='/policies/:policyId/trolls/:id/view' component={TrollView} />
      <AuthenticatedRoute exact path='/trolls/:id/view' component={TrollView} />
    </React.Fragment>
  )
}

export default Routes;