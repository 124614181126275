import React from 'react';

interface DivCellProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
}

const DivCell: React.FC<DivCellProps> = ({ className, ...props }) => {
  return (
    <div className={`div-table-cell${className ? (" " + className) : ""}`} {...props} />
  )
}

export default DivCell;