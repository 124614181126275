import React from 'react';
import Donut from '../../common/components/Donut';

export interface Counter {
  name: string;
  value: number;
  limit: number;
}

interface CounterGridProps extends React.HTMLAttributes<HTMLDivElement> {
  counters: Counter[];
}

const CounterGrid: React.FC<CounterGridProps> = ({ counters, ...props }) => {
  return (
    <div {...props}>
    {
      counters.map(({ name, value, limit}, index) => {
        return (
          <Donut key={index} current={value} max={limit} legend={name} size="10em" />
        )
      })
    }
    </div>
  )
}

export default CounterGrid;