import React, { useContext } from 'react';
import Button from '../../common/components/Button';
import Card from '../../common/components/Cards/Card';
import CardBody from '../../common/components/Cards/CardBody';
import CardHeader from '../../common/components/Cards/CardHeader';
import { MapContext } from '../contexts/MapContext';
import { SearchContext } from '../contexts/SearchContext';
import { MapMenuItems } from '../models/Map';
import DiplomacySearchCriteria from './DiplomacySearchCriteria';
import MapFilters from './MapFilters';
import MonsterDetails from './MonsterDetails';
import MonsterSearchCriteria from './MonsterSearchCriteria';
import TrollDetails from './TrollDetails';
import TrollSearchCriteria from './TrollSearchCriteria';

const MapInfos: React.FC = () => {
  const { menu } = useContext(MapContext);
  const { result, search } = useContext(SearchContext);

  const handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void> = async (e) => {
    e.preventDefault();
    await search();
  }

  switch (menu) {
    case MapMenuItems.filters:
      return <MapFilters />
    case MapMenuItems.diplomacy:
      return (
        <>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <DiplomacySearchCriteria />
                <Button type="submit">Chercher</Button>
              </form>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Ennemis</CardHeader>
            <CardBody scrollable>
              <TrollDetails details={result.enemies.details} />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Amis</CardHeader>
            <CardBody scrollable>
              <TrollDetails details={result.friends.details} />
            </CardBody>
          </Card>
        </>
      )
    case MapMenuItems.trolls:
      return (
        <>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <TrollSearchCriteria />
                <Button type="submit">Chercher</Button>
              </form>
            </CardBody>
          </Card>
          <TrollDetails details={result.highlights.details} />
        </>
      )
    case MapMenuItems.monsters:
      return (
        <>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <MonsterSearchCriteria />
                <Button type="submit">Chercher</Button>
              </form>
            </CardBody>
          </Card>
          <MonsterDetails details={result.highlights.details} />
        </>
      )
    default:
      return <div></div>
  }
}

export default MapInfos;