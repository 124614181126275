import React, { useContext } from 'react';
import { AuthContext } from '../../authentication/components/AuthContext';
import { NavLink, NavItem, NavItemProps } from 'reactstrap';
import { Link } from 'react-router-dom';


interface NavMenuPoliciesProps extends NavItemProps {
}

export const NavMenuPolicies: React.FC<NavMenuPoliciesProps> = (props) => {
  const { authenticated } = useContext(AuthContext);
  if (!authenticated) {
    return <></>
  }

  return (
    <NavItem {...props}>
      <NavLink tag={Link} to="/policies">Groupes</NavLink>
    </NavItem>
  )
}

export default NavMenuPolicies;