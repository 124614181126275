import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DisplayField from '../../common/components/DisplayField';
import NumericInput from '../../common/components/NumericInput';
import Switch from '../../common/components/Switch';
import { MapContext } from '../contexts/MapContext';

import './MapFilters.scss';
import { FilterContext } from '../contexts/FilterContext';
import Position from '../models/Position';

const MapFilters: React.FC = () => {
  const { filters, setFilters, move } = useContext(FilterContext);
  const { map } = useContext(MapContext);
  const { origin, radius, verticalRadius, showTreasures, showMushrooms } = filters;
  const [{ currentRadius, currentVerticalRadius }, setCurrentRadius] = useState({ currentRadius: radius, currentVerticalRadius: verticalRadius });

  const maxRadius = map.radius;

  const changeOrigin: (newOrigin: Position) => void = newOrigin => {
    setFilters({ ...filters, origin: newOrigin });
    move(newOrigin);
  }
  const changeRadius: (newRadius: number) => void = newRadius => {
    setFilters({ ...filters, radius: newRadius });
  }
  const changeVerticalRadius: (newVerticalRadius: number) => void = newVerticalRadius => {
    setFilters({ ...filters, verticalRadius: newVerticalRadius });
  }
  const changeCurrentRadius: (newRadius: number) => void = newRadius => {
    setCurrentRadius({ currentRadius: newRadius, currentVerticalRadius });
  }
  const changeCurrentVerticalRadius: (newVerticalRadius: number) => void = newVerticalRadius => {
    setCurrentRadius({ currentRadius, currentVerticalRadius: newVerticalRadius });
  }
  const toogleTreasures: () => void = () => {
    setFilters({ ...filters, showTreasures: !showTreasures });
  }
  const toogleMushrooms: () => void = () => {
    setFilters({ ...filters, showMushrooms: !showMushrooms });
  }
  return (
    <Container className="map-filters">
      <DisplayField title="Origine">
        <Container className="origin-input">
          <Row>
            <Col xs="2" className="origin-col">
              <NumericInput value={origin.x} onBlur={val => changeOrigin({ ...origin, x: val })} />
            </Col>
            <Col xs="2" className="origin-col">
              <NumericInput value={origin.y} onBlur={val => changeOrigin({ ...origin, y: val })} />
            </Col>
            <Col xs="2" className="origin-col">
              <NumericInput value={origin.n} onBlur={val => changeOrigin({ ...origin, n: val })} />
            </Col>
          </Row>
        </Container>
      </DisplayField>
      <DisplayField title="Vue">
        <div className="view-radius-filter">
          <NumericInput
            className="view-radius-input"
            value={currentRadius}
            onChange={changeCurrentRadius}
            onBlur={() => changeRadius(currentRadius)}
          />
          <span> / {maxRadius}</span>
        </div>
      </DisplayField>
      <DisplayField title="Vue verticale">
        <div className="view-radius-filter">
          <NumericInput
            className="view-radius-input"
            value={currentVerticalRadius}
            onChange={changeCurrentVerticalRadius}
            onBlur={() => changeVerticalRadius(currentVerticalRadius)}
          />
          <span> / {Math.ceil(maxRadius / 2)}</span>
        </div>
      </DisplayField>
      <DisplayField title="Trésors">
        <Switch id="showTreasure" checked={showTreasures} onChange={toogleTreasures} />
        <b className="d-lg-none"> Trésors</b>
      </DisplayField>
      <DisplayField title="Champignons">
        <Switch id="showMushroom" checked={showMushrooms} onChange={toogleMushrooms} />
        <b className="d-lg-none"> Champignons</b>
      </DisplayField>
    </Container>
  )
}

export default MapFilters;