import React from 'react';
import CreateAccount from './views/CreateAccount';
import { Route, Switch } from 'react-router';
import ForgetPassword from './views/ForgetPassword';
import MyAccount from './views/MyAccount';
import AuthenticatedRoute from '../authentication/components/AuthenticatedRoute';

const Routes: React.FC<{}> = () => {
  return (
    <Switch>
      <Route exact path='/accounts/create' component={CreateAccount} />
      <Route exact path='/accounts/forget' component={ForgetPassword} />
      <AuthenticatedRoute exact path='/accounts' component={MyAccount} />
    </Switch>
  )
}

export default Routes;