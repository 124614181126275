import React, { useEffect, useState } from 'react';
import Card from '../../common/components/Cards/Card';
import CardBody from '../../common/components/Cards/CardBody';
import CardColumns from '../../common/components/Cards/CardColumns';
import CardHeader from '../../common/components/Cards/CardHeader';
import Donut from '../../common/components/Donut';
import Loading from '../../common/components/Loading';
import { Status } from '../../services';
import CounterGrid, { Counter } from './CounterGrid';

interface Category {
  name: string;
  current: number;
  limit: number;
  counters: Counter[];
}

interface TrollCountersValue {
  trollId: number;
  categories: Category[];
}

export interface TrollCountersProps {
  loadCounters: () => Promise<TrollCountersValue>;
}

interface TrollCountersState {
  isLoaded: boolean;
  trollCounters?: TrollCountersValue;
}

const TrollCounters: React.FC<TrollCountersProps> = ({ loadCounters }) => {
  const [{ isLoaded, trollCounters }, setCounters] = useState<TrollCountersState>({ isLoaded: false });

  useEffect(() => {
    loadCounters()
      .then(trollCounters => setCounters({
        isLoaded: true,
        trollCounters
      }))
      .catch(error => {
        if (error.status === Status.NotFound) {
          setCounters({
            isLoaded: true
          });
        }
      });
  }, [loadCounters]);

  if (!isLoaded) {
    return <Loading />
  }

  if (!trollCounters) {
    return <div>Aucune donnée</div>
  }

  return (
    <CardColumns className="max-columns-count-2">
      {
        trollCounters.categories.map((category, index) => (
          <Card key={index}>
            <CardHeader className="d-flex justify-content-between">
              <h5><b className="text-nowrap">{category.name}</b></h5>
              <div className="card-header-icons text-nowrap">
                <Donut className="big-icon" current={category.current} max={category.limit} />
              </div>
            </CardHeader>
            <CardBody>
              <CounterGrid className="troll-counter-grid" counters={category.counters} />
            </CardBody>
          </Card>
        ))
      }
    </CardColumns>
  )
}

export default TrollCounters;