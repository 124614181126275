import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import DisplayField from '../../common/components/DisplayField';
import { Container } from 'reactstrap';
import ErrorArea from '../../common/components/ErrorArea';

interface ForgetPasswordFormProps {
  onReset: (request: ForgetPassword) => Promise<void>;
}

interface ForgetPassword {
  login: string;
  newPassword: string;
  scriptToken: string;
}


const ForgetPasswordForm: React.FC<ForgetPasswordFormProps> = ({ onReset }) => {
  const { isSubmitting, errors, touched, values: { login, newPassword, confirmPassword, scriptToken }, handleSubmit, handleChange, handleBlur } = useFormik({
    initialValues: {
      login: '',
      newPassword: '',
      confirmPassword: '',
      scriptToken: ''
    },
    validationSchema: Yup.object().shape({
      login: Yup.string()
        .required("Requis"),
      newPassword: Yup.string()
        .min(8, "Le nouveau mot de passe doit faire au moins 8 caractères")
        .required("Requis"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), ''], 'Mot de passe différent')
        .required("Requis"),
      scriptToken: Yup.string()
        .required("Requis")
    }),
    onSubmit: async ({ confirmPassword, ...values}, { setSubmitting }) => {
      try {
        await 
        onReset(values);
      }
      catch {
        setSubmitting(false);
      }
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <DisplayField title="Identifiant">
          <Input disabled={isSubmitting} type="text" name="login" autoComplete="username" value={login} onChange={handleChange} onBlur={handleBlur} />
          {errors.login && touched.login && <ErrorArea>{errors.login}</ErrorArea>}
        </DisplayField>
        <DisplayField title="Mot de passe">
          <Input disabled={isSubmitting} type="password" name="newPassword" autoComplete="new-password" value={newPassword} onChange={handleChange} onBlur={handleBlur} />
          {errors.newPassword && touched.newPassword && <ErrorArea>{errors.newPassword}</ErrorArea>}
        </DisplayField>
        <DisplayField title="Confimer mot de passe">
          <Input disabled={isSubmitting} type="password" name="confirmPassword" autoComplete="new-password" value={confirmPassword} onChange={handleChange} onBlur={handleBlur} />
          {errors.confirmPassword && touched.confirmPassword && <ErrorArea>{errors.confirmPassword}</ErrorArea>}
        </DisplayField>
        <DisplayField title="Mot de passe restreint">
          <Input disabled={isSubmitting} type="text" name="scriptToken" value={scriptToken} onChange={handleChange} onBlur={handleBlur} />
          {errors.scriptToken && touched.scriptToken && <ErrorArea>{errors.scriptToken}</ErrorArea>}
        </DisplayField>
      </Container>
      <Button type="submit" disabled={isSubmitting}>
        Réinitialiser le mot de passe
      </Button>
    </form>
  )
}

export default ForgetPasswordForm;