import React from 'react';
import BadgeIcon from '../../common/components/BadgeIcon';
import Table, { Metadata } from '../../common/components/Table';

import { ReactComponent as FlyIcon } from '../images/fly.svg';
import FlyEffect from './FlyEffect';
import './FlyGrid.scss';

interface FlyType {
  name: string;
  vitality?: number;
  view?: number;
  attack?: number;
  dodge?: number;
  damage?: number;
  regeneration?: number;
  armor?: number;
  turn?: number;
  magicMastery?: number;
  magicResistance?: number;
}

export interface Fly {
  id: number;
  name: string;
  type: FlyType;
  age: number;
  isPresent: boolean;
}

interface FlyGridProps {
  flies: Fly[];
}

const FlyGrid: React.FC<FlyGridProps> = ({ flies }) => {
  const metadatas: Metadata<Fly>[] = [{
    key: "id",
    title: "Id"
  }, {
    key: "name",
    title: "Nom"
  }, {
    title: "Type",
    render: row => row.type.name
  }, {
    title: "Effet",
    render: row => <FlyEffect {...row.type} />
  }, {
    key: "age",
    title: "Age"
  }, {
    title: "",
    render: row => <BadgeIcon className={`fly-icon ${row.isPresent ? "fly-present" : "fly-leave"}`} tooltip={row.isPresent ? "Là" : "Pas là"} disabled={!row.isPresent}><FlyIcon /></BadgeIcon>
  }];

  return <Table rows={flies} metadatas={metadatas} />
}

export default FlyGrid;